import Header from "./components/Header";
import FirstSection from "./components/FirstSection";
import React from "react";
import Advantages from "./components/Advantages";
import Footer from "./components/Footer";
import ExpectedOutcomes from "./components/ExpectedOutcomes";
import PartnerWithKap from "./components/PartnerWithKAP";
import OrganizationalDevelopment from "./components/OrganizationalDevelopment";
import BusinessPotential from "./components/BusinessPotential";
import LocalPartners from "./components/LocalPartners";

function Turkey() {
  return (
    <div className="App">
      <Header />
      <FirstSection />
      <PartnerWithKap />
      <OrganizationalDevelopment />
      <Advantages />
      <ExpectedOutcomes />
      <BusinessPotential />
      <LocalPartners />
      <Footer />
    </div>
  );
}

export default Turkey;
