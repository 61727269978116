import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "../../../assets/styles/login-1.css";
import "../custom.css";
// import { KaporgIcon } from "../../../assets/icons";
import { KapOrgIcon2 } from "../../../assets/icons";
import Lottie from "react-lottie";
import animationData from "../../../assets/animations/kaporg.json";

function LayoutBackgroundBox({ children }) {
   const location = useLocation();
   const [step, setStep] = useState(1);
   const [title, setTitle] = useState("");
   const [description, setDescription] = useState("");

   useEffect(() => {
      if (location.pathname.includes("auth/login")) {
         setTitle("Transforming capacity building");
         setDescription("For the digital age");
      } else if (location.pathname.includes("auth/confirm-request")) {
         setTitle("Elevate your organization");
         setDescription("with purposeful evaluation!");
      } else if (location.pathname.includes("auth/confirm-code")) {
         setTitle("Elevate your organization");
         setDescription("with purposeful evaluation!");
      } else if (location.pathname.includes("auth/forgot-password")) {
         setTitle("Connect with the changemakers");
         setDescription("who need you most.");
      } else if (location.pathname.includes("auth/password-confirm")) {
         setTitle("Connect with the changemakers");
         setDescription("who need you most.");
      } else if (location.pathname.includes("auth/generate-password")) {
         setTitle("Connect with the changemakers");
         setDescription("who need you most.");
      } else if (location.pathname.includes("auth/reset-password")) {
         setTitle("Connect with the changemakers");
         setDescription("who need you most.");
      } else if (location.pathname.includes("/auth/reset-password-recovered")) {
         setTitle("Connect with the changemakers");
         setDescription("who need you most.");
      } else if (location.pathname.includes("/auth/national-org-registration/verification")) {
         setTitle("Elevate your organization");
         setDescription("with purposeful evaluation!");
      } else if (location.pathname.includes("/auth/national-org-registration/step-three")) {
         setTitle("Elevate your organization");
         setDescription("with purposeful evaluation!");
      } else if (location.pathname.includes("auth/account")) {
         setTitle("We'll miss you,");
         setDescription("We hope to welcome you back soon.");
      } else {
         setTitle("Transforming capacity building");
         setDescription("For the digital age");
      }
   }, [location]);

   const lottieOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
         preserveAspectRatio: "xMidYMid slice"
      }
   };

   return (
      <>
         <div
            className="imageContainerMain"
            style={{
               // maxWidth: "100%",
               backgroundImage: `url(${toAbsoluteUrl("/media/bg/loginbackground.svg")})`,
               display: "flex",
               flexDirection: "column"
            }}
         >
            <div className="pt-8 pb-8 pl-4 pt-md-12 pb-md-12 pl-md-11 pr-md-11">
               <Link to="/">
                  <img alt="Logo" className="max-h-70px" src={KapOrgIcon2} />
               </Link>
            </div>
            <div
               className="pr-md-17 pb-md-18 pl-md-11 d-flex flex-column flex-md-row justify-content-between"
               style={{ gap: "10px", flex: "auto", alignItems: "center" }}
            >
               <div className="d-flex flex-column ">
                  <p className="text-white title-paragraph pl-6 pl-md-0">{title}</p>
                  <p className="text-white font-italic description-paragraph pl-6 pl-md-0">
                     {description}
                  </p>

                  <div className="lottie-div">
                     <Lottie options={lottieOptions} height={"100%"} width={"100%"} />
                  </div>
               </div>
               <Outlet />
            </div>
         </div>
      </>
   );
}

export default LayoutBackgroundBox;
