import React, { useEffect, useState } from "react";
import { httpService } from "../../../../../../app/base/services/httpService.service";
import { useLocation, useNavigate } from "react-router-dom";
import { Radio, Divider } from "antd";

function MenuActionPlanList() {
   const location = useLocation();
   const navigate = useNavigate();
   const [selectedSections, setSelectedSections] = useState([]);
   const [selectedId, setSelectedId] = useState(null);
   const [surveyName, setSurveyName] = useState("");
   const assessmentId = localStorage.getItem("selectedAssessmentId");
   const workspaceId = sessionStorage.getItem("workspaceID").replace(/^"(.*)"$/, "$1");
   const storedInfo = localStorage.getItem("selectedCardInfo");
   const selectedCardInfo = storedInfo ? JSON.parse(storedInfo) : null;
   const selectedRangeId = selectedCardInfo ? selectedCardInfo.selectedId : null;
   const pathnameSegments = location.pathname.split("/");
   const viewIndex = pathnameSegments.indexOf("view");
   const idAfterView =
      viewIndex !== -1 && viewIndex + 1 < pathnameSegments.length
         ? pathnameSegments[viewIndex + 1]
         : null;

   useEffect(() => {
      if (idAfterView) {
         httpService.get(
            `/ActionPlans/ngo/survey-sections?SurveyId=${idAfterView}&WorkspaceId=${workspaceId}`,
            (res) => {
               setSelectedSections(res.data);
            },
            (error) => {
               console.error("Error fetching Survey data:", error);
            }
         );

         httpService.get(
            `/ActionPlans/ngo/assessment/${assessmentId}/surveys?workspaceId=${workspaceId}&rangeId=${selectedRangeId}`,
            (res) => {
               const filteredSurvey = res.data.find((survey) => survey.id === idAfterView);
               if (filteredSurvey) {
                  setSurveyName(filteredSurvey.name);
               } else {
                  setSurveyName("");
               }
            },
            (error) => {
               console.error("Error fetching Survey name:", error);
            }
         );
      } else {
         console.log("Error: Missing Survey ID");
      }
      const storedSelectedId = localStorage.getItem("selectedSectionId");
      setSelectedId(storedSelectedId);
      navigateToDetailView(storedSelectedId);
   }, [assessmentId, idAfterView, workspaceId, selectedRangeId]);

   const handleRadioGroupChange = (e) => {
      const value = e.target.value;
      setSelectedId(value);
      localStorage.setItem("selectedId", value);
      navigateToDetailView(value);
   };

   const navigateToDetailView = (id) => {
      const newPath = `/workspace/results/action-plan/${assessmentId}/view/${idAfterView}/${id}`;
      navigate(newPath);
   };

   const handleCardClick = (sectionId) => {
      setSelectedId(sectionId);
      localStorage.setItem("selectedId", sectionId);
      navigateToDetailView(sectionId);
   };

   return (
      <div style={{ padding: "1.25rem" }}>
         <div className="assessment-action-plan-title">Areas in {surveyName}</div>
         <div className="assessment-action-plan-description">
            <div>Here you will find an action plan based on your results in each assessment.</div>
            <div>Select one to see the plan in detail.</div>
         </div>
         <div className="assessment-cards-wrapper">
            <Radio.Group
               className="custom-radio-group"
               value={selectedId}
               onChange={handleRadioGroupChange}
               defaultValue={selectedId}
            >
               {selectedSections.map((section) => (
                  <div
                     className={`custom-assessment-card ${
                        selectedId === section.sectionId ? "activeAssessmentCard" : ""
                     }`}
                     key={section.sectionId}
                     onClick={() => handleCardClick(section.sectionId)}
                  >
                     <div className="custom-card-upper">
                        <h3 className="assessment-name">{section.sectionName}</h3>
                        <Radio value={section.sectionId}></Radio>
                     </div>
                     <Divider style={{ marginTop: "30px" }} />
                     <div className="custom-card-lower">
                        <h3>Indicators to Improve: {section.indicatorToImprove}</h3>
                        <span></span>
                     </div>
                  </div>
               ))}
            </Radio.Group>
         </div>
      </div>
   );
}

export default MenuActionPlanList;
