import { Formik } from "formik";
import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { login, setNavigateToExpert, setNavigateToOrg } from "./authSlice";
import BlockUI from "../../base/components/BlockUI";
import { EyeInvisible, EyeVisible } from "../../assets/icons";
import { Checkbox, Spin } from "antd";
import https from "../../base/utils/http";
const initialValues = {
   email: "",
   password: ""
};

function Login() {
   const { isLoading } = useSelector((state) => state.auth);
   const navigateToOrg = useSelector((state) => state.auth.navigateToOrg);
   const navigateToExpert = useSelector((state) => state.auth.navigateToExpert);
   const dispatch = useDispatch();
   const [checked, setChecked] = useState(false);

   const [type, setType] = useState("password");
   const [icon, setIcon] = useState(EyeInvisible);

   const navigate = useNavigate();

   const toggleChecked = () => {
      setChecked(!checked);
   };

   const onChange = (e) => {
      setChecked(e.target.checked);
   };

   const LoginSchema = Yup.object().shape({
      email: Yup.string()
         .email("Invalid email")
         .required("Required"),
      password: Yup.string()
         .max(16, "Maximum 16 symbols")
         .required("Required")
   });

   const handleToggle = () => {
      if (type === "password") {
         setIcon(EyeVisible);
         setType("text");
      } else {
         setIcon(EyeInvisible);
         setType("password");
      }
   };

   const submitForm = (values) => {
      dispatch(login(values, successCallback, errCB));
   };

   const successCallback = () => {
      navigate("/admin/workspace");
   };

   const errCB = (data) => {
      if (data.target?.targetUrl) {
         navigate(data.target?.targetUrl, {
            state: { fromApp: true, data: data.target.params }
         });
      }
   };

   return (
      <>
         {isLoading ? (
            <BlockUI isLoading={isLoading} />
         ) : (
            <div
               className="pt-md-20 pr-md-10 pb-md-20 pl-md-10 custom-div"
               style={{
                  margin: "0 !important",
                  display: "block",
                  padding: "30px 20px",
                  height: "560px",
                  backgroundColor: "#fff"
               }}
            >
               <div className="d-flex flex-column" style={{ gap: "8px", marginBottom: "40px" }}>
                  <p style={{ color: "#4158CF", fontWeight: 600, fontSize: "26px", margin: 0 }}>
                     Welcome back!
                  </p>
                  <p className="m-0">Login into your account</p>
               </div>
               <Formik
                  initialValues={initialValues}
                  validationSchema={LoginSchema}
                  onSubmit={(values) => {
                     submitForm(values);
                     // generateEmailConfirmationCode(values.email); //check this no need to call it everytime
                  }}
               >
                  {({ handleSubmit, handleChange, values, errors }) => (
                     <form
                        onSubmit={handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                     >
                        <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                           <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 Email
                              </label>
                              <input
                                 placeholder="yourmail@gmail.com"
                                 className={`form-control`}
                                 type="email"
                                 name="email"
                                 onChange={handleChange}
                                 values={values.email}
                              />
                              {errors.email && <div className="error-message">{errors.email}</div>}
                           </div>
                           <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 Password
                              </label>
                              {/* <Tooltip title="Min 3 symbols">
                        <Button>?</Button>
                     </Tooltip> */}
                              <div className="password-section">
                                 <input
                                    autoComplete="off"
                                    placeholder="Password"
                                    className={`form-control`}
                                    type={type}
                                    name="password"
                                    onChange={handleChange}
                                    values={values.password}
                                    maxLength={16}
                                 />
                                 {/* <span className="eyeButton" onClick={handleToggle}> */}
                                 <img
                                    className="eyeButton"
                                    onClick={handleToggle}
                                    src={icon}
                                    alt="showPasswordIcon"
                                    style={{ width: "14px" }}
                                 />
                                 {/* </span> */}
                              </div>

                              {errors.password && (
                                 <div className="error-message">{errors.password}</div>
                              )}
                           </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center mt-2">
                           {/* <label> */}
                           <div className="d-flex align-items-center">
                              <Checkbox
                                 checked={checked}
                                 onChange={onChange}
                                 style={{ lineHeight: "0px" }}
                              />
                              {/* <input type="checkbox" name="acceptTerms" className="m-1 checkbox__style" /> */}
                              {/* <Link
                                 to="/"
                                 target="_blank"
                                 className="ml-2 r-btn"
                                 rel="noopener noreferrer"
                              > */}
                              <p style={{ fontSize: "12px", color: "#777", margin: "0 0 0 8px" }}>
                                 Remember me?
                              </p>
                              {/* </Link> */}
                           </div>
                           <Link
                              to="/auth/forgot-password"
                              className="mr-4 password-text"
                              id="kt_login_forgot"
                              style={{ fontSize: "12px" }}
                           >
                              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                           </Link>
                           {/* </div> */}
                           {/* </label> */}
                           {/* <div className="d-flex"> */}

                           {/* </div> */}
                        </div>
                        <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
                           <button
                              id="kt_login_signin_submit"
                              type="submit"
                              className={`btn sign-btn font-weight-bold my-3 mt-18`}
                           >
                              {isLoading ? <Spin /> : "Log in"}
                           </button>
                        </div>
                     </form>
                  )}
               </Formik>
               <div className="d-flex justify-content-center mt-4">
                  <p style={{ color: "#555", fontWeight: 400, fontSize: "14px", margin: "0" }}>
                     Don't have an account yet?{" "}
                     <Link
                        to="/auth/registration"
                        className="ml-2 sign-up-btn"
                        id="kt_login_signup"
                     >
                        Sign up
                     </Link>
                  </p>
               </div>
            </div>
         )}
      </>
   );
}

export default injectIntl(Login);
