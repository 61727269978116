import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { httpService } from "../../base/services/httpService.service";
import { validateConfirmationCode } from "./authSlice";
import AssessmentPhoto from "../../assets/icons/assessmentPhoto.png";
import SignupPhoto from "../../assets/icons/signup-photo.png";
import "./style.scss";
import { ReactComponent as RegisterPhoto } from "../../assets/icons/registerPhoto.svg";
import { ReactComponent as Wave } from "../../assets/icons/wave.svg";

function VerificationCodeAndSignIn() {
   const { state } = useLocation();
   const [code, setCode] = useState();
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const value = localStorage.getItem("userId");

   const gotoLogin = () => {
      navigate("/auth/login");
   };

   return (
      <div className="bg-form-confirmcode w-full">
         <div
            className="container pt-10 d-flex align-items-center justify-content-center"
            // style={{ backgroundColor: "#fff" }}
         >
            {/* Card */}
            <div
               className="d-flex p-10"
               style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "950px",
                  borderRadius: "28px"
               }}
            >
               <p
                  className="paragraph1"
                  //   style={{
                  //      fontFamily: "Nunito Sans",
                  //      fontSize: "24px",
                  //      fontWeight: "400",
                  //      lineHeight: "25px",
                  //      textAlign: "center"
                  //   }}
               >
                  Your application is being reviewed. <br />
                  You will receive a confirmation via email shortly.
               </p>
               {/* image */}
               <div>
                  <img className="img" alt="icon" src={SignupPhoto} />
               </div>
               <div>
                  <div className="btn-area">
                     <button onClick={() => gotoLogin()} className="btn-outline-log">
                        Login
                     </button>
                  </div>
               </div>
            </div>
         </div>
         {/* <div className="footer">
            <Wave className="wave-svg" />
         </div> */}
      </div>
   );
}

export default VerificationCodeAndSignIn;
