import { createSlice } from "@reduxjs/toolkit";
import { httpService } from "../../../base/services/httpService.service";

const initialState = {
   isLoading: false,
   tableData: {},
   peopleList: [],
   statusList: [],
   filters: []
};

const actionSlice = createSlice({
   name: "action",
   initialState,
   reducers: {
      setIsLoading(state, { payload }) {
         return {
            ...state,
            isLoading: payload
         };
      },
      setTableData(state, { payload }) {
         state.tableData = payload;
      },
      setPeopleData(state, { payload }) {
         state.peopleList = payload;
      },
      setStatusData(state, { payload }) {
         state.statusList = payload;
      },
      setFilters(state, { payload }) {
         state.filters = payload;

      }
   }
});

export const {
   setIsLoading,
   setTableData,
   setPeopleData,
   setStatusData,
   setFilters
} = actionSlice.actions;

export default actionSlice.reducer;
