import React, { useState, useEffect } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { httpService } from "../../base/services/httpService.service";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { LocalStorageService } from "../../base/services/local-storage.service";
import { Formik, Form, Field } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { BackIcon, FileImport, MinusButtonWithColor, PlusIconBlue } from "../../assets/icons";
import { Upload, Card, Button, Spin } from "antd";
import { completeConsultantProfile } from "./authSlice";

const initialValues = {
   ProfilePicture: null,
   HigestDegree: null,
   StudyFields: "",
   EducationalInstitution: "",
   Expertises: [{ areaName: "", areaExperienceYears: "" }],
   Languages: [{ languageId: "", level: "" }],
   CV: null,
   Certificate: null
};

function RegistrationExpertStepFour() {
   const [degreeOptions, setDegreeOptions] = useState([]);
   const [languageOptions, setLanguageOptions] = useState([]);
   const [languageLevelOptions, setLanguageLevelOptions] = useState([]);
   const [languages, setLanguages] = useState([{ languageId: "", level: "" }]);
   const [expertise, setExpertise] = useState([{ areaName: "", areaExperienceYears: "" }]);
   const [loading, setLoading] = useState(false);
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const handleAddExpertise = () => {
      setExpertise([...expertise, { areaName: "", areaExperienceYears: "" }]);
   };

   const handleRemoveExpertise = (index) => {
      const updatedExpertise = expertise.filter((_, i) => i !== index);
      setExpertise(updatedExpertise);
   };

   const handleExpertiseChange = (index, field, value, setFieldValue) => {
      setFieldValue(`Expertises.${index}.${field}`, value);
      const updatedExpertise = [...expertise];
      updatedExpertise[index][field] = value;
      setExpertise(updatedExpertise);
   };

   const handleAddLanguage = () => {
      setLanguages([...languages, { languageId: "", level: "" }]);
   };

   const handleRemoveLanguage = (index) => {
      const updatedLanguages = [...languages];
      updatedLanguages.splice(index, 1);
      setLanguages(updatedLanguages);
   };

   const handleLanguageChange = (index, field, value, setFieldValue) => {
      setFieldValue(`Languages.${index}.${field}`, value);
      const updatedLanguages = [...languages];
      updatedLanguages[index][field] = value;
      setLanguages(updatedLanguages);
   };

   const validationSchema = Yup.object().shape({
      HigestDegree: Yup.string()
         .nullable()
         .required("Highest degree is required"),
      Expertises: Yup.array().of(
         Yup.object().shape({
            areaName: Yup.string().required("Area is required"),
            areaExperienceYears: Yup.number()
               .required("Years of experience is required")
               .typeError("Years of experience must be a number")
         })
      ),
      ProfilePicture: Yup.mixed()
         .required("Profile picture is required")
         .test(
            "fileType",
            "Invalid file type. Only PNG or JPEG are allowed.",
            (value) =>
               !value || (value && (value.type === "image/png" || value.type === "image/jpeg"))
         )
         .test(
            "fileSize",
            "File size is too large. Max size is 20MB",
            (value) => !value || (value && value.size <= 20971520)
         ),
      CV: Yup.mixed()
         .test(
            "fileType",
            "Invalid file type. Only PDF is allowed.",
            (value) => !value || (value && value.type === "application/pdf")
         )
         .test(
            "fileSize",
            "File size is too large. Max size is 20MB.",
            (value) => !value || (value && value.size <= 20971520)
         ),
      Certificate: Yup.mixed()
         .test(
            "fileType",
            "Invalid file type. Only PDF is allowed.",
            (value) => !value || (value && value.type === "application/pdf")
         )
         .test(
            "fileSize",
            "File size is too large. Max size is 20MB.",
            (value) => !value || (value && value.size <= 20971520)
         ),
      Languages: Yup.array().of(
         Yup.object({
            languageId: Yup.string().required("Language is required"),
            level: Yup.number()
               .required("Level is required")
               .typeError("Level must be a number")
         })
      )
   });
   useEffect(() => {
      httpService.get(`Accounts/highest-degree`, (data) => {
         const degreeData = Object.entries(data.data).map(([value, label]) => ({
            value,
            label
         }));
         setDegreeOptions(degreeData);
      });
      httpService.get(`/Countries/languages`, (data) => {
         const languageData = data.data.map((language) => ({
            value: language.id,
            label: language.name
         }));
         setLanguageOptions(languageData);
      });

      httpService.get(`/Accounts/language-level`, (data) => {
         const languageLevelData = Object.entries(data.data).map(([value, label]) => ({
            value,
            label
         }));
         setLanguageLevelOptions(languageLevelData);
      });
   }, []);

   const successCallback = () => {
      navigate("/auth/login");
   };

   const onSubmit = async (values) => {
      setLoading(true);
      try {
         const userId = LocalStorageService.getItem("userId");
         const consultantProfileData = LocalStorageService.getItem("consultantProfileData");

         await dispatch(
            completeConsultantProfile(
               userId,
               {
                  id: userId,
                  FullName: consultantProfileData.FullName,
                  PersonalStatement: consultantProfileData.PersonalStatement,
                  NationalityId: consultantProfileData.NationalityId,
                  ResidenceCountryId: consultantProfileData.ResidenceCountryId,
                  OperatingCountriesIds: consultantProfileData.OperatingCountriesIds,
                  LinkedInProfile: consultantProfileData.LinkedInProfile,
                  PhoneNumber: consultantProfileData.PhoneNumber,
                  ProfilePicture: values.ProfilePicture,
                  HigestDegree: Number(values.HigestDegree),
                  StudyFields: values.StudyFields,
                  EducationalInstitution: values.EducationalInstitution,
                  Expertises: expertise.map((expertise) => ({
                     areaName: expertise.areaName.trim(),
                     areaExperienceYears: parseInt(expertise.areaExperienceYears, 10)
                  })),
                  Languages: languages.map((language) => ({
                     languageId: language.languageId.trim(),
                     level: parseInt(language.level, 10)
                  })),
                  CV: values.CV ? values.CV : "",
                  Certificate: values.Certificate ? values.Certificate : ""
               },
               successCallback
            )
         );
      } catch (error) {
         setLoading(false);
         console.error("Other error:", error);
      } finally {
         setLoading(false);
      }
   };

   return (
      <div className="p-8 p-md-10 pt-xl-10 pb-xl-20 pl-xl-40 pr-xl-40">
         <Link
            to="/auth/expert-registration-step-three"
            className="d-flex align-items-center"
            style={{
               color: "#5492FF",
               // margin: "50px 0 0 16px",
               fontSize: "16px",
               fontWeight: 500,
               cursor: "pointer"
            }}
         >
            <img src={BackIcon} alt="Go back" style={{ marginRight: "10px" }} />
            Go back to profile details
         </Link>
         <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
         >
            {({ values, setFieldValue, errors, touched }) => (
               <Form>
                  <div
                     style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "40px",
                        gap: "32px"
                     }}
                  >
                     <p
                        style={{
                           fontSize: "26px",
                           color: "#4158CF",
                           fontWeight: 600,
                           margin: "0px"
                        }}
                     >
                        Now your professional details
                     </p>
                     <div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                           <div className="form-group fv-plugins-icon-container">
                              {/* select here */}
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 <span style={{ color: "red" }}>* </span>
                                 Profile Picture
                              </label>
                              <div className="upload-profile">
                                 <Upload
                                    accept={[".png", ".jpg"]}
                                    beforeUpload={(file) => {
                                       setFieldValue("ProfilePicture", file);
                                       return false;
                                    }}
                                    listType="picture-card"
                                    className="avatar-uploader-profile"
                                    showUploadList={false}
                                 >
                                    {values.ProfilePicture ? (
                                       <div>
                                          <img
                                             src={FileImport}
                                             alt="avatar"
                                             style={{ width: "40px", height: "40px" }}
                                          />
                                          <div className="file-name">
                                             {values.ProfilePicture.name}
                                          </div>
                                       </div>
                                    ) : (
                                       <div className="uploading-profile">
                                          <img
                                             style={{ width: "40px", height: "40px" }}
                                             src={FileImport}
                                             alt="avatar"
                                          />
                                          <div>
                                             <div>
                                                Drag and drop file here, or{" "}
                                                <span>click to select file it</span>
                                             </div>
                                             <div className="xlsx-file-profile">JPG or PNG</div>
                                             <div className="max-size-file-profile">
                                                Max file size: 20MB
                                             </div>
                                          </div>
                                       </div>
                                    )}
                                 </Upload>
                              </div>
                              {errors.ProfilePicture && touched.ProfilePicture && (
                                 <div className="text-danger">{errors.ProfilePicture}</div>
                              )}
                              {values.ProfilePicture && (
                                 <div style={{ display: "flex", alignItems: "center" }}>
                                    <img
                                       src={FileImport}
                                       alt="avatar"
                                       style={{ width: "40px", height: "40px" }}
                                    />
                                    <div className="file-name" style={{ marginLeft: "10px" }}>
                                       {values.ProfilePicture.name}
                                    </div>
                                    <button
                                       type="button"
                                       style={{
                                          border: "none",
                                          background: "none",
                                          cursor: "pointer",
                                          color: "red",
                                          marginLeft: "auto"
                                       }}
                                       onClick={() => setFieldValue("ProfilePicture", null)}
                                    >
                                       Remove
                                    </button>
                                 </div>
                              )}
                           </div>
                           <p style={{ fontSize: "18px", fontWeight: 600, color: "#4158CF" }}>
                              Education
                           </p>
                           <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 <span style={{ color: "red" }}>* </span>
                                 Highest degree obtained
                              </label>
                              <Select
                                 className="basic-single"
                                 options={degreeOptions}
                                 placeholder="Select your highest degree obtain"
                                 isSearchable
                                 name="HigestDegree"
                                 onChange={(selectedOption) =>
                                    setFieldValue("HigestDegree", selectedOption.value)
                                 }
                                 value={degreeOptions.find(
                                    (option) => option.value === values.HigestDegree
                                 )}
                              />
                              {errors.HigestDegree && touched.HigestDegree && (
                                 <div className="text-danger">{errors.HigestDegree}</div>
                              )}
                           </div>
                           <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 Fields of study
                              </label>
                              <div className="password-section">
                                 <Field
                                    placeholder="Enter the fields of study"
                                    type="text"
                                    name="StudyFields"
                                    className="form-control"
                                    autoComplete="off"
                                 />
                              </div>

                              {errors.StudyFields && touched.StudyFields && (
                                 <div className="text-danger">{errors.StudyFields}</div>
                              )}
                           </div>
                           <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 Educational institution
                              </label>
                              <div className="password-section">
                                 <Field
                                    placeholder="Enter your educational institution"
                                    type="text"
                                    name="EducationalInstitution"
                                    className="form-control"
                                    autoComplete="off"
                                 />
                              </div>

                              {errors.EducationalInstitution && touched.EducationalInstitution && (
                                 <div className="text-danger">{errors.EducationalInstitution}</div>
                              )}
                           </div>
                        </div>
                     </div>
                     <div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                           <p style={{ fontSize: "18px", fontWeight: 600, color: "#4158CF" }}>
                              <span style={{ color: "red" }}>* </span>
                              Areas of expertise
                           </p>
                           <Card
                              style={{
                                 borderRadius: "8px",
                                 border: "1px solid  #CAD6FF",
                                 background: "#F9FBFF"
                              }}
                           >
                              <div style={{ display: "flex", flexDirection: "column" }}>
                                 {expertise.map((item, index) => (
                                    <div
                                       key={index}
                                       style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                       <div
                                          className="form-group fv-plugins-icon-container"
                                          style={{ width: "40%" }}
                                       >
                                          <label
                                             style={{
                                                fontSize: "16px",
                                                color: "#3A3737",
                                                fontWeight: 500,
                                                marginBottom: "10px"
                                             }}
                                          >
                                             Area
                                          </label>
                                          <Field
                                             type="text"
                                             placeholder="Enter the area"
                                             name={`expertise.${index}.areaName`}
                                             className="form-control"
                                             onChange={(event) =>
                                                handleExpertiseChange(
                                                   index,
                                                   "areaName",
                                                   event.target.value,
                                                   setFieldValue
                                                )
                                             }
                                             value={item.areaName}
                                             autoComplete="off"
                                          />
                                          {errors.Expertises?.[index]?.areaName &&
                                             touched.Expertises?.[index]?.areaName && (
                                                <div className="text-danger">
                                                   {errors.Expertises[index].areaName}
                                                </div>
                                             )}
                                       </div>
                                       <div
                                          className="form-group fv-plugins-icon-container"
                                          style={{ width: "40%" }}
                                       >
                                          <label
                                             style={{
                                                fontSize: "16px",
                                                color: "#3A3737",
                                                fontWeight: 500,
                                                marginBottom: "10px"
                                             }}
                                          >
                                             Years of experience
                                          </label>

                                          <Field
                                             type="text"
                                             placeholder="3"
                                             name={`expertise.${index}.areaExperienceYears`}
                                             className="form-control"
                                             onChange={(event) =>
                                                handleExpertiseChange(
                                                   index,
                                                   "areaExperienceYears",
                                                   event.target.value,
                                                   setFieldValue
                                                )
                                             }
                                             onKeyPress={(event) => {
                                                const charCode = event.which || event.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                   event.preventDefault();
                                                }
                                             }}
                                             value={item.areaExperienceYears}
                                             autoComplete="off"
                                          />
                                          {errors.Expertises?.[index]?.areaExperienceYears &&
                                             touched.Expertises?.[index]?.areaExperienceYears && (
                                                <div className="text-danger">
                                                   {errors.Expertises[index].areaExperienceYears}
                                                </div>
                                             )}
                                       </div>
                                       <div style={{ paddingTop: "22px", cursor: "pointer" }}>
                                          <img
                                             onClick={() => handleRemoveExpertise(index)}
                                             src={MinusButtonWithColor}
                                             alt="MinusButtonWithColor"
                                          />
                                       </div>
                                    </div>
                                 ))}
                                 <div className="uploader-profile">
                                    <Button
                                       onClick={handleAddExpertise}
                                       type="dashed"
                                       style={{
                                          width: "100%",
                                          height: "70px",
                                          background: "#F1F6FF"
                                       }}
                                    >
                                       <div className="uploading-profile">
                                          <img src={PlusIconBlue} alt="avatar" />
                                          <div>Add another area</div>
                                       </div>
                                    </Button>
                                 </div>
                              </div>
                           </Card>
                           <p style={{ fontSize: "18px", fontWeight: 600, color: "#4158CF" }}>
                              <span style={{ color: "red" }}>* </span>
                              Languages
                           </p>
                           <Card
                              style={{
                                 borderRadius: "8px",
                                 border: "1px solid  #CAD6FF",
                                 background: "#F9FBFF"
                              }}
                           >
                              <div style={{ display: "flex", flexDirection: "column" }}>
                                 {languages.map((language, index) => (
                                    <div
                                       key={index}
                                       style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                       <div
                                          className="form-group fv-plugins-icon-container"
                                          style={{ width: "40%" }}
                                       >
                                          <label
                                             style={{
                                                fontSize: "16px",
                                                color: "#3A3737",
                                                fontWeight: 500,
                                                marginBottom: "10px"
                                             }}
                                          >
                                             Language
                                          </label>
                                          <Select
                                             className="basic-single"
                                             options={languageOptions}
                                             placeholder="Select language"
                                             isSearchable
                                             onChange={(selectedOption) =>
                                                handleLanguageChange(
                                                   index,
                                                   "languageId",
                                                   selectedOption.value,
                                                   setFieldValue
                                                )
                                             }
                                             value={languageOptions.find(
                                                (option) => option.value === language.languageId
                                             )}
                                          />
                                          {errors.Languages?.[index]?.languageId &&
                                             touched.Languages?.[index]?.languageId && (
                                                <div className="text-danger">
                                                   {errors.Languages[index].languageId}
                                                </div>
                                             )}
                                       </div>
                                       <div
                                          className="form-group fv-plugins-icon-container"
                                          style={{ width: "40%" }}
                                       >
                                          <label
                                             style={{
                                                fontSize: "16px",
                                                color: "#3A3737",
                                                fontWeight: 500,
                                                marginBottom: "10px"
                                             }}
                                          >
                                             Level
                                          </label>

                                          <Select
                                             className="basic-single"
                                             options={languageLevelOptions}
                                             placeholder="Select level"
                                             isSearchable
                                             onChange={(selectedOption) =>
                                                handleLanguageChange(
                                                   index,
                                                   "level",
                                                   selectedOption.value,
                                                   setFieldValue
                                                )
                                             }
                                             value={languageLevelOptions.find(
                                                (option) => option.value === language.level
                                             )}
                                          />

                                          {errors.Languages?.[index]?.level &&
                                             touched.Languages?.[index]?.level && (
                                                <div className="text-danger">
                                                   {errors.Languages[index].level}
                                                </div>
                                             )}
                                       </div>
                                       <div style={{ paddingTop: "22px", cursor: "pointer" }}>
                                          <img
                                             onClick={() => handleRemoveLanguage(index)}
                                             src={MinusButtonWithColor}
                                             alt="MinusButtonWithColor"
                                          />
                                       </div>
                                    </div>
                                 ))}
                                 <div className="uploader">
                                    <Button
                                       onClick={handleAddLanguage}
                                       type="dashed"
                                       style={{
                                          width: "100%",
                                          height: "70px",
                                          background: "#F1F6FF"
                                       }}
                                    >
                                       <div className="uploading">
                                          <img src={PlusIconBlue} alt="avatar" />
                                          <div>Add another language</div>
                                       </div>
                                    </Button>
                                 </div>
                              </div>
                           </Card>

                           <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 Upload your CV
                              </label>
                              <div className="upload-profile">
                                 <Upload
                                    accept=".pdf"
                                    beforeUpload={(file) => {
                                       setFieldValue("CV", file);
                                       return false;
                                    }}
                                    listType="picture-card"
                                    className="avatar-uploader-profile"
                                    showUploadList={false}
                                 >
                                    {values.CV ? (
                                       <div>
                                          <img
                                             src={FileImport}
                                             alt="avatar"
                                             style={{ width: "40px", height: "40px" }}
                                          />
                                          <div className="file-name">{values.CV.name}</div>
                                       </div>
                                    ) : (
                                       <div className="uploading-profile">
                                          <img
                                             style={{ width: "40px", height: "40px" }}
                                             src={FileImport}
                                             alt="avatar"
                                          />
                                          <div>
                                             <div>
                                                Drag and drop file here, or{" "}
                                                <span>click to select file it</span>
                                             </div>
                                             <div className="xlsx-file-profile">PDF</div>
                                             <div className="max-size-file-profile">
                                                Max file size: 20MB
                                             </div>
                                          </div>
                                       </div>
                                    )}
                                 </Upload>
                              </div>
                              {errors.CV && touched.CV && (
                                 <div className="text-danger">{errors.CV}</div>
                              )}
                              {values.CV && (
                                 <div style={{ display: "flex", alignItems: "center" }}>
                                    <img
                                       src={FileImport}
                                       alt="avatar"
                                       style={{ width: "40px", height: "40px" }}
                                    />
                                    <div className="file-name" style={{ marginLeft: "10px" }}>
                                       {values.CV.name}
                                    </div>
                                    <button
                                       type="button"
                                       style={{
                                          border: "none",
                                          background: "none",
                                          cursor: "pointer",
                                          color: "red",
                                          marginLeft: "auto"
                                       }}
                                       onClick={() => setFieldValue("CV", null)}
                                    >
                                       Remove
                                    </button>
                                 </div>
                              )}
                           </div>
                           <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 Upload a certification or a special training
                              </label>
                              <div className="upload-profile">
                                 <Upload
                                    accept=".pdf"
                                    beforeUpload={(file) => {
                                       setFieldValue("Certificate", file);
                                       return false;
                                    }}
                                    listType="picture-card"
                                    className="avatar-uploader-profile"
                                    showUploadList={false}
                                 >
                                    {values?.Certificate ? (
                                       <div>
                                          <img
                                             src={FileImport}
                                             alt="avatar"
                                             style={{ width: "40px", height: "40px" }}
                                          />
                                          <div className="file-name">{values.Certificate.name}</div>
                                       </div>
                                    ) : (
                                       <div className="uploading-profile">
                                          <img
                                             style={{ width: "40px", height: "40px" }}
                                             src={FileImport}
                                             alt="avatar"
                                          />
                                          <div>
                                             <div>
                                                Drag and drop file here, or{" "}
                                                <span>click to select file it</span>
                                             </div>
                                             <div className="xlsx-file-profile">PDF</div>
                                             <div className="max-size-file-profile">
                                                Max file size: 20MB
                                             </div>
                                          </div>
                                       </div>
                                    )}
                                 </Upload>
                              </div>
                              {errors.Certificate && touched.Certificate && (
                                 <div className="text-danger">{errors.Certificate}</div>
                              )}
                              {values.Certificate && (
                                 <div style={{ display: "flex", alignItems: "center" }}>
                                    <img
                                       src={FileImport}
                                       alt="avatar"
                                       style={{ width: "40px", height: "40px" }}
                                    />
                                    <div className="file-name" style={{ marginLeft: "10px" }}>
                                       {values.Certificate.name}
                                    </div>
                                    <button
                                       type="button"
                                       style={{
                                          border: "none",
                                          background: "none",
                                          cursor: "pointer",
                                          color: "red",
                                          marginLeft: "auto"
                                       }}
                                       onClick={() => setFieldValue("Certificate", null)}
                                    >
                                       Remove
                                    </button>
                                 </div>
                              )}
                           </div>
                        </div>
                     </div>

                     <div
                        style={{
                           display: "flex",
                           alignItems: "center",
                           gap: "40px",
                           justifyContent: "center"
                        }}
                     >
                        <button
                           type="submit"
                           // onClick={() => onSubmit(values)}
                           className={`btn sign-btn font-weight-bold my-3`}
                           disabled={loading}
                        >
                           {loading ? <Spin /> : "Submit"}
                        </button>
                     </div>
                  </div>
               </Form>
            )}
         </Formik>
      </div>
   );
}
export default RegistrationExpertStepFour;
