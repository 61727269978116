import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../../_helpers";
import { Button, ButtonGroup, Grid } from "@material-ui/core";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Radio, ConfigProvider, Select } from "antd";
import { EmptyBox } from "../../../../../../app/assets/icons";

function MenuAssesmentList({ menuItem, typeAssessment, kind }) {
   //  const [activeAssessment, setActiveAssessment] = useState(menuItem?.length ? menuItem[0].id : 0);
   const [activeAssessment, setActiveAssessment] = useState("");
   const location = useLocation();
   const navigate = useNavigate();
   const params = useParams();
   const [noAssessmentMessage, setNoAssessmentMessage] = useState("");
   const [sortingOption, setSortingOption] = useState("1");
   const [sortedMenuItems, setSortedMenuItems] = useState([]);

   //  useEffect(() => {
   //     setActiveAssessment(menuItem.length ? menuItem[0].id : 0);
   //  }, [menuItem]);

   const getMenuItemActive = (url, hasSubmenu = false) => {
      return checkIsActive(location, url)
         ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
         : "";
   };

   const getColor = (completed, number) => {
      if (number) {
         const percent = completed * (100 / number);
         const hue = ((percent / 100) * 120).toString(10);
         return ["hsl(", hue, ",100%,42%)"].join("");
      } else {
         return "#DDE9FF";
      }
   };

   const clickedCard = (e) => {
      setActiveAssessment(e);
      if (kind === "self-assessment") {
         navigate(`/workspace/assessment/self-assessment/${e}`);
      } else {
         navigate(`/workspace/assessment/guided-assessment/${e}`);
      }
   };

   useEffect(() => {
      if (menuItem?.length === 0 && kind === "self-assessment") {
         navigate(`/workspace/assessment/self-assessment/no-self-assessment`);
      } else if (menuItem?.length === 0 && kind === "guided-assessment") {
         navigate(`/workspace/assessment/guided-assessment/no-guided-assessment`);
      }
   }, [menuItem, kind]);

   useEffect(() => {
      if (params["*"].includes("assessment/self-assessment/")) {
         setNoAssessmentMessage("No self assessments here");
      } else if (params["*"].includes("assessment/guided-assessment/")) {
         setNoAssessmentMessage("No guided assessments here");
      }

      if (params["*"].includes("assessment/self-assessment/")) {
         if (params["*"].split("/")[3]) {
            setActiveAssessment(params["*"].split("/")[3]);
         } else {
            if (menuItem?.length) {
               clickedCard(menuItem[0].id);
            }
         }
      } else if (params["*"].includes("assessment/guided-assessment/")) {
         if (params["*"].split("/")[3]) {
            setActiveAssessment(params["*"].split("/")[3]);
         } else {
            if (menuItem?.length) {
               clickedCard(menuItem[0].id);
            }
         }
      }
      const sortedItems = Array.isArray(menuItem) ? [...menuItem] : [];
      sortedItems.sort((a, b) => {
         const completionRatioA =
            a.totalSurveys !== 0 ? a.totalCompletedSurveys / a.totalSurveys : 0;
         const completionRatioB =
            b.totalSurveys !== 0 ? b.totalCompletedSurveys / b.totalSurveys : 0;

         if (sortingOption === "2") {
            return completionRatioA - completionRatioB;
         } else {
            return completionRatioB - completionRatioA;
         }
      });

      setSortedMenuItems(sortedItems);
   }, [params, menuItem, sortingOption]);

   return (
      <>
         <div className="assessments-menu-list">
            {sortedMenuItems?.length >= 1 ? (
               <>
                  <div className="assessments-menu-options">
                     <h2>
                        {typeAssessment === "self-assessment"
                           ? "Self assessment"
                           : "Guided assessment"}
                     </h2>

                     <div className="assessments-menu-sort">
                        <h3 className="assessment-sort-title">Sort progress by:</h3>
                        <Select
                           style={{ width: "100%" }}
                           placeholder="Highest to lowest"
                           value={sortingOption}
                           onChange={(value) => setSortingOption(value)}
                        >
                           <Select.Option value="1">Highest to lowest</Select.Option>
                           <Select.Option value="2">Lowest to highest</Select.Option>
                        </Select>
                     </div>
                  </div>
                  <div className="assessments-cards">
                     <Radio.Group value={activeAssessment}>
                        {sortedMenuItems?.map((item, i) => {
                           return (
                              <React.Fragment key={i}>
                                 <div
                                    className={`assessments-card ${
                                       activeAssessment === item?.id ? "activeCard" : ""
                                    }`}
                                    key={i}
                                    onClick={() => clickedCard(item?.id)}
                                 >
                                    <div className="assessments-card-inner">
                                       <div className="assessments-card-upper">
                                          <h3 className="assessment-title">{item?.name}</h3>
                                          <Radio value={item?.id}></Radio>
                                       </div>
                                       {(!item?.status || item?.status === "Approved") &&
                                       item?.status !== "" ? (
                                          <div className="assessments-card-lower">
                                             <h3
                                                style={{
                                                   color: getColor(
                                                      item?.totalCompletedSurveys,
                                                      item?.totalSurveys
                                                   )
                                                }}
                                             >
                                                {item?.totalCompletedSurveys}/{item?.totalSurveys}{" "}
                                                surveys
                                             </h3>
                                             <div className="assessments-progress-bar">
                                                <div
                                                   className="assessments-progress"
                                                   // style={{
                                                   //    width: 1 * (100 / item?.totalSurveys) + "%",
                                                   //    backgroundColor: getColor(
                                                   //       item?.totalCompletedSurveys *
                                                   //          (100 / item?.totalSurveys)
                                                   //    )
                                                   // }}
                                                   style={{
                                                      width: item?.totalSurveys
                                                         ? item?.totalCompletedSurveys *
                                                              (100 / item?.totalSurveys) +
                                                           "%"
                                                         : "0",
                                                      backgroundColor: getColor(
                                                         item?.totalCompletedSurveys,
                                                         item?.totalSurveys
                                                      )
                                                   }}
                                                ></div>
                                             </div>
                                          </div>
                                       ) : item?.status === "Pending" ? (
                                          <div className="assessments-card-lower assessments-card-status assessments-card-pending">
                                             <div className="assessment-image">
                                                <img
                                                   src={
                                                      process.env.PUBLIC_URL +
                                                      "/media/svg/icons/General/locker.svg"
                                                   }
                                                   alt="lock"
                                                />
                                             </div>
                                             <div className="assessment-status">
                                                <div className="status-button"></div>
                                                <span>Answer pending</span>
                                             </div>
                                          </div>
                                       ) : item?.status === "Rejected" ? (
                                          <div className="assessments-card-lower assessments-card-status assessments-card-rejected">
                                             <div className="assessment-image">
                                                <img
                                                   src={
                                                      process.env.PUBLIC_URL +
                                                      "/media/svg/icons/General/locker.svg"
                                                   }
                                                   alt="lock"
                                                />
                                             </div>
                                             <div className="assessment-status">
                                                <div className="status-button"></div>
                                                <span>Rejected</span>
                                             </div>
                                          </div>
                                       ) : (
                                          <div className="assessments-card-lower assessments-card-status assessments-card-unlock">
                                             <div className="assessment-image">
                                                <img
                                                   src={
                                                      process.env.PUBLIC_URL +
                                                      "/media/svg/icons/General/locker.svg"
                                                   }
                                                   alt="lock"
                                                />
                                             </div>
                                             <div className="assessment-status">
                                                <span>Unlock assessment</span>
                                             </div>
                                          </div>
                                       )}
                                    </div>
                                 </div>
                              </React.Fragment>
                           );
                        })}
                     </Radio.Group>
                  </div>
               </>
            ) : (
               <div
                  style={{
                     display: "flex",
                     flexDirection: "column",
                     backgroundColor: "#fff",
                     height: "100vh",
                     width: "100%",
                     overflow: "hidden",
                     justifyContent: "center",
                     alignItems: "center",
                     padding: "0px 32px 0px"
                  }}
               >
                  <img src={EmptyBox} alt="" width={80} height={50} />
                  <p
                     style={{
                        textAlign: "center",
                        color: "#4158CF",
                        fontSize: "16px",
                        fontWeight: 500
                     }}
                  >
                     {noAssessmentMessage}
                  </p>
               </div>
            )}
         </div>
      </>
   );
   //  return menuItem.type === "divider" ? (
   //     <>
   //        <li key={menuItem.id} className="menu-section ">
   //           <h4 className="menu-text">{menuItem.title}</h4>
   //           <i className="menu-icon flaticon-more-v2"></i>
   //        </li>
   //        <li>
   //           <p style={{ color: "#036051", fontSize: "12px", width: "70%;", marginLeft: "26px" }}>
   //              This assessment is checked by:
   //              {menuItem?.consultant != null ? " " + menuItem?.consultant : ""}
   //           </p>
   //        </li>
   //     </>
   //  ) : (
   //     <li
   //        key={menuItem.id}
   //        className={`menu-item menu-item-submenu ${getMenuItemActive(menuItem.url)}`}
   //        aria-haspopup="true"
   //        data-menu-toggle="hover"
   //     >
   //        <NavLink className="menu-link menu-toggle" to={menuItem.url}>
   //           <span className="svg-icon menu-icon">
   //              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
   //           </span>
   //           <span className="menu-text">
   //              {menuItem.title} - {menuItem.progress}%
   //           </span>
   //        </NavLink>
   //     </li>
   //  );
}

export default MenuAssesmentList;
