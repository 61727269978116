import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../../_helpers";
import { Button, ButtonGroup, Grid } from "@material-ui/core";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Radio, ConfigProvider, Select, Input } from "antd";
import { SearchOutlined } from "@material-ui/icons";
import { getPercentage } from "../../../../../../app/pages/helper";
import { useResultStore } from "../../../useResultStore";

function MenuResultsList({ menuItem, kind }) {
   //  const [activeAssessment, setActiveAssessment] = useState(menuItem?.length ? menuItem[0].id : 0);
   // const [activeResult, setActiveResult] = useState("");

   const { activeResult, setActiveResult } = useResultStore();

   const location = useLocation();
   const navigate = useNavigate();
   const params = useParams();

   const getMenuItemActive = (url, hasSubmenu = false) => {
      return checkIsActive(location, url)
         ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
         : "";
   };

   const getColor = (completed, number) => {
      if (number) {
         const percent = completed * (100 / number);
         const hue = ((percent / 100) * 120).toString(10);
         return ["hsl(", hue, ",100%,42%)"].join("");
      } else {
         return "#DDE9FF";
      }
   };

   const clickedCard = (e) => {
      setActiveResult(e);

      navigate(`/workspace/results/results/${e}`);
   };

   useEffect(() => {
      if (menuItem?.length === 0) {
         navigate(`/workspace/results/results/no-result`);
      }
   }, [menuItem]);

   useEffect(() => {
      if (params["*"].includes("workspace/results/results")) {
         if (params["*"].split("/").length > 3 && params["*"].split("/")[3]) {
            setActiveResult(params["*"].split("/")[3]);
         } else {
            if (menuItem?.length) {
               clickedCard(menuItem[0].id);
            }
         }
      }
   }, []);

   return (
      <>
         <div className="assessments-menu-list">
            <div className="assessments-menu-options">
               <h2>My Results</h2>

               <div className="assessments-menu-sort">
                  <h3 className="assessment-sort-title">
                     Select an assessment to see its results in detail.
                  </h3>
                  <Input
                     autoComplete="off"
                     type="text"
                     prefix={<SearchOutlined />}
                     style={{ width: "100%" }}
                     placeholder="Search for an assessment"
                  />
               </div>
            </div>
            <div className="assessments-cards">
               {menuItem?.length ? (
                  <Radio.Group value={activeResult}>
                     {menuItem?.map((item, i) => {
                        return (
                           <div key={i}>
                              <div
                                 className={`assessments-card ${
                                    activeResult === item?.id ? "activeCard" : ""
                                 }`}
                                 key={i}
                                 onClick={() => clickedCard(item?.id)}
                              >
                                 <div className="assessments-card-inner" style={{ gap: "10px" }}>
                                    <div className="assessments-card-upper">
                                       <h3 className="assessment-title">{item?.name}</h3>
                                       <Radio value={item?.id}></Radio>
                                    </div>
                                    <div
                                       className="assessment-results justify-content-between"
                                       style={{ gap: "10px" }}
                                    >
                                       <div>
                                          <span className="assessment-results-text">Score:</span>
                                          <span
                                             className="assessment-results-result"
                                             style={{
                                                color: getColor(item?.score, item?.maxScore)
                                             }}
                                          >{`${getPercentage(item?.score, item?.maxScore).toFixed(
                                             2
                                          )}%`}</span>
                                       </div>

                                       <div
                                          className="d-flex align-items-center"
                                          style={{
                                             padding: "2px 5px",
                                             borderRadius: "4px",
                                             border: `1px solid ${
                                                item?.isGuided
                                                   ? "rgb(65, 88, 207)"
                                                   : "rgb(121, 214, 212)"
                                             }`,

                                             backgroundColor: item?.isGuided
                                                ? "rgb(223, 230, 255)"
                                                : "rgb(242, 254, 254)"
                                          }}
                                       >
                                          <h6 className="m-0" style={{ fontWeight: 400 }}>
                                             {item?.isGuided ? "GA" : "SA"}
                                          </h6>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        );
                     })}
                  </Radio.Group>
               ) : (
                  <>
                     <p style={{ textAlign: "center", color: "#555" }}>No active assessments </p>
                  </>
               )}
            </div>
         </div>
      </>
   );
}

export default MenuResultsList;
