import React, { useEffect, useState } from "react";
import { GoBack } from "../../../../../../app/assets/icons";
import Select from "react-select";
import { httpService } from "../../../../../../app/base/services/httpService.service";
import { Button } from "antd";

const FilterOptionsComponent = ({
   onClose,
   onFilterChange,
   onApplyFilters,
   initialFilters,
   onClearAllFilters
}) => {
   const [orgSizeList, setOrgSizeList] = useState([]);
   const [countryList, setCountryList] = useState([]);
   const [countryOfOperationList, setCountryOfOperationList] = useState([]);
   const [orgSectors, setOrgSectorsList] = useState([]);
   const [clearButtonClick, setClearButtonClick] = useState(0);

   const [formInitialValues, setFormInitialValues] = useState({
      orgSizes: initialFilters.orgSizes,
      orgSectors: initialFilters.orgSectors,
      countries: initialFilters.countries,
      countryOfOperation: initialFilters.countryOfOperation
   });

   const clearFilters = () => {
      setFormInitialValues((prevValues) => ({
         ...prevValues,
         orgSizes: "",
         orgSectors: "",
         countries: [],
         countryOfOperation: ""
      }));
      onApplyFilters({});
      setClearButtonClick((prevClick) => prevClick + 1);
   };

   useEffect(() => {
      httpService.get(`/Organizations/sizes`, (res) => {
         const data = res.data.map((orgSize) => {
            return {
               value: orgSize.id,
               label: orgSize.range
            };
         });
         setOrgSizeList(data);
      });

      httpService.get(`/Countries`, (res) => {
         const data = res.data.map((country) => {
            return {
               value: country.id,
               label: country.code + "-" + country.name
            };
         });
         setCountryList(data);
         setCountryOfOperationList(data);
      });
      httpService.get(`/Organizations/sectors`, (res) => {
         const data = res.data.map((orgSectors) => {
            return {
               value: orgSectors.id,
               label: orgSectors.name
            };
         });
         setOrgSectorsList(data);
      });
   }, []);

   const handleFilterChange = (fieldName, value) => {
      setFormInitialValues((prevValues) => ({
         ...prevValues,
         [fieldName]: value
      }));
      onFilterChange(fieldName, value);
   };

   const handleApplyFilters = () => {
      const selectedFilters = {
         orgSizes: formInitialValues.orgSizes,
         countries: formInitialValues.countries,
         orgSectors: formInitialValues.orgSectors,
         countryOfOperation: formInitialValues.countryOfOperation
      };
      onApplyFilters(selectedFilters);
      onClose();
   };

   return (
      <div className="filter-options">
         <div onClick={onClose} className="close-btn">
            <img src={GoBack} alt="GoBack" />
            <div className="go-back-text">Go back to results</div>
         </div>
         <div className="filter-organization">
            <h1 className="filter-organization-title">Filter Organization</h1>
            <h1 className="filter-organization-description">
               Filter and target organizations to compare their results using the following filters
            </h1>
         </div>
         <div className="select-filters">
            <div className="main-select-size">
               <div className="search-box">
                  <label className="size-label">Organization size</label>
               </div>
               <Select
                  isClearable
                  key={`orgSizes-select-${clearButtonClick}`}
                  name="orgSizes"
                  placeholder="Select range size"
                  options={orgSizeList}
                  value={formInitialValues.orgSizes}
                  onChange={(selectedOrgSizes) => {
                     handleFilterChange("orgSizes", selectedOrgSizes);
                  }}
                  styles={{
                     option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "hsl(0, 0%, 20%)"
                     })
                  }}
               />
            </div>
            <div className="main-select-base-country">
               <div className="search-box">
                  <label className="base-country-label">Operating Countries</label>
               </div>
               <Select
                  isClearable
                  key={`countries-select-${clearButtonClick}`}
                  name="countries"
                  value={formInitialValues.countries}
                  placeholder="Select the country"
                  options={countryList}
                  onChange={(selectedCountry) => {
                     handleFilterChange("countries", selectedCountry);
                  }}
                  styles={{
                     option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "hsl(0, 0%, 20%)"
                     })
                  }}
               />
            </div>
            <div className="main-select-country-operation">
               <div className="search-box">
                  <label className="country-operation-label">Country of operation</label>
               </div>
               <Select
                  isClearable
                  key={`countryOfOperation-select-${clearButtonClick}`}
                  name="countryOfOperationList"
                  value={formInitialValues.countryOfOperation}
                  placeholder="Select the country"
                  options={countryOfOperationList}
                  onChange={(selectedCountry) => {
                     handleFilterChange("countryOfOperation", selectedCountry);
                  }}
                  styles={{
                     option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "hsl(0, 0%, 20%)"
                     })
                  }}
               />
            </div>
            <div className="main-select-sectors">
               <div className="search-box">
                  <label className="sectors-label">Sectors</label>
               </div>
               <Select
                  isMulti
                  key={`orgSectors-select-${clearButtonClick}`}
                  name="orgSectors"
                  value={formInitialValues.orgSectors}
                  placeholder="Select the sectors"
                  options={orgSectors}
                  onChange={(selectedOrgSectors) => {
                     handleFilterChange("orgSectors", selectedOrgSectors);
                  }}
                  styles={{
                     option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "hsl(0, 0%, 20%)"
                     })
                  }}
               />
            </div>
            <div className="filter-buttons">
               <Button className="apply-filters-button" onClick={handleApplyFilters}>
                  <h1>Apply filters</h1>
               </Button>
               <Button
                  className="clear-all-filters-button"
                  onClick={() => {
                     clearFilters();
                     onClearAllFilters();
                  }}
               >
                  <h1>Clear all filters</h1>
               </Button>
            </div>
         </div>
      </div>
   );
};

export default FilterOptionsComponent;
