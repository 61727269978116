import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { checkIsActive } from "../../../../../_helpers";
import { Button } from "@material-ui/core";
import { Checkbox, Input, Spin } from "antd";
import { SearchOutlined } from "@material-ui/icons";
import { StrategyTasksWork } from "../../../../../../app/assets/icons";
import FilterOptionsComponent from "./FilterOptionsComponent";
import { httpService } from "../../../../../../app/base/services/httpService.service";
import AuthService from "../../../../../../app/base/services/authentication.service";
import { UserRolesEnum } from "../../../../../../app/pages/auth/userRoles.enum";
import { SessionService } from "../../../../../../app/base/services/session.service";
import { getPercentage } from "../../../../../../app/pages/helper";
import { useResultStore } from "../../../../../../app/pages/workspace/results/components/ResultsStore";

function MenuResultsListSuperadmin({ setIsLoading }) {
   const [activeResult, setActiveResult] = useState([]);
   const [showFilters, setShowFilters] = useState(false);
   const [orgLoading, setOrgsLoading] = useState(false);
   const location = useLocation();
   const navigate = useNavigate();
   const params = useParams();
   const [menuItem, setMenuItem] = useState("");
   const [organizationName, setOrganizationName] = useState("");
   const currentWorkspaceId = SessionService.getItem("workspaceID");
   const {
      allOrganizations,
      setAllOrganizations,
      selectedAssessments,
      setSelectedSubmissionRounds,
      selectedSubmissionRounds
   } = useResultStore();

   const [selectedFilters, setSelectedFilters] = useState({
      orgSizes: "",
      countries: "",
      orgSectors: [],
      countryOfOperation: ""
   });

   const handleFilterChange = (fieldName, value) => {
      setSelectedFilters((prevFilters) => ({
         ...prevFilters,
         [fieldName]: value
      }));
   };

   const handleApplyFilters = (selectedFilters) => {
      const payload = {};

      if (organizationName !== "") {
         payload.OrganizationName = organizationName;
      }

      if (selectedFilters.orgSizes) {
         payload.OrganizationSizeId = selectedFilters.orgSizes.value;
      }
      if (selectedFilters.countries) {
         payload.BaseCountryId = selectedFilters.countries.value;
      }
      if (selectedFilters.countryOfOperation) {
         payload.OperatingCountryId = selectedFilters.countryOfOperation.value;
      }
      if (selectedFilters.orgSectors && selectedFilters.orgSectors.length > 0) {
         payload.SectorIds = selectedFilters.orgSectors.map((sector) => sector.value);
      }

      const queryString = new URLSearchParams(payload).toString();
      const apiUrl =
         AuthService.isAuthorized([
            UserRolesEnum.InternationalOrganization,
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Donor,
            UserRolesEnum.Consultant
         ]) && `Results/${currentWorkspaceId}/organizations?${queryString}`;

      httpService.get(apiUrl, (res) => {
         setMenuItem(res.data);
         setIsLoading(false);
      });
   };

   useEffect(() => {
      const timeout = setTimeout(() => {
         if (selectedAssessments?.value && selectedSubmissionRounds?.value) {
            const payload = {
               OrganizationName: organizationName !== "" ? organizationName : "",
               SubmissionRoundId: selectedSubmissionRounds?.value
            };
   
            setOrgsLoading(true);
            const queryString = new URLSearchParams(payload).toString();
            const apiUrl =
               AuthService.isAuthorized([
                  UserRolesEnum.InternationalOrganization,
                  UserRolesEnum.SuperAdmin,
                  UserRolesEnum.Donor,
                  UserRolesEnum.Consultant
               ]) &&
               `Results/${currentWorkspaceId}/organizations?AssessmentId=${selectedAssessments?.value}&${queryString}`;
   
            httpService.get(apiUrl, (res) => {
               setMenuItem(res.data);
               setIsLoading(false);
               setOrgsLoading(false);
            });
         } else {
            setIsLoading(false);
         }
      },500);

      return () => {
         clearTimeout(timeout);
      }
   }, [organizationName]);

   const clickedCard = (e) => {
      const isExisting = allOrganizations.some((el) => el === e);
      if (isExisting) {
         const filteredResults = allOrganizations.filter((el) => el !== e);
         setAllOrganizations(filteredResults);
      } else {
         setAllOrganizations([...allOrganizations, e]);
      }
   };

   useEffect(() => {
      if (selectedAssessments?.value && selectedSubmissionRounds?.value) {
         const payload = {
            OrganizationName: organizationName !== "" ? organizationName : ""
         };

         setOrgsLoading(true);
         const queryString = new URLSearchParams(payload).toString();
         const apiUrl =
            AuthService.isAuthorized([
               UserRolesEnum.InternationalOrganization,
               UserRolesEnum.SuperAdmin,
               UserRolesEnum.Donor,
               UserRolesEnum.Consultant
            ]) &&
            `Results/${currentWorkspaceId}/organizations?AssessmentId=${selectedAssessments?.value}&SubmissionRoundId=${selectedSubmissionRounds?.value}&${queryString}`;

         httpService.get(apiUrl, (res) => {
            setMenuItem(res.data);
            setIsLoading(false);
            setOrgsLoading(false);
         });
      } else {
         setIsLoading(false);
      }
   }, [selectedSubmissionRounds]);

   useEffect(() => {
      setMenuItem([]);
   }, [selectedAssessments]);

   // useEffect(() => {
   //    console.log("TEST", selectedAssessments);
   // }, [selectedAssessments]);

   // useEffect(() => {
   //    setAllOrganizations(activeResult);
   // }, [activeResult]);

   // useEffect(() => {
   //    if (menuItem?.length > 0) {
   //       clickedCard(menuItem[0]?.id);
   //    }
   // }, [menuItem]);

   const handleFilterClick = () => {
      setShowFilters(true);
   };

   const handleCloseFilters = () => {
      setShowFilters(false);
   };

   const handleClearAllFilters = () => {
      setSelectedFilters({
         orgSizes: "",
         countries: "",
         orgSectors: [],
         countryOfOperation: ""
      });
   };
   return (
      <>
         {showFilters ? (
            <FilterOptionsComponent
               onClose={handleCloseFilters}
               onFilterChange={handleFilterChange}
               onApplyFilters={handleApplyFilters}
               initialFilters={selectedFilters}
               onClearAllFilters={handleClearAllFilters}
            />
         ) : (
            <div>
               <div className="filter-container">
                  <div className="filter-main">
                     <img src={StrategyTasksWork} alt="StrategyTasksWork" />
                     <div>Compare results</div>
                  </div>
                  <h1>
                     Use this tool to filter through various organizations based on your criteria
                     and compare their respective results.
                  </h1>
                  <Button className="filter-button" onClick={handleFilterClick}>
                     <div>Show filters</div>
                  </Button>
               </div>
               <div className="assessments-menu-list">
                  <div className="assessments-menu-options">
                     <h2>Select your organization</h2>

                     <div className="assessments-menu-sort">
                        <h3 className="assessment-sort-title blue">
                           <span>Select up to 8 organizations from the list below</span> to have
                           their results compared
                        </h3>
                        <Input
                           autoComplete="off"
                           type="text"
                           prefix={<SearchOutlined />}
                           style={{ width: "100%" }}
                           value={organizationName}
                           onChange={(e) => setOrganizationName(e.target.value)}
                           placeholder="Search for an organization"
                        />
                     </div>
                  </div>
                  <div className="assessments-cards">
                     {!orgLoading ? (
                        menuItem?.length ? (
                           <>
                              {menuItem?.map((item, i) => {
                                 return (
                                    <>
                                       <div
                                          className={`assessments-card admin-result ${
                                             allOrganizations?.find((e) => e === item?.id)
                                                ? "activeCard"
                                                : ""
                                          }`}
                                          key={i}
                                          onClick={() => clickedCard(item?.id)}
                                       >
                                          <div className="assessments-card-inner">
                                             <div className="assessments-card-upper">
                                                <div className="assessment-upper-image">
                                                   <div className="assessment-inner-image">
                                                      <img
                                                         src={
                                                            process.env.PUBLIC_URL +
                                                            "/media/svg/icons/General/office-building-1--office-building-work.svg"
                                                         }
                                                         alt="buildings"
                                                      />
                                                   </div>
                                                   <Checkbox
                                                      checked={
                                                         allOrganizations?.find(
                                                            (e) => e === item?.id
                                                         )
                                                            ? true
                                                            : false
                                                      }
                                                   ></Checkbox>
                                                </div>
                                                <h3
                                                   className="assessment-title"
                                                   style={{ wordBreak: "break-word" }}
                                                >
                                                   {item?.name}
                                                </h3>
                                             </div>
                                             <div className="assessment-lower-info">
                                                <div className="assessment-info">
                                                   <div className="assessment-location">
                                                      <img
                                                         src={
                                                            process.env.PUBLIC_URL +
                                                            "/media/svg/icons/General/map-pin-location.svg"
                                                         }
                                                         alt="map-pin"
                                                      />
                                                      <span style={{ wordBreak: "break-word" }}>
                                                         {item?.baseCountryName}
                                                      </span>
                                                   </div>
                                                   <div className="assessment-members">
                                                      <img
                                                         src={
                                                            process.env.PUBLIC_URL +
                                                            "/media/svg/icons/General/group-users-people-community.svg"
                                                         }
                                                         alt="users-community"
                                                      />
                                                      <span>{item?.membersCount}</span>
                                                   </div>
                                                </div>
                                                <div className="assessment-results">
                                                   <span className="assessment-results-text">
                                                      Score:
                                                   </span>
                                                   <span className="assessment-results-result">{`${getPercentage(
                                                      item?.averageScore || 0,
                                                      item?.averageMaxScore || 0
                                                   ).toFixed(2)}%`}</span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </>
                                 );
                              })}
                           </>
                        ) : (
                           <>
                              <p style={{ textAlign: "center", color: "#555" }}>
                                 No active assessments and submission round
                              </p>
                           </>
                        )
                     ) : (
                        <Spin
                           style={{
                              position: "relative",
                              left: "50%",
                              transform: "translate(-50%)",
                              top: "20px"
                           }}
                        />
                     )}
                  </div>
               </div>
            </div>
         )}
      </>
   );
}

export default MenuResultsListSuperadmin;
