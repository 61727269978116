import React, { createContext, useState, useEffect } from "react";
import AuthService from "../../../../../app/base/services/authentication.service";
import { httpService } from "../../../../../app/base/services/httpService.service";
import PaymentModal from "./PaymentModal";
import { useLocation, useNavigate } from "react-router-dom";

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
   const [notifications, setNotifications] = useState([]);
   const [unreadCount, setUnreadCount] = useState(0);
   const [user, setUser] = useState(AuthService.getUser());
   const [prevNotificationCount, setPrevNotificationCount] = useState(0);
   const [pageNumber, setPageNumber] = useState(1);
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [modalMessage, setModalMessage] = useState("");
   const location = useLocation();
   const [loadingMarkAllRead, setLoadingMarkAllRead] = useState(false);
   const [loadingMarkOneRead, setLoadingMarkOneRead] = useState({});

   const setPage = (page) => {
      setPageNumber(page);
   };

   const getNotifications = async () => {
      try {
         await httpService.get(`/Notifications/all/${user?.id}`, (res) => {
            const sortedNotifications = res?.data?.sort((a, b) => {
               if (a?.isRead !== b?.isRead) {
                  return a.isRead ? 1 : -1;
               }
               return new Date(b?.createdDate) - new Date(a?.createdDate);
            });
            setNotifications(sortedNotifications);
            const newUnreadNotifications = sortedNotifications?.filter(
               (notification) => !notification?.isRead
            );
            const newUnreadCount = newUnreadNotifications?.length;
            setUnreadCount(newUnreadCount);
         });
      } catch (error) {
         if (error?.response && error?.response?.data && error?.response?.data?.Errors) {
            const errorDetail = error?.response?.data?.Errors?.[0];

            if (
               errorDetail?.code === "invalid_subscription_superadmin" &&
               location?.pathname !== "/admin/payment-information"
            ) {
               setModalMessage(errorDetail?.message);
               setIsModalVisible(true);
            }
         } else {
            console.error(error);
         }
      }
   };

   useEffect(() => {
      if (user) {
         getNotifications();
      } else {
         setNotifications([]);
         setUnreadCount(0);
         setPrevNotificationCount(0);
      }
   }, [user]);

   const handleNewNotifications = () => {
      getNotifications();
   };

   const markNotificationAsRead = async (notificationId) => {
      try {
         setLoadingMarkOneRead((prev) => ({
            ...prev,
            [notificationId]: true
         }));
         await httpService.post("/Notifications/read", { id: notificationId });
         setNotifications((prevNotifications) =>
            prevNotifications?.map((notification) =>
               notification?.id === notificationId
                  ? { ...notification, isRead: true }
                  : notification
            )
         );
         setUnreadCount((prevCount) => Math.max(0, prevCount - 1));
      } catch (error) {
         console.error(error);
      } finally {
         setLoadingMarkOneRead((prev) => ({
            ...prev,
            [notificationId]: false
         }));
      }
   };

   const markAllNotificationsAsRead = async () => {
      try {
         setLoadingMarkAllRead(true);
         await httpService.post("/Notifications/read/all", { userId: user.id });
         setNotifications((prevNotifications) =>
            prevNotifications?.map((notification) => ({ ...notification, isRead: true }))
         );
         setUnreadCount(0);
      } catch (error) {
         console.log(error);
      } finally {
         setLoadingMarkAllRead(false);
      }
   };

   const value = {
      notifications,
      unreadCount,
      markNotificationAsRead,
      markAllNotificationsAsRead,
      setUser,
      pageNumber,
      setPage,
      loadingMarkAllRead,
      loadingMarkOneRead
   };

   return (
      <>
         <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
         <PaymentModal
            isVisible={isModalVisible}
            message={modalMessage}
            onClose={() => setIsModalVisible(false)}
         />
      </>
   );
};

export { NotificationContext, NotificationProvider };
