import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { httpService } from "../../../base/services/httpService.service";
// import { LocalStorageService } from "../../../base/services/local-storage.service";
import * as Yup from "yup";
import { Form, Formik } from "formik";
// import Toast from "../../../base/components/Toast";
import { httpService } from "../../../base/services/httpService.service";
import Toast from "../../../base/components/Toast";
import { LocalStorageService } from "../../../base/services/local-storage.service";
import { Button, Spin } from "antd";

const initialValues = {
   code: ""
};

function ForgotPasswordConfirm() {
   const navigate = useNavigate();
   const [code, setCode] = useState();
   const location = useLocation();
   const userID = LocalStorageService.getItem("recoveryData");
   const searchParam = new URLSearchParams(location.search);
   const email = searchParam.get("email");
   const [error, setError] = useState(false);
   const [loading, setLoading] = useState(false);

   const ConfirmCodeSchema = Yup.object().shape({
      code: Yup.string()
         .min(6, "Minimum 6 symbols")
         .max(6, "Maximum 12 symbols")
         .required("Code is required")
   });

   const checkCode = async (values) => {
      // let formattedUserId = userID.replace(/"/g, "");
      try {
         setLoading(true);
         const res = await httpService.post("/Accounts/password/recover/check-code", {
            userId: userID,
            code: values?.code
         });
         if (res.status === 200) {
            navigate("/auth/reset-password");
         }
      } catch (error) {
         if (error.response && error.response.data && error.response.data.errors) {
            const errorMessages = error.response.data.errors.map((error) => error.message);
            if (errorMessages.length > 0) {
               errorMessages.forEach((errorMessage) => {
                  Toast.error(errorMessage);
               });
               setError(true);
            }
         } else {
            setError(false);
         }
      } finally {
         setLoading(false);
      }
   };

   const resendCode = async (values) => {
      try {
         const res = await httpService.post("/Accounts/password/recover", {
            email: values?.email
         });
         if (res?.status === 200) {
            Toast.success("Code has been resent to your email");
         }
      } catch (error) {
         if (error.response && error.response.data && error.response.data.errors) {
            const errorMessages = error.response.data.errors.map((error) => error.message);
            if (errorMessages.length > 0) {
               errorMessages.forEach((errorMessage) => {
                  Toast.error(errorMessage);
               });
               setError(true);
            }
         }
      }
   };

   const handleResendCode = () => {
      const values = { email: email };
      resendCode(values);
   };

   return (
      <div
         className="pt-md-15 pr-md-10 pb-md-15 pl-md-10 custom-div"
         style={{
            margin: "0 !important",
            padding: "30px 20px",
            height: "auto",
            maxHeight: "470px",
            backgroundColor: "#fff"
         }}
      >
         <Formik
            initialValues={initialValues}
            validationSchema={ConfirmCodeSchema}
            onSubmit={(values) => checkCode(values)}
         >
            {({ handleSubmit, handleChange, values, errors, isSubmitting }) => (
               <Form>
                  <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
                     <div className="d-flex flex-column align-items-center text-center">
                        <p
                           className="mb-4"
                           style={{ color: "#4158CF", fontWeight: 600, fontSize: "26px" }}
                        >
                           Verification code
                        </p>
                        <p style={{ color: "#555", fontSize: "16px", margin: "0px" }}>
                           Please enter the verification code that we sent to
                        </p>
                        <p style={{ color: "#3A3737", fontSize: "16px", fontWeight: 700 }}>
                           {email}
                        </p>
                        {error && <div className="error-message">{error}</div>}
                        <input
                           style={{
                              borderRadius: "8px",
                              border: errors.code || error ? "1px solid red" : "1px solid #979797",
                              // width: "325px",
                              maxWidth: "325px",
                              height: "70px",
                              padding: "10px",
                              color: "#003DAA",
                              fontSize: "40px",
                              fontWeight: 700,
                              letterSpacing: "6.8px",
                              textAlign: "center",
                              marginTop: "15px"
                           }}
                           className={`form-control`}
                           maxLength={6}
                           onChange={handleChange}
                           value={values?.code}
                           name="code"
                           autoComplete="off"
                        />
                        {error && <div className="error-message mt-4">The code is incorrect</div>}
                        {errors?.code && <div className="error-message mt-4">{errors?.code}</div>}
                     </div>
                     <div
                        className="form-group d-flex flex-wrap flex-center"
                        style={{ gap: "40px" }}
                     >
                        <Link to="/">
                           <button
                              type="button"
                              className="btn btn-outline-info"
                              style={{
                                 fontWeight: 500,
                                 fontSize: "14px",
                                 height: "44px"
                              }}
                           >
                              Cancel
                           </button>
                        </Link>
                        <button
                           type="submit"
                           disabled={isSubmitting}
                           className={`btn sign-btn`}
                           style={{ fontWeight: 500 }}
                        >
                           {loading ? <Spin /> : "Verify code"}
                        </button>
                     </div>
                     <p
                        className="m-0 text-center"
                        style={{
                           color: "#777",
                           fontSize: "14px"
                        }}
                     >
                        If you didn’t receive a verification code,{" "}
                        <Button
                           onClick={handleResendCode}
                           // to="/resend-verification"
                           style={{
                              color: "#5492FF",
                              textDecoration: "none",
                              border: "none",
                              padding: 0
                           }}
                        >
                           click here to resend it
                        </Button>{" "}
                        or send us an email at{" "}
                        <a
                           href="mailto:info@kaptechgroup.com"
                           style={{ color: "#555", fontWeight: 600 }}
                        >
                           info@kaptechgroup.com
                        </a>
                        .
                     </p>
                  </div>
               </Form>
            )}
         </Formik>
      </div>
   );
}

export default ForgotPasswordConfirm;
