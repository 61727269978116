import React, { useState, useEffect } from "react";
import { Divider, Radio, Input } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { SearchOutlined } from "@material-ui/icons";
import { EmptyBox } from "../../../../../../app/assets/icons";
import AuthService from "../../../../../../app/base/services/authentication.service";
import { UserRolesEnum } from "../../../../../../app/pages/auth/userRoles.enum";

function MenuActionPlanList({ data, kind }) {
   const [selectedAssessment, setSelectedAssessment] = useState(null);
   const [loading, setLoading] = useState(true);
   const [initialLoadComplete, setInitialLoadComplete] = useState(false);
   const navigate = useNavigate();
   const location = useLocation();
   const isConsultant = AuthService.isAuthorized([UserRolesEnum.Consultant])
   const [searchQuery, setSearchQuery] = useState("");

   useEffect(() => {
      if (data?.length > 0 && !initialLoadComplete) {
         const urlAssessmentId = location.pathname.split("/").pop();
         const initialAssessmentId = data[0]?.assessmentId;
         if (urlAssessmentId !== initialAssessmentId) {
            const url = `/workspace/results/action-plan/${initialAssessmentId}`;
            setSelectedAssessment(initialAssessmentId);
            localStorage.setItem("selectedAssessmentId", initialAssessmentId);
            navigate(url);
         } else {
            setSelectedAssessment(urlAssessmentId);
         }

         setInitialLoadComplete(true);
         setLoading(false);
      }
   }, [data, location.pathname, initialLoadComplete]);

   const handleAssessmentChange = (assessmentId) => {
      setSelectedAssessment(assessmentId);
      localStorage.setItem("selectedAssessmentId", assessmentId);

      if (kind === "action-plan") {
         navigate(`/workspace/results/action-plan/${assessmentId}`);
      }
   };

   const clickedCard = (assessmentId) => {
      handleAssessmentChange(assessmentId);
   };

   useEffect(() => {
      if (data?.length === 0) {
         navigate(`/workspace/results/action-plan/no-action-plan`);
      }
   }, [data]);

   return (
      <div style={{ padding: "1.25rem" }}>
         <div className="assessment-action-plan-title">Assessments</div>
         <div className="assessment-action-plan-description">
            <div>Here you will find an action plan based on your results in each assessment.</div>
            <div>Select one to see the plan in detail.</div>
            <div style={{ padding: "20px 0px" }}>
               <Input
                  autoComplete="off"
                  placeholder="Search for an assessment"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  prefix={<SearchOutlined style={{ color: "#a8a8a8" }} />}
               />
            </div>
         </div>
         <div className="assessment-cards-wrapper">
            {data.length > 0 ? (
               <Radio.Group
                  value={selectedAssessment}
                  onChange={(e) => handleAssessmentChange(e.target.value)}
                  className="custom-radio-group"
               >
                  {data
                     .filter((item) =>
                        item?.assessmentName.toLowerCase().includes(searchQuery.toLowerCase())
                     )
                     .map((item, index) => (
                        <div
                           className={`custom-assessment-card ${
                              selectedAssessment === item?.assessmentId
                                 ? "activeAssessmentCard"
                                 : ""
                           }`}
                           key={index}
                           onClick={() => clickedCard(item?.assessmentId)}
                        >
                           <div className="custom-card-upper">
                              <h3 className="assessment-name">{item?.assessmentName}</h3>
                              <Radio key={item.assessmentId} value={item.assessmentId}></Radio>
                           </div>
                           {

                           }
                           {
                              !isConsultant ?
                              <>
                                 <Divider style={{ marginTop: "30px" }} />
                                 <div className="custom-card-lower">
                                    <h3>Areas to Improve:</h3>
                                    <span>{item.areasToImprove}</span>
                                 </div>
                              </> : ''
                           }
                        </div>
                     ))}
               </Radio.Group>
            ) : (
               <div
                  style={{
                     display: "flex",
                     flexDirection: "column",
                     backgroundColor: "#fff",
                     height: "100vh",
                     width: "100%",
                     overflow: "hidden",
                     justifyContent: "center",
                     alignItems: "center",
                     padding: "0px 32px 0px",
                     border: "1px solid #B3BCEC",
                     borderRadius: "8px"
                  }}
               >
                  <img src={EmptyBox} alt="" width={80} height={50} />
                  <p
                     style={{
                        textAlign: "center",
                        color: "#4158CF",
                        fontSize: "16px",
                        fontWeight: 500
                     }}
                  >
                     No assessments here!
                  </p>
               </div>
            )}
         </div>
      </div>
   );
}

export default MenuActionPlanList;
