import { createSlice } from "@reduxjs/toolkit";
import { httpService } from "../../../base/services/httpService.service";
import { SessionService } from "../../../base/services/session.service";
import { convertTZ, getYear } from "../../../base/utils/timezoneConverter";
import { UserRolesEnum } from "../../auth/userRoles.enum";

const initialState = {
   isLoading: false,
   organizationList: [],
   dynamicRangeList: [],
   filterData: {
      organizationsIdList: [],
      filters: {
         assesmentId: "",
         sectorId: "",
         size: "",
         registerDate: ""
      }
   },
   compareResultData: {}
};

const resultSlice = createSlice({
   name: "result",
   initialState,
   reducers: {
      setLoading(state, { payload }) {
         return {
            ...state,
            isLoading: payload
         };
      },
      setDynamicRangeList(state, { payload }) {
         return {
            ...state,
            dynamicRangeList: payload
         };
      },
      setFilterData(state, { payload }) {
         return {
            ...state,
            filterData: {
               ...state.filterData,
               filters: payload
            }
         };
      },
      setFilterOrganizations(state, { payload }) {
         return {
            ...state,
            filterData: {
               ...state.filterData,
               organizationsIdList: payload
            }
         };
      },
      setResultData(state, { payload }) {
         return {
            ...state,
            compareResultData: payload
         };
      },
      setOrganizationList(state, { payload }) {
         return {
            ...state,
            organizationList: payload
         };
      }
   }
});

export function fetchChartData(payload, successCallback, errCB) {
   return async (dispatch, getState) => {
      await dispatch(setLoading(true));
      httpService
         .post(
            "/survey/resultManagementReport",
            {
               workSpacesId: SessionService.getItem("workspaceID"),
               organizationsIdList: getState().result.filterData.organizationsIdList || [],
               assesmentId: getState().result.filterData.filters.assesmentId
            },
            (res) => {
               dispatch(setResultData(res.data));
               if (successCallback !== null && successCallback !== undefined) successCallback();
            },
            (res) => {
               if (errCB !== null && errCB !== undefined) errCB(res);
            }
         )
         .finally(() => {
            dispatch(setLoading(false));
         });
   };
}

export function fetchOrganizationList(user, successCallback) {
   let organizationData;

   return async (dispatch, getState) => {
      await dispatch(setLoading(true));
      httpService.post(
         "/survey/resultManagementOrganization",
         {
            organizationName: "",
            workSpacesId: SessionService.getItem("workspaceID"),
            assesmentId: getState().result.filterData.filters.assesmentId,
            sectorId: getState().result.filterData.filters.sectorId,
            size: getState().result.filterData.filters.size,
            registerDate: getState().result.filterData.filters.registerDate
         },
         (res) => {
            organizationData = res.data.map((organization) => {
               return {
                  id: organization.organizationId,
                  title: organization.organizationName,
                  email: organization.email,
                  registrationDate: getYear(convertTZ(organization.foundationDate)),
                  location: organization.baseCountry,
                  selfAssessmentScore: organization.selfAssessmentScore,
                  paidAssessmentScore: organization.paidAssessmentScore,
                  selfAssessmentMaxScore: organization.selfAssessmentMaxScore,
                  paidAssessmentMaxScore: organization.paidAssessmentMaxScore,
                  isVerified: true, // this will be only shown if has score for paid assessment
                  role: [
                     UserRolesEnum.SuperAdmin,
                     UserRolesEnum.InternationalOrganization,
                     UserRolesEnum.NationalOrganization,
                     UserRolesEnum.Staff,
                     UserRolesEnum.Donor
                  ]
               };
            });
            const containsSuperadmin = user?.roles?.some((org) => org.name === "SuperAdmin");
            if (containsSuperadmin) {
               const firstOrganizationId = organizationData[0]?.id;
               dispatch(setFilterOrganizations([firstOrganizationId]));
            }
            dispatch(setOrganizationList(organizationData));
            if (successCallback !== null && successCallback !== undefined)
               successCallback(organizationData);
         }
      );
   };
}

export const {
   setLoading,
   setFilterData,
   setDynamicRangeList,
   setFilterOrganizations,
   setResultData,
   setOrganizationList
} = resultSlice.actions;

export default resultSlice.reducer;
