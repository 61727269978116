const monthsEnum = {
   1: "January",
   2: "February",
   3: "March",
   4: "April",
   5: "May",
   6: "June",
   7: "July",
   8: "August",
   9: "September",
   10: "October",
   11: "November",
   12: "December"
};

function getTimeZoneString() {
   return new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function convertTZ(date) {
   //applying local timezone to db date
   if (date != null) {
      return new Date(
         (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
            timeZone: getTimeZoneString()
         })
      );
   } else {
      return null;
   }
}

export function getFormattedDate(date) {
   if (date != null) {
      const day = date?.getDate();
      const month = date?.getMonth() + 1;
      const year = date?.getFullYear();

      return `${month}-${day}-${year}`;
   }
}

export function getStringDate(date) {
   if (date != null) {
      const day = date?.getDate();
      const monthNumber = date?.getMonth() + 1;
      const month = monthsEnum[monthNumber];
      const year = date?.getFullYear();

      return `${month}-${day}-${year}`;
   }
}

export function getFullDateTime(date) {
   if (date != null) {
      let timeZoneConvertedTime = convertTZ(date);
      let mdate = getDatepickerDateFromDate(timeZoneConvertedTime);
      const day = mdate?.getDate();
      const monthNumber = mdate?.getMonth() + 1;
      const month = monthsEnum[monthNumber];
      const year = mdate?.getFullYear();
      const hour = mdate?.getHours();
      let minute = mdate?.getMinutes();
      if (minute < 10) {
         minute = "0" + minute;
      }

      return " " + `${month}-${day}-${year}` + " " + ` ${hour}:${minute}` + " ";
   }
}

export function getTime(date) {
   const hour = date?.getHours();
   const minute = date?.getMinutes();

   return `${hour}:${minute}`;
}

export function setTime12(date) {
   //to protect day skip because of timezone
   date.setHours(12, 0, 0, 0);
}

export function getYear(date) {
   const year = date?.getFullYear();

   return year;
}

export function getDateFromDatePicker(date) {
   const timeZonedDate = convertTZ(date);

   if (timeZonedDate != null) {
      const day = timeZonedDate.getDate();
      const month = timeZonedDate.getMonth() + 1;
      const year = timeZonedDate.getFullYear();

      return `${year}-${month}-${day}`;
   } else {
      return null;
   }
}

export function fromDatepickerToISO(date) {
   const timeZonedDate = convertTZ(date);

   if (timeZonedDate != null) {
      return date.toISOString();
   } else {
      return date.toISOString();
   }
}

export function fromISOtoDateTime(date) {
   const timeZonedDate = convertTZ(date);

   if (timeZonedDate != null) {
      return new Date(date);
   } else {
      return new Date(date);
   }
}

export function getDatepickerDateFromDate(date) {
   return new Date(date);
}

export function formatDateString(inputDateString) {
   const date = new Date(inputDateString);
   const day = date.getDate();
   const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
   ];
   const monthIndex = date.getMonth();
   const year = date.getFullYear();

   return `${day}-${monthNames[monthIndex]}-${year}`;
}

