import { create } from "zustand";

export const useResultStore = create((set) => ({
   isActiveMenu: "",
   activeResult: {},

   setActiveItem: (item) => {
      set({
         isActiveMenu: item
      });
   },

   setActiveResult: (payload) => {
      set({
         activeResult: payload
      });
   }
}));
