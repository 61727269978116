import React from "react";
import "./styles.scss";
import BlueTick from "../assets/Blue-tick.svg";
import TurkishPeople2 from "../assets/turkish-people2.svg";

const BusinessPotential = () => {
   return (
      <div className="mainContainer-turkey">
         <div className="container">
            <div className="mainContainer-wrapper">
               <div className="main-image-wrapper-2">
                  <img
                     src={TurkishPeople2}
                     alt="People"
                     className="img-fluid"
                     style={{
                        height: "100%",
                        maxWidth: "100%",
                        objectFit: "cover",
                        borderRadius: "16px"
                     }}
                  />
               </div>
               <div style={{ gap: "32px" }}>
                  <div className="d-flex flex-column" style={{ gap: "32px" }}>
                     <span
                        style={{
                           fontSize: "32px",
                           fontWeight: 600,
                           color: "#003DAA",
                           margin: 0
                        }}
                     >
                        Numbers are more than just figures. Reveal your business's potential by
                        making it meaningful.
                     </span>
                     <div className="d-flex flex-column" style={{ gap: "24px" }}>
                        <span
                           style={{
                              fontSize: "16px",
                              color: "#3A3737",
                              letterSpacing: "0.32px"
                           }}
                        >
                           In today's data-driven world, accessing reliable and accurate information
                           is more critical than ever. Accurate analysis facilitates business
                           development and enables us to achieve better, more accurate results. A
                           linear and mathematical understanding of data can help find results with
                           the right analysis methods. TürkiyeKAPorg is here to help Turkish NGOs
                           collect, analyze, and deliver meaningful insights from data that will
                           drive your business forward.
                        </span>
                        <div className="d-flex flex-column" style={{ gap: "24px" }}>
                           <div className="d-flex align-items-center" style={{ gap: "12px" }}>
                              <img src={BlueTick} alt="blue-tick" />
                              <span
                                 style={{
                                    color: "#3A3737",
                                    fontWeight: 600,
                                    fontSize: "18px",
                                    letterSpacing: "0.36px"
                                 }}
                              >
                                 Data-driven decisions
                              </span>
                           </div>

                           <div className="d-flex align-items-center" style={{ gap: "12px" }}>
                              <img src={BlueTick} alt="blue-tick" />
                              <span
                                 style={{
                                    color: "#3A3737",
                                    fontWeight: 600,
                                    fontSize: "18px",
                                    letterSpacing: "0.36px"
                                 }}
                              >
                                 Personalized surveys
                              </span>
                           </div>
                           <div className="d-flex align-items-center" style={{ gap: "12px" }}>
                              <img src={BlueTick} alt="blue-tick" />
                              <span
                                 style={{
                                    color: "#3A3737",
                                    fontWeight: 600,
                                    fontSize: "18px",
                                    letterSpacing: "0.36px"
                                 }}
                              >
                                 User-friendly interface
                              </span>
                           </div>
                           <div className="d-flex align-items-center" style={{ gap: "12px" }}>
                              <img src={BlueTick} alt="blue-tick" />
                              <span
                                 style={{
                                    color: "#3A3737",
                                    fontWeight: 600,
                                    fontSize: "18px",
                                    letterSpacing: "0.36px"
                                 }}
                              >
                                 Powerful analytics tools
                              </span>
                           </div>
                           <div className="d-flex align-items-center" style={{ gap: "12px" }}>
                              <img src={BlueTick} alt="blue-tick" />
                              <span
                                 style={{
                                    color: "#3A3737",
                                    fontWeight: 600,
                                    fontSize: "18px",
                                    letterSpacing: "0.36px"
                                 }}
                              >
                                 Impressive visualizations
                              </span>
                           </div>
                        </div>
                        <span
                           style={{ fontSize: "16px", color: "#3A3737", letterSpacing: "0.32px" }}
                        >
                           KAPorg is committed to fostering impactful change and supporting Turkish
                           NGOs in their vital work.
                        </span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default BusinessPotential;
