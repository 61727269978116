import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { KapOrgIcon2, SuccessPurchaseIcon, BackIconWhiteColor } from "../../assets/icons";
import { Button } from "antd";
import "./style.scss";

const SuccessPaymentInfo = () => {
   const navigate = useNavigate();
   return (
      <div
         className="full-page-background"
         style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/bg/loginbackground.svg")})`
         }}
      >
         <Link to="/" className="logo-link">
            <img alt="Logo" className="logo" src={KapOrgIcon2} />
         </Link>
         <div className="centered-box">
            <img src={SuccessPurchaseIcon} alt="SuccessPurchaseIcon" />
            <h1>Purchase successful!</h1>
            <p>Your payment has been successfully processed.</p>
            <span>Thank you for your purchase!</span>
            <Button
               className="back-to-payment-info-button"
               htmlType="submit"
               style={{ width: "fit-content", color: "#fff" }}
               onClick={() => navigate("/admin/payment-information")}
            >
               <div className="button-info">
                  <img src={BackIconWhiteColor} alt="BackIconWhiteColor" />
                  <div className="back-to-payment-info-text">Go back to payment information</div>
               </div>
            </Button>
         </div>
      </div>
   );
};

export default SuccessPaymentInfo;
