export const SessionService = {
   setItem(key, value) {
      sessionStorage.setItem(key, JSON.stringify(value));
   },

   getItem(key) {
      return JSON.parse(sessionStorage.getItem(key));
   },

   clear() {
      sessionStorage.clear();
   },

   removeItem(key) {
      sessionStorage.removeItem(key);
   }
};
