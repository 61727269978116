export function toggleDarkMode() {
   let theme = window.localStorage.getItem("theme");

   if (theme === "dark") {
      window.localStorage.setItem("theme", "light");
      document.body.classList.remove("dark-mode");
   } else {
      window.localStorage.setItem("theme", "dark");
      document.body.classList.add("dark-mode");
   }
}

export function isDarkMode() {
   let theme = window.localStorage.getItem("theme");
   if (theme === "dark") {
      return true;
   } else {
      return false;
   }
}
