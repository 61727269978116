import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { useEffect } from "react";
import SurveyPopup from "../../../../../assets/icons/surveyPopup.png";
import AssessmentPhoto from "../../../../../assets/icons/assessmentPhoto.png";
import AssessmentPhotoPartTwo from "../../../../../assets/icons/secondAssessmentPhoto.png";
import CloseButton from "../../../../../assets/icons/circle1.png";
import { LocalStorageService } from "../../../../../base/services/local-storage.service";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { SessionService } from "../../../../../base/services/session.service";
import { httpService } from "../../../../../base/services/httpService.service";
import ConsultantPhoto from "../../../../../assets/icons/consultantPhoto.png";
import ConsultantsList from "../../consultantsList";
import AuthService from "../../../../../base/services/authentication.service";
import Toast from "../../../../../base/components/Toast";

function RequestModal({ show, setOpen }) {
   const workspaceId = SessionService.getItem("workspaceID");
   const navigate = useNavigate();
   const [expertList, setExpertList] = useState([]);
   const [secondModalOpen, setSecondModalOpen] = useState(false);
   const [consultantModal, setConsultantModal] = useState(false);
   const [consultantList, setConsultantList] = useState([]);
   const [assesmentList, setAssesmentList] = useState([]);
   const [selectedAssessment, setSelectedAssessment] = useState(null);
   const [assessmentCode, setAssessmentCode] = useState("");
   const [showError, setShowError] = useState(false);
   const [requestModalOpen, setRequestModalOpen] = useState(false);
   const user = AuthService.getUser();

   // useEffect(() => {
   //    if(Array.isArray(user?.roles)) {
   //       if (user?.roles[0]?.name === "Consultant") {
   //          setTimeout(() => {
   //          setOpen(false)
   //           navigate("/workspace/assessment/guided-assessment/list");
   //          }, 200);
   //       }
   //    }
   // }, []);

   // const handleButtonClick = () => {
   //    handleClose();
   //    navigate("/help-desk/list", { state: { openModal: true } });
   // };

   const handleGoToAssesment = () => {
      // handleClose();
      // Redirect to another page with props (replace '/destination' with the desired route path)
      navigate("/guided-assessment/list");
      setOpen(false);
   };

   // const handleRequestACButton = () => {
   //    setOpen(false);
   //    setSecondModalOpen(true);
   // };

   const handleRequestForGuideAssessment = () => {
      // Check if an assessment is selected
      if (selectedAssessment) {
         setOpen(false);
         setSecondModalOpen(false);
         httpService.post(
            "/guidedAssesment/request/assessmentcode",
            {
               assessmentId: selectedAssessment.value,
               organizationId: user.organizationId,
               workspaceId: workspaceId
            },
            (data) => {
               Toast.success(data.data.info);
            }
         );
      } else {
         // Show the error message if no assessment is selected
         setShowError(true);
      }
   };

   const handleAssessmentSelect = (selectedAssessmentt) => {
      setSelectedAssessment(selectedAssessmentt);
      setShowError(false);
   };
   // Function to handle the code input change
   const handleCodeChange = (event) => {
      setAssessmentCode(event.target.value);
   };

   const handleSubmit = () => {
      if (assessmentCode.trim() !== "") {
         // Check if the assessment code is not empty
         let assessmentId = selectedAssessment ? selectedAssessment.value : null;
         httpService.post(
            "/guidedassesment/checkassessmentcode",
            {
               assessmentcode: assessmentCode,
               assessmentId: assessmentId,
               organizationId: user.organizationId,
               workspaceId: workspaceId
            },
            (data) => {
               if (data.success) {
                  Toast.success("Your code was valid and now you can select the consultant");
                  setOpen(false);
                  setRequestModalOpen(false);
                  navigate("/guided-assessment-list/consultant-list", {
                     state: {
                        isValid: true,
                        assessmentId: assessmentId,
                        organizationId: user.organizationId
                     }
                  });
               }
            },
            (error) => {
               if (!error.success) {
                  handleGoToAssesment();
               }
            }
         );
      } else if (!selectedAssessment) {
         // Show the error message if both the assessment code and assessment are empty
         setShowError(true);
      }
   };

   const handleClose = () => {
      setOpen(false);
      setSecondModalOpen(false);
      document.querySelector('[data-target="#self-assessment"]').click();
   };

   //  useEffect(() => {
   //     httpService.get(`/guidedassesment/getall/${workspaceId}`, (data) => {
   //        const assesmentData = data.data.map((assesment) => {
   //           return {
   //              value: assesment.id,
   //              label: assesment.name
   //           };
   //        });
   //        setAssesmentList(assesmentData);
   //     });
   //  }, []);

   //  useEffect(() => {
   //     httpService.get(`/team/getworkspaceconsultants/${workspaceId}`, (data) => {
   //        setConsultantList(data.data);
   //     });
   //  }, [consultantModal]);

   return (
      <>
         {user?.roles[0]?.name !== "Consultant" && (
            <>
               <Modal centered show={show} className="modal-dialog-d modal-dialog-centered">
                  <div
                     onClick={handleClose}
                     className="closeModal"
                     style={{ background: "#036051" }}
                  >
                     <span
                        className="d-flex justify-content-end"
                        style={{ color: "white", padding: "10px", cursor: "pointer" }}
                     >
                        <img alt="png" src={CloseButton} />
                     </span>
                  </div>
                  <div className="main-modal custom-modal-width">
                     <h4>
                        Should you wish to improve your score and further grow your organization,
                        the KAPorg team is here to help!
                     </h4>
                     <div className="d-flex justify-content-center mt-10 mb-10 col-md-6">
                        <img alt="png" src={AssessmentPhotoPartTwo} />
                     </div>
                     <p className="mb-15">
                        Request a guided assessment now so we can assign our experts from your field
                        of work to directly support you along the assessment process and help you
                        explore ways to build better partnerships and secure funding for future
                        programs. <br />
                        <br />
                        Request it now and we will get in touch with you as soon as possible.
                     </p>
                     <button
                        className="btn-assessment mb-5"
                        onClick={() => {
                           setOpen(false);
                           setSecondModalOpen(true);
                        }}
                     >
                        Request an assessment code
                     </button>
                     <button
                        className="btn-assessment"
                        onClick={() => {
                           setOpen(false);
                           setRequestModalOpen(true);
                        }}
                     >
                        I have an assessment code
                     </button>
                  </div>
               </Modal>
               <Modal
                  centered
                  show={requestModalOpen}
                  className="modal-dialog-second modal-dialog-centered"
               >
                  <div
                     onClick={handleClose}
                     className="closeModal"
                     style={{ background: "#036051" }}
                  >
                     <span
                        className="d-flex justify-content-end"
                        style={{ color: "white", padding: "10px", cursor: "pointer" }}
                     >
                        <img alt="png" src={CloseButton} />
                     </span>
                  </div>
                  <div className="modalContainer d-flex justify-content-between align-items-center">
                     <div className="d-flex justify-content-center mt-10 mb-10 col-md-6">
                        <img alt="png" src={AssessmentPhoto} />
                     </div>

                     <div
                        className="col-md-6"
                        style={{
                           display: "flex",
                           flexDirection: "column",
                           alignItems: "flex-start"
                        }}
                     >
                        <h4>Assessment code</h4>
                        <p>To access your assessment, please type your assessment code below</p>
                        <div>
                           <p>Enter your code here</p>
                           <input
                              className="col-md col-sm-4 col-12 p-0"
                              style={{ width: "215px", height: "30px" }}
                              type="text"
                              value={assessmentCode}
                              onChange={handleCodeChange}
                           />
                        </div>
                        {showError && <p style={{ color: "red" }}>Please enter code</p>}

                        <div className="mt-5">
                           <p>Assessment list</p>
                           <Select
                              name="essessment"
                              placeholder="-Select an assessment-"
                              style={{ minWidth: "247px", width: "247px", height: "145px" }}
                              className="select col-md col-sm-4 col-12 p-0"
                              options={assesmentList}
                              onChange={(selectedAssessment) => {
                                 handleAssessmentSelect(selectedAssessment);
                              }}
                           />
                        </div>
                        {showError && (
                           <p style={{ color: "red" }}>You should select an assessment</p>
                        )}
                        <div className="btnSubmit mt-5">
                           <button className="btnOutline request-btn" onClick={handleSubmit}>
                              Submit request
                              <br />
                           </button>
                        </div>
                     </div>
                  </div>
               </Modal>

               {/* {Request AC Modal} */}
               <Modal
                  centered
                  show={secondModalOpen}
                  className="modal-dialog-second modal-dialog-centered"
                  // Add necessary props for the second modal
               >
                  <div
                     onClick={handleClose}
                     className="closeModal"
                     style={{ background: "#036051" }}
                  >
                     <span
                        className="d-flex justify-content-end"
                        style={{ color: "white", padding: "10px", cursor: "pointer" }}
                     >
                        <img alt="png" src={CloseButton} />
                     </span>
                  </div>
                  <div className="modalContainer d-flex justify-content-between align-items-center">
                     <div className="d-flex justify-content-center mt-10 mb-10 col-md-6">
                        <img alt="png" src={AssessmentPhoto} />
                     </div>

                     <div
                        className="col-md-6"
                        style={{
                           display: "flex",
                           flexDirection: "column",
                           alignItems: "flex-start"
                        }}
                     >
                        <h4
                           style={{
                              fontFamily: "Poppins",
                              fontSize: "20px",
                              fontWeight: "600",
                              color: "#036051"
                           }}
                        >
                           Request Assessment Code
                        </h4>
                        <p
                           style={{
                              fontFamily: "IBM Plex Sans",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#036051",
                              lineHeight: "18.2px",
                              color: "#575757"
                           }}
                        >
                           Please request your assessment and we will get in touch with you. As soon
                           as the request is accepted you will receive an email with the code.
                        </p>
                        <div className="mt-5">
                           <p
                              style={{
                                 fontFamily: "Poppins",
                                 fontSize: "14px",
                                 fontWeight: "400",
                                 color: "#036051",
                                 lineHeight: "24px",
                                 color: "#707073"
                              }}
                           >
                              Select an assessment from the list below
                           </p>
                           <Select
                              name="essessment"
                              placeholder="-Select an assessment-"
                              className="select col-md col-sm-4 col-12 p-0"
                              options={assesmentList}
                              onChange={(selectedAssessment) => {
                                 handleAssessmentSelect(selectedAssessment);
                              }}
                           />
                        </div>
                        {/* Error message */}
                        {showError && (
                           <p style={{ color: "red" }}>You should select an assessment</p>
                        )}
                        <div className="btnSubmit mt-10">
                           <button
                              className="btnOutline request-btn"
                              onClick={handleRequestForGuideAssessment}
                           >
                              Submit request
                              <br />
                           </button>
                        </div>
                     </div>
                  </div>
               </Modal>
            </>
         )}
      </>
   );
}

export default RequestModal;
