import React, { useMemo } from "react";
import { menu } from "../../../../../app/Menu";
import { mainMenu } from "../../../../../app/MainMenu";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { AsideMenuList } from "./AsideMenuList";
import "./custom.scss";

export function AsideMenu({ isActive, activeMenu }) {
   const uiService = useHtmlClassService();
   const layoutProps = useMemo(() => {
      return {
         layoutConfig: uiService.config,
         asideMenuAttr: uiService.getAttributes("aside_menu"),
         ulClasses: uiService.getClasses("aside_menu_nav", true),
         asideClassesFromConfig: uiService.getClasses("aside_menu", true)
      };
   }, [uiService]);

   return (
      <div className={`tab-pane fade show active`}>
         <div className="aside-menu-wrapper flex-column-fluid py-5">
            {/* begin::Menu Container */}
            <div
               id="kt_aside_menu"
               data-menu-vertical="1"
               className={`aside-menu min-h-lg-800px p-5 ${layoutProps.asideClassesFromConfig}`}
               {...layoutProps.asideMenuAttr}
            >
               {mainMenu?.layout?.map((item, i) => {
                  return (
                     <div className="wrapper" key={i}>
                        {item?.children
                           ?.filter((x) => x.id === activeMenu)
                           .map((menuItem) => {
                              return (
                                 <AsideMenuList
                                    key={menuItem.id}
                                    api={menuItem.api}
                                    kind={menuItem.kind}
                                    data={[]}
                                    layoutProps={layoutProps}
                                    activeMenu={activeMenu}
                                 />
                              );
                           })}
                     </div>
                  );
               })}
            </div>
            {/* end::Menu Container */}
         </div>
      </div>
   );
}
