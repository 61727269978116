import React from "react";
import { useLocation } from "react-router-dom";
import { DeactivatedIcon } from "../../../assets/icons";
import { AccountDeleted } from "../../../assets/icons";
import "../style.scss";

const AccountDeletedORDeactivated = () => {
   const location = useLocation();
   const params = new URLSearchParams(location.search);
   const action = params.get("action");

   return (
      <div className="pt-md-15 pr-md-10 pb-md-15 pl-md-10 custom-div accountDeletedDeactivated">
         <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <div className="d-flex justify-content-center">
               <img
                  src={action === "deactivate" ? DeactivatedIcon : AccountDeleted}
                  alt="account action"
               />
            </div>
            <div className="d-flex flex-column align-items-center">
               <label className="labelStatus">
                  Your account has been successfully{" "}
                  {action === "deactivate" ? "deactivated" : "deleted"} at your request.
               </label>
               <div className="d-flex flex-column align-items-center lastParagraphSection">
                  <p className="paragraph-text">
                     We trust you've had a positive experience with us, and we want you to know that
                     you'll always be welcomed back whenever you choose to return.
                  </p>
                  <p className="last-paragraph-text">Best regards, KAPorg team.</p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default AccountDeletedORDeactivated;
