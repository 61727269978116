import React from "react";

function NoDataMessage() {
   return (
      <div>
         <span className="d-flex justify-content-center mt-5 no-data-message">
            There is no data available to be displayed.
         </span>
      </div>
   );
}

export default NoDataMessage;
