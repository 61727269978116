import React from "react";
import { Link } from "react-router-dom";
const TermsServices = () => {
   return (
      <div className="d-flex flex-column" style={{ gap: "16px" }}>
         <p style={{ fontSize: "22px", color: "#003DAA", fontWeight: 600 }}>Terms of service</p>
         <div className="d-flex flex-column" style={{ gap: "16px" }}>
            <div className="d-flex flex-column">
               <p className="mb-6">These Terms of Service were last updated on March 4, 2024.</p>
               <p>
                  These Terms of Service govern your use of our websites located at{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://www.i-aps.com"}
                     style={{ textDecoration: "none", color: "#5492FF" }}
                  >
                     https://www.i-aps.com
                  </a>
                  ,{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://kaptechgroup.com"}
                     style={{ textDecoration: "none", color: "#5492FF" }}
                  >
                     https://kaptechgroup.com
                  </a>
                  ,{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://www.kapsurvey.com"}
                     style={{ textDecoration: "none", color: "#5492FF" }}
                  >
                     https://www.kapsurvey.com
                  </a>
                  ,{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://www.kaporg.com"}
                     style={{ textDecoration: "none", color: "#5492FF" }}
                  >
                     https://www.kaporg.com
                  </a>
                  ,{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://kapinsights.com"}
                     style={{ textDecoration: "none", color: "#5492FF" }}
                  >
                     https://kapinsights.com
                  </a>{" "}
                  and{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://kapcourse.com"}
                     style={{ textDecoration: "none", color: "#5492FF" }}
                  >
                     https://kapcourse.com
                  </a>
                  , as well as any related services provided by International Advisory, Products and
                  Systems Ltd. By accessing our websites you agree to abide by these Terms of
                  Service and to comply with all applicable laws and regulations. If you do not
                  agree with these Terms of Service, you are prohibited from using or accessing
                  those websites or using any other services provided by International Advisory,
                  Products and Systems Ltd. We, International Advisory, Products and Systems Ltd,
                  reserve the right to review and amend any of these Terms of Service at our sole
                  discretion. Upon doing so, we will update this page. Any changes to these Terms of
                  Service will take effect immediately from the date of publication.
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>Overview of Services</h4>
               <p>
                  International Advisory, Products and Systems Ltd. (i-APS), together with its
                  affiliated entities, including but not limited to KAPsurvey, KAPorg, KAPinsights,
                  and KAPcourse (hereinafter collectively referred to as “KAPtech Group”), provides
                  technical advisory services, innovative technological solutions, and comprehensive
                  data collection and analysis tools. The offerings of KAPtech Group are aimed at
                  supporting the execution and management of expert and development projects.
                  These services utilize cutting-edge technology and capitalize on extensive local
                  expertise to assist partner organizations and communities in effectively
                  responding to and overcoming challenges in expert aid and development
                  sectors. By using the services provided by KAPtech Group, including accessing our
                  websites, you agree to comply with and be bound by the terms and conditions set
                  forth in this agreement. The services offered are subject to the terms outlined
                  herein, along with any additional guidelines or rules applicable to specific
                  services or features of the services.
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>Limitations of Use</h4>
               <p>
                  By using our websites, you warrant on behalf of yourself, your users, and other
                  parties you represent that you will not:
               </p>
               <ul style={{ paddingLeft: "20px" }}>
                  <li>
                     modify, copy, prepare derivative works of, decompile, or reverse engineer any
                     materials and software contained on those websites;
                  </li>
                  <li>
                     remove any copyright or other proprietary notations from any materials and
                     software on those websites;
                  </li>
                  <li>
                     transfer the materials to another person or "mirror" the materials on any other
                     server;
                  </li>
                  <li>
                     knowingly or negligently use those websites or any of their associated services
                     in a way that abuses or disrupts our networks or any other service
                     International Advisory, Products and Systems Ltd provides;
                  </li>

                  <li>
                     use those websites or their associated services to transmit or publish any
                     harassing, indecent, obscene, fraudulent, or unlawful material;
                  </li>
                  <li>
                     use those websites or their associated services in violation of any applicable
                     laws or regulations;
                  </li>
                  <li>
                     use those websites in conjunction with sending unauthorized advertising or
                     spam;{" "}
                  </li>
                  <li>harvest, collect, or gather user data without the user’s consent; or </li>
                  <li>
                     use those websites or their associated services in such a way that may infringe
                     the privacy, intellectual property rights, or other rights of third parties
                  </li>
               </ul>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>Intellectual Property</h4>
               <p>
                  The intellectual property in the materials contained in those websites are owned
                  by or licensed to International Advisory, Products and Systems Ltd and are
                  protected by applicable copyright and trademark law. We grant our users permission
                  to download one copy of the materials for personal, non-commercial transitory use.
                  This constitutes the grant of a license, not a transfer of title. This license
                  shall automatically terminate if you violate any of these restrictions or the
                  Terms of Service, and may be terminated by International Advisory, Products and
                  Systems Ltd at any time.
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>User-Generated Content</h4>
               <p>
                  You retain your intellectual property ownership rights over the content you submit
                  to us for publication on our websites. We will never claim ownership of your
                  content, but we do require a license from you in order to use it. When you use our
                  websites or their associated services to post, upload, share, or otherwise
                  transmit content covered by intellectual property rights, you grant to us a
                  non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to
                  use, distribute, modify, run, copy, publicly display, translate, or otherwise
                  create derivative works of your content in a manner that is consistent with your
                  privacy preferences and our Privacy Policy. The license you grant us can be
                  terminated at any time by deleting your content or account. However, to the extent
                  that we (or our partners) have used your content in connection with commercial or
                  sponsored content, the license will continue until the relevant commercial or post
                  has been discontinued by us. You give us permission to use your username and other
                  identifying information associated with your account in a manner that is
                  consistent with your privacy preferences and our Privacy Policy.
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>Registration and Payments</h4>
               <p>
                  In order to use any of our services, you need to create an account. Any
                  information provided needs to be accurate and updated if necessary. Customers are
                  liable for maintaining their accounts and are fully responsible for all activities
                  occurring under their accounts. Should anybody gain unauthorized access to your
                  account, you need to notify us immediately. International Advisory, Products and
                  Systems Ltd is not liable for any loss or damage arising from failure to maintain
                  the confidentiality of your account. When creating your account, you are required
                  to provide your financial information relevant to the chosen payment method. In
                  some cases, you may be requested to provide your credit card details. Please note
                  that any payments made on our platform will be processed by PayPal, Stripe,
                  Square, Google Payments, or Apple Pay. As such, we will not be responsible for
                  processing or retaining any of your financial information. PayPal, Stripe, Square,
                  Google Payments, or Apple Pay will handle all of your financial information per
                  their respective Terms of Service, and you agree to comply with such Terms of
                  Service.
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>
                  Disclaimer and Limitations of Liability
               </h4>
               <p>
                  The websites and materials provided by International Advisory, Products and
                  Systems Ltd., including but not limited to{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://www.i-aps.com"}
                     style={{ textDecoration: "none", color: "#5492FF" }}
                  >
                     https://www.i-aps.com
                  </a>
                  ,{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://kaptechgroup.com"}
                     style={{ textDecoration: "none", color: "#5492FF" }}
                  >
                     https://kaptechgroup.com
                  </a>
                  ,{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://www.kapsurvey.com"}
                     style={{ textDecoration: "none", color: "#5492FF" }}
                  >
                     https://www.kapsurvey.com
                  </a>
                  ,{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://www.kaporg.com"}
                     style={{ textDecoration: "none", color: "#5492FF" }}
                  >
                     https://www.kaporg.com
                  </a>
                  ,{" "}
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://kapinsights.com"}
                     style={{ textDecoration: "none", color: "#5492FF" }}
                  >
                     https://kapinsights.com
                  </a>{" "}
                  and
                  <a
                     rel="noopener noreferrer"
                     target="_blank"
                     href={"https://kapcourse.com"}
                     style={{ textDecoration: "none", color: "#5492FF" }}
                  >
                     https://kapcourse.com
                  </a>
                  , are made available to users on an 'as is' basis. International Advisory,
                  Products and Systems Ltd disclaims all warranties, express or implied, to the
                  extent permitted by law. This includes but is not limited to warranties of
                  merchantability, fitness for a particular purpose, or non-infringement of
                  intellectual property rights. Furthermore, no advice or information, whether oral
                  or written, obtained by you from International Advisory, Products and Systems Ltd
                  or through the services shall create any warranty not expressly stated in these
                  terms. International Advisory, Products and Systems Ltd and its suppliers shall
                  not be liable for any direct, indirect, incidental, special, consequential, or
                  exemplary damages, including but not limited to damages for loss of profits,
                  goodwill, use, data, or other intangible losses (even if International Advisory,
                  Products and Systems Ltd has been advised of the possibility of such damages),
                  resulting from the use or the inability to use the services or any other matter
                  relating to the services. This limitation of liability applies whether the alleged
                  liability is based on contract, tort, negligence, strict liability, or any other
                  basis, even if International Advisory, Products and Systems Ltd has been advised
                  of the possibility of such damage. Given the nature of the services provided by
                  International Advisory, Products and Systems Ltd, especially in the context of
                  expert and development sectors, users acknowledge the inherent risks
                  involved in data collection and the use of technology-driven solutions. Users are
                  solely responsible for ensuring their compliance with all applicable laws and
                  regulations, including but not limited to data protection and privacy laws. The
                  responsibility for obtaining the necessary consents and permissions for data
                  collection, particularly when involving minors or sensitive data, rests with the
                  user. The liability of International Advisory, Products and Systems Ltd and its
                  affiliates, directors, officers, employees, agents, suppliers, and licensors is
                  limited to the maximum extent permitted by law. If you are dissatisfied with any
                  portion of the services, or with any of these terms, your sole and exclusive
                  remedy is to discontinue using the services. Some jurisdictions do not allow the
                  exclusion of certain warranties or the limitation or exclusion of liability for
                  incidental or consequential damages, so some of the above limitations may not
                  apply to you.
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>Accuracy of Materials</h4>
               <p>
                  The materials appearing on our websites are not comprehensive and are for general
                  information purposes only. International Advisory, Products and Systems Ltd does
                  not warrant or make any representations concerning the accuracy, likely results,
                  or reliability of the use of the materials on those websites, or otherwise
                  relating to such materials or on any resources linked to those websites. You
                  should not rely solely on the said materials to make decisions, we advise you to
                  consult other, more complete sources of information. You are solely responsible
                  for the strict reliance on the materials posted on our websites. We have the right
                  to change the information on our websites without prior notice and users are
                  responsible to monitor said changes.
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>Additional Tools</h4>
               <p>
                  Please note that our websites may include third-party tools which are outside of
                  our control. The access to said tools is provided on an 'as is' basis with no
                  warranties, expressed or implied, and without any endorsement. We are not liable
                  for the use of the third-party tools provided on our websites. You are solely
                  responsible for any risks associated with using said tools, we recommend reading
                  the relevant third-party provider’s Terms of Service. International Advisory,
                  Products and Systems Ltd has the right to offer new third-party tools in the
                  future
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>Links</h4>
               <p>
                  International Advisory, Products and Systems Ltd has not reviewed all of the sites
                  linked to its websites and is not responsible for the contents of any such linked
                  site. The inclusion of any link does not imply endorsement, approval or control by
                  International Advisory, Products and Systems Ltd of the site. Use of any such
                  linked site is at your own risk, and we strongly advise you make your own
                  investigations with respect to the suitability of those sites.
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>Inaccuracies and Mistakes</h4>
               <p>
                  Please note that any content available on our websites may contain typographical
                  errors, inaccuracies, or mistakes related to the service’s description, pricing,
                  promotion, and availability. We have the right to cancel or update any existing
                  services should any information provided by us be proven inaccurate. Said
                  information can be amended at any time without prior notice. Please note that we
                  are not obligated to clarify any of the information related to our websites and
                  services, including pricing information, except as required by law.
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>Indemnification</h4>
               <p>
                  You agree to defend, indemnify, and hold us harmless, including our subsidiaries,
                  affiliates, and all of our respective officers, agents, partners, and employees,
                  from and against any loss, damage, liability, claim, or demand, including
                  reasonable attorneys’ fees and expenses, made by any third party due to or arising
                  out of: (1) your Contributions; (2) use of the websites; (3) breach of these Terms
                  of Service; (4) any breach of your representations and warranties set forth in
                  these Terms of Service; (5) your violation of the rights of a third party,
                  including but not limited to intellectual property rights; or (6) any overt
                  harmful act toward any other user of the websites with whom you connected via the
                  websites.
               </p>
               <p>
                  Notwithstanding the foregoing, we reserve the right, at your expense, to assume
                  the exclusive defense and control of any matter for which you are required to
                  indemnify us, and you agree to cooperate, at your expense, with our defense of
                  such claims. We will use reasonable efforts to notify you of any such claim,
                  action, or proceeding which is subject to this indemnification upon becoming aware
                  of it.
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>Right to Terminate</h4>
               <p>
                  We may suspend or terminate your right to use our websites and terminate these
                  Terms of Service immediately upon written notice to you for any breach of these
                  Terms of Service.
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>Severance</h4>
               <p>
                  Any term of these Terms of Service which is wholly or partially void or
                  unenforceable is severed to the extent that it is void or unenforceable. The
                  validity of the remainder of these Terms of Service is not affected.
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>Entire Agreement</h4>
               <p>
                  These Terms of Service and any policies or operating rules posted by us on the
                  websites or with respect to the websites constitute the entire agreement and
                  understanding between you and us. Our failure to exercise or enforce any right or
                  provision of these Terms of Service shall not operate as a waiver of such right or
                  provision. These Terms of Service operate to the fullest extent permissible by
                  law. We may assign any or all of our rights and obligations to others at any time.
                  We shall not be responsible or liable for any loss, damage, delay, or failure to
                  act caused by any cause beyond our reasonable control. If any provision or part of
                  a provision of these Terms of Service is determined to be unlawful, void, or
                  unenforceable, that provision or part of the provision is deemed severable from
                  these Terms of Service and does not affect the validity and enforceability of any
                  remaining provisions. There is no joint venture, partnership, employment, or
                  agency relationship created between you and us as a result of these Terms of
                  Service or use of the websites. You agree that these Terms of Service will not be
                  construed against us by virtue of having drafted them. You hereby waive any and
                  all defenses you may have based on the electronic form of these Terms of Service
                  and the lack of signing by the parties hereto to execute these Terms of Service.
               </p>
            </div>
            <div>
               <h4 style={{ color: "#003DAA", marginBottom: "10px" }}>Governing Law</h4>
               <p>
                  These Terms of Service are governed by and construed in accordance with the laws
                  of Virginia. You irrevocably submit to the exclusive jurisdiction of the courts in
                  that State or location.
               </p>
            </div>
         </div>
      </div>
   );
};

export default TermsServices;
