import toast from "../components/Toast";

function isNetworkError(err) {
   return err.isAxiosError && !err.response;
}

export function errorHandler({ status, message }) {
   toast.error(message);
   return { status, message };
}

export function getErrorMessage(error) {
   const status = error.response?.status || "Status undefined"; 
   let message;

   if (isNetworkError(error)) {
      message = "Please check your network connection.";
      return {status, message}
   }

   error.response?.status >= 500
      ? (message = "There was an internal processing error. Please try again after some time.")
      : (message = error.response?.data.clientMessage);
   //    const errorList = ...will be added ac.to new err data model

   return { status, message };
}
