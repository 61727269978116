import React from "react";
import "./styles.scss";
import TurkishPeople from "../assets/turkish-people.svg";

const ExpectedOutcomes = () => {
   return (
      <div className="turkey-principles-component">
         <div className="container">
            <div className="row g-0">
               <div className="col-md-6 d-flex flex-column align-items-center">
                  <div className="d-flex flex-column">
                     {/* <h2 className="principles-title">Our platform provides</h2> */}
                     <span style={{ color: "#003DAA", fontSize: "22px", fontWeight: 600 }}>
                        Our platform provides
                     </span>
                     <span style={{ color: "#003DAA", fontWeight: 600, fontSize: "32px" }}>
                        unique tools and resources to help Türkiye NGOs:
                     </span>
                  </div>
                  <div className="text-content" style={{ width: "100%", padding: "0 20px" }}>
                     <ul
                        className="d-flex flex-column p-md-0"
                        style={{ marginTop: "32px", gap: "24px" }}
                     >
                        <li style={{ letterSpacing: "0.32px" }}>
                           Identify and secure funding opportunities
                        </li>
                        <li style={{ letterSpacing: "0.32px" }}>
                           Upgrade organizational management and operations
                        </li>
                        <li style={{ letterSpacing: "0.32px" }}>
                           Improve project design, implementation, and evaluation
                        </li>
                        <li style={{ letterSpacing: "0.32px" }}>
                           Build strong partnerships with donors and other stakeholders
                        </li>
                     </ul>
                  </div>
               </div>
               <div className="col-md-6 d-flex justify-content-center align-items-center">
                  <img src={TurkishPeople} alt="Child Eating" className="img-fluid" />
               </div>
            </div>
         </div>
      </div>
   );
};

export default ExpectedOutcomes;
