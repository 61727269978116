import { Button, ButtonGroup, Grid } from "@material-ui/core";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import { useDispatch } from "react-redux";
import {
   fetchChartData
} from "../../../../../../app/pages/workspace/results/resultSlice";

function OrganizationMenuButtons({ sortOrganizationOrder, searchOrganization }) {
   const dispatch = useDispatch();

   const handleSort = () => {
      sortOrganizationOrder();
   };

   const handleSearch = (keyword) => {
      searchOrganization(keyword);
   };

   const getResults = () => {
      dispatch(fetchChartData());
   };

   return (
      <div className="organization-search-bar">
         <DebounceInput
            element={"input"}
            name="questionText"
            placeholder="Search organization"
            value={""}
            minLength={1}
            debounceTimeout={500}
            onChange={(event) => handleSearch(event.target.value)}
         />

         <Grid className="bottom-buttons" item xs={12}>
            <ButtonGroup fullWidth aria-label="Full width outlined button group">
               <OverlayTrigger
                  key={"sort-order-org"}
                  placement={"bottom"}
                  overlay={<Tooltip id={`tooltip-organization-sort-tp`}>Sort Order</Tooltip>}
               >
                  <Button onClick={() => handleSort()} className="mr-1">
                     <i className="flaticon2-sort"></i>
                  </Button>
               </OverlayTrigger>
               <OverlayTrigger
                  key={"calculate-result-org"}
                  placement={"bottom"}
                  overlay={
                     <Tooltip id={`tooltip-organization-get-results-tp`}>Get results</Tooltip>
                  }
               >
                  <Button onClick={() => getResults()} className="ml-1">
                     Filter
                  </Button>
               </OverlayTrigger>
            </ButtonGroup>
         </Grid>
      </div>
   );
}

export default OrganizationMenuButtons;
