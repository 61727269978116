import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

export function Error({
   title = "Status 500",
   subTitle = "Sorry, something went wrong.",
   extra = [],
   icon,
   status = 500,
   hasBackButton = false
}) {
   const navigate = useNavigate();

   return (
      <Result
         status={status === "401" ? "403" : status}
         title={title}
         subTitle={subTitle}
         icon={icon}
         extra={
            hasBackButton
               ? [...extra, <Button onClick={() => navigate("/")}>Back Home</Button>]
               : extra
         }
      />
   );
}
