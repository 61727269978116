import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Link, Navigate } from "react-router-dom";
import { injectIntl } from "react-intl";
// import "./custom.css";
// import https from "../../base/utils/http";
import { useNavigate } from "react-router-dom";
import { httpService } from "../../../base/services/httpService.service";
import Toast from "../../../base/components/Toast";
import { Spin } from "antd";
const initialValues = {
   email: ""
};

function ForgotPassword(props) {
   const navigate = useNavigate();
   const { intl } = props;
   const [isRequested] = useState(false);
   const [error, setError] = useState(false);

   const [loading, setLoading] = useState(false);

   // const [error, setError] = useState(false);
   const ForgotPasswordSchema = Yup.object().shape({
      email: Yup.string()
         .email("Wrong email format")
         .min(3, "Minimum 3 symbols")
         .max(50, "Maximum 50 symbols")
         .required(
            intl.formatMessage({
               id: "AUTH.VALIDATION.REQUIRED_FIELD"
            })
         )
   });
   const handleSubmit = async (values) => {
      try {
         setLoading(true);
         const res = await httpService.post("/Accounts/password/recover", {
            email: values?.email
         });
         if (res.status === 200) {
            localStorage.setItem("recoveryData", JSON.stringify(res?.data?.data));

            navigate(`/auth/password-confirm?email=${encodeURIComponent(values?.email)}`);
         }
         // .then(() =>
         // );
      } catch (error) {
         if (error.response && error.response.data && error.response.data.errors) {
            const errorMessages = error.response.data.errors.map((error) => error.message);
            if (errorMessages.length > 0) {
               errorMessages.forEach((errorMessage) => {
                  Toast.error(errorMessage);
               });
               setError(true);
            }
         }
      } finally {
         setLoading(false);
      }
   };

   return (
      <>
         {isRequested && <Navigate to="/auth" />}
         {!isRequested && (
            <div
               className="pt-md-20 pr-md-10 pb-md-20 pl-md-10 custom-div"
               style={{
                  margin: "0 !important",
                  display: "block",
                  padding: "30px 20px",
                  height: "auto",
                  maxHeight: "440px",
                  backgroundColor: "#fff"
               }}
            >
               {/* begin::Head */}
               <div>
                  <p
                     className="mb-4"
                     style={{ color: "#4158CF", fontWeight: 600, fontSize: "26px" }}
                  >
                     Forgot your password?
                  </p>
                  <p style={{ color: "#555", fontSize: "16px", margin: 0 }}>
                     Enter your email below to receive a verification code and recover your password
                  </p>
               </div>
               <Formik
                  initialValues={initialValues}
                  validationSchema={ForgotPasswordSchema}
                  onSubmit={(values) => {
                     handleSubmit(values);
                  }}
               >
                  {({ handleSubmit, handleChange, values, errors }) => (
                     <Form>
                        <div
                           className="d-flex flex-column"
                           style={{ gap: "44px", marginTop: "40px" }}
                        >
                           <div>
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 Email
                              </label>
                              <input
                                 className={`form-control`}
                                 style={{ border: error ? "1px solid red" : "1px solid #ccc" }}
                                 type="email"
                                 placeholder="yourmail@gmail.com"
                                 name="email"
                                 onChange={handleChange}
                                 values={values?.email}
                                 autoComplete="off"
                              />
                              {errors.email && <div className="error-message">{errors.email}</div>}
                           </div>
                           <div className="form-group d-flex flex-center" style={{ gap: "40px" }}>
                              <Link to="/auth/login">
                                 <button
                                    type="button"
                                    className="btn btn-outline-info"
                                    style={{
                                       fontWeight: 500,
                                       fontSize: "14px",
                                       height: "44px",
                                       width: "114px"
                                    }}
                                 >
                                    Cancel
                                 </button>
                              </Link>
                              <button type="submit" className={`btn sign-btn font-weight-bold`}>
                                 {loading ? <Spin /> : "Submit"}
                              </button>
                           </div>
                        </div>
                     </Form>
                  )}
               </Formik>
            </div>
         )}
      </>
   );
}

export default injectIntl(ForgotPassword);
