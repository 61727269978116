import { Fab, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { LocalStorageService } from "../../base/services/local-storage.service";
import https from "../../base/utils/http";
import { errorHandler, getErrorMessage } from "../../base/utils/errorHandler";
import { getLoading, getUser } from "./selectors";
import { httpService } from "../../base/services/httpService.service";
import BlockUI from "../../base/components/BlockUI";
import { setLoading } from "./authSlice";
import FileUpload from "../../base/components/FileUpload/FileUpload";

const initialValues = {
   isRegistered: "0",
   message: "",
   files: "",
   additionalFileName: ""
};

function RegistrationStep3() {
   const { state } = useLocation();
   const navigate = useNavigate();
   const [isLoading, setIsLoading] = useState(false);
   const [files, setFiles] = useState();

   const RegistrationSchema = Yup.object().shape({
      message: Yup.string()
         .min(3, "Minimum 3 symbols")
         .max(50, "Maximum 50 symbols"),
      isRegistered: Yup.bool(),
      files: Yup.string().when("isRegistered", {
         is: true,
         then: Yup.string().required("Registered organizations must upload their files")
      })
   });

   const { user } = useSelector((state) => ({
      user: getUser(state)
   }));

   const onFileUpload = (val, setFieldValue) => {
      const paths = val.map((vl) => vl.path);
      setFieldValue("files", paths);
   };

   const sendApprovalRequest = (val) => {
      setIsLoading(true);
      httpService
         .post(
            "/approvalrequest/add",
            {
               organizationId: state?.data || LocalStorageService.getItem("orgId"),
               isRegistered: +val.isRegistered === 1 ? true : false,
               requestMessage: val.message,
               additionalFileName: val.additionalFileName,
               files: val.files
            },
            (data) => {
               setIsLoading(false);
               navigate("/auth/success", { state: { fromApp: true } });
            }
         )
         .finally(() => setIsLoading(false));
   };

   useEffect(() => {
      if (!state?.fromApp) {
         return navigate("/auth/registration");
      }
   }, [state, navigate, user]);

   return (
      <div className="login-form login-signin" style={{ display: "block" }}>
         <BlockUI isLoading={isLoading} />
         <div className="text-center mb-5 mb-lg-10">
            <h2>Verification information</h2>
         </div>
         <Formik
            initialValues={initialValues}
            validationSchema={RegistrationSchema}
            onSubmit={(values) => {
               sendApprovalRequest(values);
            }}
         >
            {({ handleSubmit, handleChange, values, setFieldValue, errors, touched }) => (
               <form
                  onSubmit={handleSubmit}
                  className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
               >
                  <div className="form-group fv-plugins-icon-container">
                     <label>Is your organization registred?</label>
                     <Field
                        className="form-control h-auto"
                        component="select"
                        id="isRegistered"
                        name="isRegistered"
                        values={values.isRegistered}
                        onChange={handleChange}
                     >
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                     </Field>
                     {errors.isRegistered && (
                        <div className="error-message">{errors.isRegistered}</div>
                     )}
                  </div>
                  <div className="d-flex flex-row">
                     <div className="form-group w-100 fv-plugins-icon-container d-flex flex-column">
                        <label>Upload registration document</label>
                        <FileUpload onUpload={(val) => onFileUpload(val, setFieldValue)} />
                        <Field
                           className="form-control d-none"
                           component="input"
                           id="files"
                           name="files"
                           values={values.files}
                           onChange={handleChange}
                        ></Field>
                        {errors.files ? <div className="error-message">{errors.files}</div> : null}{" "}
                     </div>
                     {/* <div className="form-group fv-plugins-icon-container ml-3 d-flex flex-column">
                        <label>Upload additional documents </label>
                        <FileUpload onUpload={(val) => onFileUpload(val, setFieldValue)} />
                        <Field
                           className="form-control d-none"
                           component="input"
                           id="additionalFileName"
                           name="additionalFileName"
                           values={values.additionalFileName}
                           onChange={handleChange}
                        ></Field>
                     </div> */}
                  </div>
                  <div className="form-group fv-plugins-icon-container">
                     <label>Message</label>
                     <TextField
                        className={`form-control h-auto py-6 px-6`}
                        type="text"
                        name="message"
                        onChange={handleChange}
                        values={values.message}
                     />
                     {errors.message && <div className="error-message">{errors.message}</div>}
                  </div>
                  <div className="d-flex justify-content-between"></div>
                  <div className="d-flex justify-content-center">
                     <button
                        type="submit"
                        className="btn btn-next font-weight-bold px-9 py-4 my-3 mx-4 "
                     >
                        Submit
                     </button>
                  </div>
               </form>
            )}
         </Formik>
      </div>
   );
}

export default RegistrationStep3;
