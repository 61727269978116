import React from "react";
import { Modal, Button } from "antd";
import { useNavigate } from "react-router-dom";

const PaymentModal = ({ isVisible, message, onClose }) => {
   const navigate = useNavigate();

   return (
      <Modal visible={isVisible} closable={false} footer={[]}>
         <div
            className="centered-box"
            style={{ boxShadow: "none", width: "100%", padding: "20px 10px" }}
         >
            <h1>Subscription Required</h1>
            <p>{message}</p>
            <Button
               className="back-to-payment-info-button"
               htmlType="submit"
               style={{ width: "fit-content", color: "#fff" }}
               onClick={() => {
                  navigate("/admin/payment-information");
                  onClose();
               }}
            >
               <div className="button-info">
                  <div className="back-to-payment-info-text"> Go to Payment Information</div>
               </div>
            </Button>
         </div>
      </Modal>
   );
};

export default PaymentModal;
