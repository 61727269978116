/* eslint-disable */
"use strict";

import KTMenu from "./../../components/menu.js";
import { KTUtil } from "./../../components/util.js";
import KTLayoutAside from "./aside.js";

var KTLayoutAsideMenu = (function() {
   // Private properties
   var _element;
   var _menuObject;

   // Initialize
   var _init = function() {
      _menuObject = new KTMenu(_element, {
         // Submenu setup
         submenu: {
            desktop: "accordion",
            tablet: "accordion", // menu set to accordion in tablet mode
            mobile: "accordion" // menu set to accordion in mobile mode
         },

         // Accordion setup
         accordion: {
            expandAll: false // allow having multiple expanded accordions in the menu
         }
      });

      // Close aside offcanvas panel before page reload On tablet and mobile
      _menuObject.on("linkClick", function(menu) {
         if (KTUtil.isBreakpointDown("lg")) {
            // Tablet and mobile mode
            KTLayoutAside.getOffcanvas().hide(); // Hide offcanvas after general link click
         }
      });
   };

   // Public methods
   return {
      init: function(id) {
         _element = KTUtil.getById(id);

         if (!_element) {
            return;
         }

         // Initialize menu
         _init();
      },

      getElement: function() {
         return _element;
      },

      getMenu: function() {
         return _menuObject;
      },

      pauseDropdownHover: function(time) {
         if (_menuObject) {
            _menuObject.pauseDropdownHover(time);
         }
      },

      closeMobileOffcanvas: function() {
         if (_menuObject && KTUtil.isMobileDevice()) {
            _menuObject.hide();
         }
      }
   };
})();

// Webpack support
if (typeof module !== "undefined") {
   // module.exports = KTLayoutAsideMenu;
}

export default KTLayoutAsideMenu;
