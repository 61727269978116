import React, { useMemo } from "react";
import objectPath from "object-path";
import { Outlet } from "react-router-dom";
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";
// Import Layout components
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Aside } from "./aside/Aside";
import { Footer } from "./footer/Footer";
import { LayoutInit } from "./LayoutInit";
import { SubHeader } from "./subheader/SubHeader";
import { ScrollTop } from "./extras/ScrollTop";

export function Layout({ children }) {
   const uiService = useHtmlClassService();
   // Layout settings (cssClasses/cssAttributes)
   const layoutProps = useMemo(() => {
      return {
         selfLayout: objectPath.get(uiService.config, "self.layout"),
         subheaderDisplay: true,
         contentCssClasses: uiService.getClasses("content", true),
         contentContainerClasses: uiService.getClasses("content_container", true),
         contentExtended: objectPath.get(uiService.config, "content.extended")
      };
   }, [uiService]);

   return layoutProps.selfLayout !== "blank" ? (
      <>
         {/*begin::Main*/}
         <HeaderMobile />

         <div className="d-flex flex-column flex-root">
            {/*begin::Page*/}
            <div className="d-flex flex-row flex-column-fluid page">
               <Aside />

               {/*begin::Wrapper*/}
               <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                  {/*begin::Content*/}
                  <div
                     id="kt_content"
                     // style={{ padding: "25px 0 0 " }}
                     style={{ padding: "0" }}
                     className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
                  >
                     <SubHeader />

                     {/*begin::Entry*/}
                     {layoutProps.contentExtended && <>{children}</>}

                     {!layoutProps.contentExtended && (
                        <div className="d-flex flex-column-fluid">
                           {/*begin::Container*/}
                           <div className="container-fluid px-0">
                              <Outlet />
                           </div>
                           {/*end::Container*/}
                        </div>
                     )}

                     {/*end::Entry*/}
                  </div>
                  {/*end::Content*/}
                  <Footer />
               </div>
               {/*end::Wrapper*/}
            </div>
            {/*end::Page*/}
         </div>

         <ScrollTop />

         {/*end::Main*/}
         <LayoutInit />
      </>
   ) : (
      // BLANK LAYOUT
      <div className="d-flex flex-column flex-root">{children}</div>
   );
}
