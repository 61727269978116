import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "../../../assets/styles/login-1.css";
import "../custom.css";
import { KapOrgIcon2 } from "../../../assets/icons";
import animationData from "../../../assets/animations/kaporg.json";
import Lottie from "react-lottie";
function AuthLayout() {
   const location = useLocation();
   const [title, setTitle] = useState("");
   const [description, setDescription] = useState("");

   const lottieOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
         preserveAspectRatio: "xMidYMid slice"
      }
   };

   useEffect(() => {
      if (location.pathname.includes("auth/international-donor-registration")) {
         setTitle("Dive into a world of");
         setDescription("impactful collaborations!");
      } else if (location.pathname.includes("auth/national-org-registration")) {
         setTitle("Elevate your organization");
         setDescription("with purposeful evaluation!");
      } else if (location.pathname.includes("auth/expert-registration")) {
         setTitle("Your expertise");
         setDescription("and insights fuels Progress!");
      } else if (location.pathname.includes("auth/expert-registration-step-three")) {
         setTitle("Your expertise");
         setDescription("and insights fuels Progress!");
      } else if (location.pathname.includes("auth/expert-registration-step-four")) {
         setTitle("Your expertise");
         setDescription("and insights fuels Progress!");
      }
   }, [location]);

   return (
      <>
         <div className="d-flex flex-column flex-lg-row">
            <div
               className="login-aside bgi-size-cover bgi-no-repeat"
               style={{
                  backgroundImage: `url(${toAbsoluteUrl("/media/bg/loginbackground.svg")})`
               }}
            >
               <div
                  style={{
                     display: "flex",
                     flexDirection: "column",
                     height: "100vh",
                     overflow: "hidden"
                  }}
               >
                  <div className="d-flex flex-column" style={{ padding: "40px 40px 0px 40px" }}>
                     <Link to="/">
                        <img alt="Logo" className="max-h-70px" src={KapOrgIcon2} />
                     </Link>
                     <p
                        className="text-white title-paragraph mb-0 mt-3"
                        style={{ fontWeight: 500 }}
                     >
                        {title}
                     </p>
                     <p
                        className="text-white font-italic description-paragraph m-0"
                        style={{
                           wordBreak: "break-word"
                        }}
                     >
                        {description}
                     </p>
                  </div>
                  {/* <div className="image-container"> */}
                  {/* <div className=" lottie-div-registration"> */}
                  <Lottie className="lottie-div-registration" options={lottieOptions} />
                  {/* </div> */}
                  {/* </div> */}
                  {/* <div className="image-container">
                     <img src="/media/bg/SideLogoProfileSelection.svg" alt="img" />
                  </div> */}
               </div>
            </div>
            <div className="d-flex flex-column flex-row-fluid position-relative layout-content">
               <div>
                  {" "}
                  <Outlet />
               </div>
            </div>
         </div>
      </>
   );
}

export default AuthLayout;
