import React, { useState } from "react";
// import "./style.scss";
import { Modal } from "antd";
import { XIcon } from "../assets/icons";
import { Tabs } from "antd";
import "../../index.scss";
import PrivacyPolicy from "./terms-conditions/PrivacyPolicyComponent";
import TermsServices from "./terms-conditions/TermsServicesComponent";
import CookiePolicy from "./terms-conditions/CookiePolicyComponent";

function TermsConditionsModal({ visible, onCancel }) {
   const { TabPane } = Tabs;

   return (
      <Modal
         open={visible}
         footer={null}
         closable={false}
         onCancel={onCancel}
         className="modalAddSurveyStyle"
      >
         <>
            <div className="d-flex flex-column" style={{ gap: "15px" }}>
               <div style={{ display: "block" }}>
                  <img
                     src={XIcon}
                     alt="BlackXIcon"
                     onClick={onCancel}
                     style={{ float: "right", cursor: "pointer" }}
                  />
               </div>
               <div>
                  <Tabs defaultActiveKey="1" style={{ maxHeight: "450px", overflowY: "auto" }}>
                     {/* , color: "#003DAA" */}
                     <TabPane
                        tab={<span style={{ fontWeight: 500 }}>Terms of service</span>}
                        key="1"
                     >
                        <TermsServices />
                     </TabPane>
                     <TabPane tab={<span style={{ fontWeight: 500 }}>Privacy policy</span>} key="2">
                        <PrivacyPolicy />
                     </TabPane>
                     <TabPane tab={<span style={{ fontWeight: 500 }}>Cookie policy</span>} key="3">
                        <CookiePolicy />
                     </TabPane>
                  </Tabs>
                  <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
                     <button
                        id="kt_login_signin_submit"
                        type="submit"
                        onClick={onCancel}
                        className={`btn sign-btn font-weight-bold my-3 w-auto`}
                     >
                        Got it!
                     </button>
                  </div>
               </div>
            </div>
         </>
      </Modal>
   );
}

export default TermsConditionsModal;
