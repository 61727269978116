import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import AuthService from "../services/authentication.service";

function ProtectedRoute({ allowedRoles }) {
   const user = AuthService.getUser();
   const location = useLocation();
   const params = new URLSearchParams(location.search);
   const action = params.get("action");

   const isLogoutAction = action === "delete" || action === "deactivate";
   if (isLogoutAction) {
      return <Navigate to={`/auth/account?action=${action}`} replace />;
   }

   return AuthService.isAuthorized(allowedRoles) ? (
      <Outlet />
   ) : user ? (
      <Navigate to="/unauthorized" replace />
   ) : (
      <Navigate to="/auth/login" replace />
   );
}

export default ProtectedRoute;
