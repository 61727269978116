import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { httpService } from "../../base/services/httpService.service";
import { Link, useLocation } from "react-router-dom";
import Toast from "../../base/components/Toast";
import { LocalStorageService } from "../../base/services/local-storage.service";
const initialValues = {
   code: ""
};

function ConfirmCode() {
   const navigate = useNavigate();
   const location = useLocation();

   const [error, setError] = useState(null);
   const userID = LocalStorageService.getItem("userId");
   const searchParam = new URLSearchParams(location.search);
   const email = searchParam.get("email");

   const sentCode = async (values) => {
      let formattedUserId = userID.replace(/"/g, "");
      try {
         await httpService
            .post("/Accounts/confirm-email", {
               userId: formattedUserId,
               code: values.code.trim()
            })
            .then(() => {
               navigate(`/auth/expert-registration-step-three`);
               Toast.success("Code verified successfully");
            });
      } catch (error) {
         const errorMessages =
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.map((error) => error.message);
         if (errorMessages && errorMessages.length > 0) {
            errorMessages.forEach((errorMessage) => {
               Toast.error(errorMessage);
               setError(true);
            });
         } else {
            setError(false);
         }
      }
   };

   const ConfirmCodeSchema = Yup.object().shape({
      code: Yup.string().required("Verification code is required")
   });

   return (
      <div
         className="pt-md-15 pr-md-10 pb-md-15 pl-md-10 custom-div"
         style={{
            margin: "0 !important",
            padding: "30px 20px",
            height: "auto",
            maxHeight: "470px",
            backgroundColor: "#fff"
         }}
      >
         <Formik
            initialValues={initialValues}
            validationSchema={ConfirmCodeSchema}
            onSubmit={(values) => sentCode(values)}
         >
            {({ handleSubmit, handleChange, values, errors, isSubmitting }) => (
               <form onSubmit={handleSubmit}>
                  <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
                     <div className="d-flex flex-column align-items-center">
                        <p
                           className="mb-4"
                           style={{ color: "#4158CF", fontWeight: 600, fontSize: "26px" }}
                        >
                           Verification code
                        </p>
                        <p style={{ color: "#555", fontSize: "16px", margin: "0px" }}>
                           Please enter the verification code that we sent to
                        </p>
                        <p style={{ color: "#3A3737", fontSize: "16px", fontWeight: 700 }}>
                           {email}
                        </p>
                        {error && <div className="error-message">{error}</div>}
                        <input
                           autoComplete="off"
                           style={{
                              borderRadius: "8px",
                              border: errors.code ? "1px solid red" : "1px solid #979797",
                              width: "325px",
                              height: "70px",
                              padding: "10px",
                              color: "#003DAA",
                              fontSize: "40px",
                              fontWeight: 700,
                              letterSpacing: "6.8px",
                              textAlign: "center",
                              marginTop: "15px"
                           }}
                           className={`form-control`}
                           maxLength={12}
                           onChange={handleChange}
                           value={values.code}
                           name="code"
                        />
                        {errors.code && <div className="error-message mt-4">{errors.code}</div>}
                        {/* {touched.code && errors.code && (
                              <div className="invalid-feedback">{errors.code}</div>
                           )} */}
                     </div>

                     <div
                        className="form-group d-flex flex-wrap flex-center"
                        style={{ gap: "40px" }}
                     >
                        <Link to="/">
                           <button
                              type="button"
                              className="btn btn-outline-info"
                              style={{
                                 fontWeight: 500,
                                 fontSize: "14px",
                                 height: "44px"
                              }}
                           >
                              Cancel
                           </button>
                        </Link>
                        <button
                           type="submit"
                           disabled={isSubmitting}
                           className={`btn sign-btn`}
                           style={{ fontWeight: 500 }}
                        >
                           Verify code
                        </button>
                     </div>
                     <p
                        style={{
                           textAlign: "center",
                           color: "#777",
                           fontSize: "14px",
                           fontWeight: 400
                        }}
                     >
                        If you didn’t receive a verification code,{" "}
                        <Link
                           to="/resend-verification"
                           style={{ color: "#5492FF", textDecoration: "none" }}
                        >
                           click here to resend it
                        </Link>{" "}
                        or send us an email at{" "}
                        <a
                           href="mailto:info@kaptechgroup.com"
                           style={{ color: "#555", fontWeight: 600 }}
                        >
                           info@kaptechgroup.com
                        </a>
                        .
                     </p>
                  </div>
               </form>
            )}
         </Formik>
      </div>
   );
}

export default ConfirmCode;
