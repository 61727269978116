import { create } from "zustand";
import { useState } from "react";
import { httpService } from "../../../../../base/services/httpService.service";
import { LocalStorageService } from "../../../../../base/services/local-storage.service";
import {
   debounce,
   addQuestion,
   deleteQuestion,
   addInnerSection,
   deleteSection,
   addSection,
   addFirstSection,
   duplicateQuestion,
   updateQuestionHint,
   updateQuestionLabel,
   updateQuestionType,
   updateQuestionChoices,
   updateSecondQuestionChoices,
   updateRangeValues,
   updateIsRequiredStatus,
   updateMaxChoices
} from "./helpers";
import Toast from "../../../../../base/components/Toast";

export const useSurveyStore = create((set) => ({
   isLoading: false,
   isSurveyLoading: false,
   surveyAlldata: [],
   surveyData: [],
   surveyBaseLanguage: "ecfb845a-86f0-476c-a4df-f101d35a96f9",
   arabicLanguageID: "b63818cb-da8f-44d6-a578-c9b83e39f5eb",
   surveyBaseLanguageName: "",
   allAssessmentsDropdown: [],
   selectedConsultantOrg: "",

   fetchSurveyById: (payload, loading = true) => {
      set({ isSurveyLoading: loading });

      httpService.post(
         "Survey/GetById",
         {
            SurveyId: payload.surveyId,
            languageId: payload.languageId
         },
         (res) => {
            const language =
               res?.data.surveyLanguageMaps?.find((lang) => lang.isBaseLanguage) || null;
            set({
               surveyAlldata: res?.data,
               surveyData: res?.data?.surveyItems,
               isSurveyLoading: false,
               surveyBaseLanguage: language.languageId, // You can use the correct value here
               surveyBaseLanguageName: language.name
            });
         }
      );
   },
   fetchSurveyByyId: (payload, loading = true) => {
      set({ isSurveyLoading: loading });

      httpService.post(
         `Surveys/${payload.surveyId}/${payload.languageId}`,
         {
            id: payload.surveyId,
            languageId: payload.languageId
         },
         (res) => {
            const language =
               res?.data.surveyLanguageMaps?.find((lang) => lang.isBaseLanguage) || null;
            set({
               surveyAlldata: res?.data,
               surveyData: res?.data?.surveyItems,
               isSurveyLoading: false,
               surveyBaseLanguage: language.languageId, // You can use the correct value here
               surveyBaseLanguageName: language.name
            });
         }
      );
   },
   setSurveySubmissionData: (key, value) => {
      set((state) => ({
         surveySubmissionData: {
            ...state.surveySubmissionData,
            [key]: value
         }
      }));
   },

   fetchSurveyDetailsById: (surveyId, loading = true) => {
      set({ isSurveyLoading: loading });

      httpService.get(`Surveys/survey-details?surveyId=${surveyId}`, (res) => {
         set({
            surveyAlldata: res.data,
            isSurveyLoading: false
         });
      });
   },

   fetchAllAssessmentsDropdown: () => {
      // set({});
      httpService.get(`Assesments/dropdown?pageSize=1000`, (res) => {
         set({
            allAssessmentsDropdown: res.data
         });
      });
   },

   setConsultantOrg: (data) => {
      set({
         selectedConsultantOrg: data
      });
   }
}));

export const useSurveyInputStore = create((set) => ({
   inputs: {},
   setInputs: (e) => {
      set((state) => {
         const { name, value } = e.target;
         const updatedInputs = { ...state.inputs };

         if (value !== "") {
            updatedInputs[name] = value;
         } else {
            delete updatedInputs[name];
         }

         return { inputs: updatedInputs };
      });
   },
   setDraftSurvey: (draftData) => {
      const mappedInputs = Object.keys(draftData).reduce((acc, key) => {
         const draft = draftData[key];
         acc[key] = {
            id: draft.id,
            surveyId: draft.surveyId,
            userId: draft.userId,
            questionId: draft.questionId,
            sectionId: draft.sectionId,
            maxScore: draft.maxScore,
            name: draft.name,
            skipLogicAnswer: draft.skipLogicAnswer,
            answerDetail: draft.answerDetail,
            questionType: draft.questionType,
            expectedAnswer: draft.expectedAnswer,
            score: draft.answerDetail.choices
         };
         return acc;
      }, {});

      set({ inputs: mappedInputs });
   },
   clearInputs: () => set({ inputs: {} })
}));

export const editDataStore = create((set) => ({
   editData: {},
   isSurveyLoading: false,
   surveyData: [],
   surveyAllData: [],
   isSurveyInWorkspace: false,
   setSurveyInWorkspace: (payload) => {
      set({
         isSurveyInWorkspace: payload
      });
   },
   fetchSurveyById: (payload, loading = true) => {
      payload?.isSavingProgress || set({ isSurveyLoading: loading });
      httpService.get(
         `Surveys/${payload.surveyId}/${payload.languageId}`,

         (res) => {
            const surveyData = res?.data?.surveyItems?.map((item) => {
               if (item.itemLabel === "Section" && item.innerItems) {
                  item.innerItems.forEach((innerItem, innerIndex) => {
                     innerItem.surveyItem.order = innerIndex + 1; // Start order count from 1 for each section
                  });
               }
               return item;
            });
            const language =
               res?.data.surveyLanguageMaps?.find((lang) => lang.isBaseLanguage) || null;
            set({
               isSurveyLoading: false,
               surveyData: surveyData,
               surveyAllData: res?.data,
               surveyBaseLanguage: language.languageId,
               surveyBaseLanguageName: language.name
            });
         }
      );
   },
   deleteQuestion: async (sectionId, questionId) => {
      const performDeletion = async () => {
         const updatedSurveyData = [...editDataStore.getState().surveyData];

         if (questionId === 0) {
            const sectionsIds = [sectionId];
            try {
               await httpService.delete("Surveys/sections", sectionsIds, (res) => {
                  if (res.status === 200) {
                     deleteSection(updatedSurveyData, sectionId);
                     set({ surveyData: updatedSurveyData });
                  }
               });
            } catch (error) {
               if (error.response && error.response.data && error.response.data.errors) {
                  const errorMessages = error.response.data.errors.map((error) => error.message);
                  if (errorMessages.length > 0) {
                     errorMessages.forEach((errorMessage) => {
                        Toast.error(errorMessage);
                     });
                  }
               }
               deleteSection(updatedSurveyData, sectionId);
               set({ surveyData: updatedSurveyData });
            }
         } else {
            const questionIds = [questionId];
            try {
               await httpService.delete("Surveys/Questions", questionIds, (res) => {
                  if (res.status === 200) {
                     deleteQuestion(updatedSurveyData, sectionId, questionId);
                     set({ surveyData: updatedSurveyData });
                  }
               });
            } catch (error) {
               if (error.response && error.response.data && error.response.data.errors) {
                  const errorMessages = error.response.data.errors.map((error) => error.message);
                  if (errorMessages.length > 0) {
                     errorMessages.forEach((errorMessage) => {
                        Toast.error(errorMessage);
                     });
                  }
               }
               deleteQuestion(updatedSurveyData, sectionId, questionId);
               set({ surveyData: updatedSurveyData });
            }
         }
      };

      await performDeletion();
   },
   addQuestion: (payload) => {
      set((state) => {
         const updatedSurveyData = [...state.surveyData];

         if (payload.questionType === 0) {
            addInnerSection(updatedSurveyData, payload);
         } else {
            addQuestion(updatedSurveyData, payload);
         }
         return { surveyData: updatedSurveyData };
      });
   },
   duplicateElement: (sectionId, questionId) => {
      set((state) => {
         const updatedSurveyData = [...state.surveyData];

         duplicateQuestion(updatedSurveyData, sectionId, questionId);
         return { surveyData: updatedSurveyData };
      });
   },
   addSection: (targetId) => {
      set((state) => {
         const updatedSurveyData = [...state.surveyData];

         if (targetId === 0) {
            addFirstSection(updatedSurveyData);
         } else {
            addSection(updatedSurveyData, targetId);
         }

         return { surveyData: updatedSurveyData };
      });
   },
   changeRequiredStatus: (id, status) => {
      set((state) => {
         const updatedEditData = { ...state.editData };
         if (!updatedEditData[id]) {
            updatedEditData[id] = { isRequired: status };
         } else {
            updatedEditData[id].isRequired = status;
         }
         const updatedSurveyData = updateIsRequiredStatus(state.surveyData, id, status);

         return { editData: updatedEditData, surveyData: updatedSurveyData };
      });
   },
   setQuestionTitle: (sectionId, id, text) => {
      set((state) => {
         const updatedEditData = { ...state.editData };
         if (!updatedEditData[id]) {
            updatedEditData[id] = { title: text };
         } else {
            updatedEditData[id].title = text;
         }
         const updatedSurveyData = updateQuestionLabel(state.surveyData, sectionId, id, text);

         return { editData: updatedEditData, surveyData: updatedSurveyData };
      });
   },
   setQuestionHint: (sectionId, id, text) => {
      set((state) => {
         const updatedEditData = { ...state.editData };

         if (!updatedEditData[id]) {
            updatedEditData[id] = { hint: text };
         } else {
            updatedEditData[id].hint = text;
         }

         const updatedSurveyData = updateQuestionHint(state.surveyData, sectionId, id, text);
         return { editData: updatedEditData, surveyData: updatedSurveyData };
      });
   },
   setQuestionType: (sectionId, id, type) => {
      set((state) => {
         const updatedEditData = { ...state.editData };
         if (!updatedEditData[id]) {
            updatedEditData[id] = { type: type };
         } else {
            updatedEditData[id].type = type;
         }
         const updatedSurveyData = updateQuestionType(state.surveyData, sectionId, id, type);
         return { editData: updatedEditData, surveyData: updatedSurveyData };
      });
   },
   setQuestionChoices: (sectionId, id, choices) => {
      set((state) => {
         const updatedEditData = { ...state.editData };
         if (!updatedEditData[id]) {
            updatedEditData[id] = {
               choices: choices
            };
         } else {
            updatedEditData[id].choices = choices;
         }

         const updatedSurveyData = updateQuestionChoices(state.surveyData, sectionId, id, choices);
         return { editData: updatedEditData, surveyData: updatedSurveyData };
      });
   },
   setMaxChoices: (sectionId, id, maxChoices) => {
      set((state) => {
         const updatedEditData = { ...state.editData };
         if (!updatedEditData[id]) {
            updatedEditData[id] = {
               maxChoices: maxChoices
            };
         } else {
            updatedEditData[id].maxChoices = maxChoices;
         }

         const updatedSurveyData = updateMaxChoices(state.surveyData, sectionId, id, maxChoices);
         return { editData: updatedEditData, surveyData: updatedSurveyData };
      });
   },
   setSecondQuestionChoices: (sectionId, id, choices) => {
      set((state) => {
         const updatedEditData = { ...state.editData };
         if (!updatedEditData[id]) {
            updatedEditData[id] = {
               secondChoices: choices
            };
         } else {
            updatedEditData[id].secondChoices = choices;
         }

         const updatedSurveyData = updateSecondQuestionChoices(
            state.surveyData,
            sectionId,
            id,
            choices
         );
         return { editData: updatedEditData, surveyData: updatedSurveyData };
      });
   },
   setRangeOptions: (sectionId, id, rangeOptions) => {
      set((state) => {
         const updatedEditData = { ...state.editData };

         if (!updatedEditData[id]) {
            updatedEditData[id] = {
               range: rangeOptions
            };
         } else {
            updatedEditData[id].range = rangeOptions;
         }

         const updatedSurveyData = updateRangeValues(state.surveyData, sectionId, id, rangeOptions);
         return { editData: updatedEditData, surveyData: updatedSurveyData };
      });
   }
}));

export const surveyLanguageStore = create((set) => ({
   language: "",
   setLanguage: (value) => set({ language: value })
}));

export const useTranslateStore = create((set) => ({
   toLanguage: "",
   languageStatus: "",
   labelData: [],
   choiceData: [],
   translationSections: [],
   translationLabels: [],
   setToLanguage: (value) => set({ toLanguage: value }),
   setLanguageStatus: (value) => set({ languageStatus: value }),
   handleTextChange: debounce((text, questionId, dataType, description) => {
      set((state) => {
         const existingEntryIndex = state.labelData.findIndex(
            (entry) => entry.questionId === questionId
         );
         let newData;
         if (existingEntryIndex !== -1) {
            const updatedData = [...state.labelData];
            updatedData[existingEntryIndex] = { questionId, text, dataType };
            newData = {};
         } else {
            switch (dataType) {
               case "Section":
                  const filteredSections = (state.translationSections || []).filter(
                     (entry) => entry.sectionId !== questionId
                  );
                  newData = {
                     ...state,
                     translationSections: [
                        ...filteredSections,
                        { sectionId: questionId, name: text, description: text }
                     ]
                  };
                  break;
               case "Question":
                  const filteredLabels = (state.translationLabels || []).filter(
                     (entry) => entry.questionId !== questionId
                  );
                  newData = {
                     ...state,
                     translationLabels: [...filteredLabels, { questionId, text }]
                  };
                  break;
               default:
                  newData = { ...state };
            }

            delete newData.labelData;
         }

         return newData;
      });
   }, 500),
   handleChoiceLabel: debounce((choiceId, value) => {
      set((state) => {
         const existingEntryIndex = state.choiceData.findIndex(
            (entry) => entry.choiceLabels[0].choiceId === choiceId
         );

         if (existingEntryIndex !== -1) {
            const updatedData = [...state.choiceData];
            updatedData[existingEntryIndex].choiceLabels[0].text = value;
            return { choiceData: updatedData };
         } else {
            return {
               choiceData: [
                  ...state.choiceData,
                  {
                     choiceLabels: [
                        {
                           text: value,
                           choiceId: choiceId
                        }
                     ]
                  }
               ]
            };
         }
      });
   }, 500)
}));

export const useGetDraftedSurvey = create((set) => ({
   survey: "",
   setSurvey: (value) => set({ survey: value })
}));

export const useFilePreview = () => {
   const [loadingFiles, setLoadingFiles] = useState({});

   const previewFiles = (e, filePath, id) => {
      e.stopPropagation();
      setLoadingFiles((prev) => ({ ...prev, [filePath]: true }));

      let correctedFilePath = filePath.replace(/\\202/g, "\\x82");
      const payload = [
         {
            fileKey: correctedFilePath,
            entityId: id
         }
      ];

      httpService
         .post(
            `/FileData/getFileUrlByFileKeyAndEntityId?platformId=f2d81c01-8cc9-4daf-b936-978ed3809c0b`,
            payload,
            () => {},
            (response) => {
               setLoadingFiles((prev) => ({ ...prev, [filePath]: false }));
               if (response && response.data && response.data[0].fileUrl) {
                  window.open(response.data[0].fileUrl, "_blank");
               } else {
               }
            }
         )
         .catch(() => {
            setLoadingFiles((prev) => ({ ...prev, [filePath]: false }));
         });
   };

   return { loadingFiles, previewFiles };
};

export const useSurveySkipLogicStore = create((set) => ({
   skipLogicCreate: [],

   setSkipLogicCreate: (question, logicalOperator, skipLogics) => {
      set((state) => {
         const sectionId = question?.itemLabel === "Section" && question?.id;
         const questionId = !question?.itemLabel && question?.id;

         let itemExists = false;
         const updatedSkipLogic = state.skipLogicCreate.map((item) => {
            if (
               (item.questionId && questionId && item.questionId === questionId) ||
               (item.sectionId && sectionId && item.sectionId === sectionId)
            ) {
               itemExists = true;
               const existingSkipLogic = item.skipLogics.find(
                  (logic) => logic.index === skipLogics[0].index
               );

               if (skipLogics[0].questionType === 33 && skipLogics[0].value) {
                  //later: logicaloperator for selectmany should be put directly from preview
                  // item.logicalOperator = 1;
                  if (existingSkipLogic && existingSkipLogic.value === "") {
                     item.skipLogics = item.skipLogics.map((logic) =>
                        logic.index === skipLogics[0].index ? { ...logic, ...skipLogics[0] } : logic
                     );
                  } else {
                     item.skipLogics.push({ ...skipLogics[0] });
                  }
               } else {
                  if (existingSkipLogic) {
                     item.skipLogics = item.skipLogics.map((logic) =>
                        logic.index === skipLogics[0].index ? { ...logic, ...skipLogics[0] } : logic
                     );
                  } else {
                     item.skipLogics.push(...skipLogics);
                  }
               }
            }
            return item;
         });

         if (!itemExists) {
            const newSkipLogic = {
               sectionId,
               questionId,
               logicalOperator,
               skipLogics
            };

            updatedSkipLogic.push(newSkipLogic);
         }

         return { skipLogicCreate: updatedSkipLogic };
      });
   },
   deleteSkipLogic: (question, index, value) => {
      set((state) => {
         const updatedSkipLogic = state.skipLogicCreate.map((item) => {
            if (item.questionId === question.id || item.sectionId === question.id) {
               if (item.skipLogics.length > 0) {
                  // later: value is not passed in general delete item from skipLogicCreate state
                  if (value) {
                     item.skipLogics = item.skipLogics.filter((logic) => logic.value !== value);
                  } else {
                     item.skipLogics = item.skipLogics.filter((logic) => logic.index !== index);
                  }

                  if (item.skipLogics.length === 0) {
                     return {
                        ...item,
                        skipLogics: []
                     };
                  }
               } else {
                  return undefined;
               }
            }
            return item;
         });

         return { skipLogicCreate: updatedSkipLogic };
      });
   },
   setLogicalOperator: (question, logicalOperator) => {
      set((state) => {
         const sectionId = question?.itemLabel === "Section" && question?.id;
         const questionId = !question?.itemLabel && question?.id;

         const updatedSkipLogic = state.skipLogicCreate.map((item) => {
            if (item.questionId === questionId || item.sectionId === sectionId) {
               item.logicalOperator = logicalOperator;
            }
            return item;
         });

         return { skipLogicCreate: updatedSkipLogic };
      });
   },
   setSkipLogicCreateFromBackend: (skipLogicData) => {
      set((state) => {
         return {
            skipLogicCreate: [
               ...state.skipLogicCreate,
               {
                  sectionId: skipLogicData.sectionId,
                  questionId: skipLogicData.questionId,
                  logicalOperator: skipLogicData.logicalOperator,
                  skipLogics: skipLogicData.skipLogics
               }
            ]
         };
      });
   },
   clearSkipLogicCreate: () => {
      set((state) => {
         return {
            skipLogicCreate: []
         };
      });
   }
}));
