import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { httpService } from "../../services/httpService.service";
import { Spinner } from "react-bootstrap";
import { formatBytes } from "../../utils/byteConverter";
import "./FileUpload.scss";

function FileUpload({ onUpload, questionId = null, acceptedTypes = {} }) {
   const [isLoading, setIsLoading] = useState(false);
   const onDrop = useCallback((acceptedFiles) => {
      const formData = new FormData();

      acceptedFiles.forEach((file) => {
         formData.append("data", file);
      });

      if (questionId !== null) {
         if (acceptedFiles.length > 0) {
            httpService.uploadFileForQuestion(
               "/file/upload",
               formData,
               {
                  questionId: questionId
               },
               (res) => {
                  onUpload(res.data);
               }
            );
         }
      } else {
         if (acceptedFiles.length > 0) {
            setIsLoading(true);
            httpService
               .upload("/file/upload", formData, (res) => {
                  onUpload(res.data);
               })
               .finally(() => setIsLoading(false));
         }
      }
   }, []);

   const {
      getRootProps,
      getInputProps,
      acceptedFiles,
      fileRejections,
      isFocused,
      isDragAccept,
      isDragReject
   } = useDropzone({ onDrop, maxFiles: 20, maxSize: 5000000, accept: acceptedTypes });
   // } = useDropzone({accept: {'image/*': []}});

   const files = acceptedFiles.map((file) => (
      <li key={file.path}>
         {file.path} - {formatBytes(file.size)}
      </li>
   ));

   const fileRejectionItems = fileRejections.map(({ file, errors }) => {
      return (
         <li key={file.path}>
            {file.path} - {formatBytes(file.size)}
            <ul>
               {errors.map((e) => (
                  <li className="text-danger" key={e.code}>
                     {e.message}
                  </li>
               ))}
            </ul>
         </li>
      );
   });

   return (
      <div>
         <div
            className={`dropzone-wrapper ${
               isDragAccept
                  ? "drag-accepted"
                  : isDragReject
                  ? "drag-rejected"
                  : isFocused
                  ? "drag-focused"
                  : ""
            }`}
            {...getRootProps()}
         >
            <input {...getInputProps()} />
            <p className="dropText">Drag and drop some files here, or click to select files</p>
         </div>
         {files.length > 0 && (
            <div>
               <div className="d-flex mt-5">
                  <h6>Uploaded files:</h6>
                  {isLoading && (
                     <Spinner className="ml-2 mb-5" animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                     </Spinner>
                  )}
               </div>
               <ul>{files}</ul>
            </div>
         )}

         {fileRejectionItems.length > 0 && (
            <div className="mt-5">
               <h6>Rejected files:</h6>
               <ul>{fileRejectionItems}</ul>
            </div>
         )}
      </div>
   );
}
//Usage with formik
/* <FileUpload
onUpload={(val) => {
   setFieldValue("licenceFileName", val.path);
}}
/> */

export default FileUpload;
