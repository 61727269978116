import React, { useState, useEffect } from "react";
import "../style.scss";
import { Link } from "react-router-dom";
import { httpService } from "../../../base/services/httpService.service";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { LocalStorageService } from "../../../base/services/local-storage.service";
import { Formik, Form, Field, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { registerUserOrg } from "../authSlice";
import * as Yup from "yup";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { BackIcon, EyeInvisible } from "../../../assets/icons";
import { DatePicker, Spin } from "antd";
import TermsConditionsModal from "../../../components/TermsConditionsModal";
import Toast from "../../../base/components/Toast";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const initialValues = {
   email: "",
   password: "",
   confirmPassword: "",
   internationalName: "",
   description: "",
   dateOfEstablishment: null,
   organizationSizeId: null,
   sectors: [],
   organizationEmail: "",
   baseCountryId: null,
   cityId: "",
   address: "",
   zipCode: ""
};

const validationSchema = Yup.object().shape({
   email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
   password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(
         /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*\d).*$/,
         "Password must contain at least one uppercase letter, one number and one of the specified symbols !, @, #, $, %, ^, &, *"
      )
      .required("Password is required"),
   confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
   internationalName: Yup.string().required("International Organization's name is required"),
   dateOfEstablishment: Yup.date()
      .nullable()
      .required("Foundation year is required"),
   sectors: Yup.array()
      .min(1, "At least one sector must be selected")
      .required("Sectors are required"),
   baseCountryId: Yup.string()
      .nullable()
      .required("Base country is required"),
   cityId: Yup.string().nullable(),
   // .required("Base city is required"),
   organizationSizeId: Yup.string()
      .nullable()
      .required("Organization size is required"),
   address: Yup.string().required("Organization address is required"),
   organizationEmail: Yup.string()
      .email("Invalid organization email")
      .required("Organization's email is required"),
   acceptTerms: Yup.boolean().oneOf([true], "You must accept the terms and conditions")
});

function RegistrationOfNationalOrganization() {
   const [sectorList, setSectorList] = useState([]);
   const [orgSizeList, setOrgSizeList] = useState([]);
   const [countryList, setCountryList] = useState([]);
   const [cityList, setCityList] = useState([]);
   const [type, setType] = useState("password");
   const [icon, setIcon] = useState(eyeOff);
   const [selectedCountry, setSelectedCountry] = useState(null);
   const [selectedCity, setSelectedCity] = useState(null);
   const [confirmType, setConfirmType] = useState("password");
   const [confirmIcon, setConfirmIcon] = useState(eyeOff);
   const [modalVisible, setModalVisible] = useState(false);
   const loading = useSelector((state) => state.auth.isLoading);

   const onSubmit = async (values) => {
      if (cityList.length > 0 && selectedCity === "" && values.cityId === "") {
         formik.setFieldError("cityId", "City is required!");
      }

      try {
         const orgSizeIdValue = values.organizationSizeId.value;
         const baseCountryIdValue = values.baseCountryId.value;
         const sectorValues = values.sectors.map((sector) => sector.value);

         const updatedValues = {
            ...values,
            baseCountryId: baseCountryIdValue,
            organizationSizeId: orgSizeIdValue,
            sectors: sectorValues,
            dateOfEstablishment: values?.dateOfEstablishment.format("YYYY-MM-DD")
         };
         dispatch(
            registerUserOrg(updatedValues, (user) => {
               LocalStorageService.setItem("userId", user.userId);
               navigate(
                  `/auth/national-org-registration/verification?email=${encodeURIComponent(
                     updatedValues.email
                  )}`
               );
            })
         );
      } catch (error) {
         if (error.response || error.response.data || error.response.data.errors) {
            const errorMessage = error.response.data.errors.message;
            Toast.error(errorMessage);
         }
      }
   };

   const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema
   });

   const dispatch = useDispatch();
   const navigate = useNavigate();

   const openTermsModal = () => {
      setModalVisible(!modalVisible);
   };

   const handleCountryChange = async (selectedOption) => {
      const countryId = selectedOption.value;

      await httpService.get(`/countries/${countryId}/cities`, (res) => {
         const cityData = res.data.map((city) => ({
            value: city.id,
            label: city.name
         }));

         setCityList(cityData);
         setSelectedCountry(selectedOption); // Update selected country
         setSelectedCity(null); // Reset selected city
      });
   };

   const handleTogglePassword = () => {
      if (type === "password") {
         setIcon(eye);
         setType("text");
      } else {
         setIcon(eyeOff);
         setType("password");
      }
   };

   const handleToggleConfirmPassword = () => {
      if (confirmType === "password") {
         setConfirmIcon(eye);
         setConfirmType("text");
      } else {
         setConfirmIcon(eyeOff);
         setConfirmType("password");
      }
   };

   useEffect(() => {
      httpService.get(`/Organizations/sectors`, (data) => {
         const sectorData = data.data.map((sector) => {
            return {
               value: sector.id,
               label: sector.name
            };
         });
         setSectorList(sectorData);
      });
      httpService.get(`/Organizations/sizes`, (data) => {
         const orgSizeData = data.data.map((size) => {
            return {
               value: size.id,
               label: size.range
            };
         });
         setOrgSizeList(orgSizeData);
      });
      httpService.get(`/countries`, (data) => {
         const countryData = data.data.map((country) => {
            return {
               value: country.id,
               label: country.name
            };
         });
         setCountryList(countryData);
      });
      httpService.get(`/Countries/cities`, (res) => {
         const cityData = res.data.map((city) => {
            return {
               value: city.id,
               label: city.name
            };
         });
         setCityList(cityData);
      });
   }, []);

   // const onSubmit = (values) => {};

   return (
      <>
         <div className="p-8 p-md-10 pt-xl-10 pb-xl-20 pl-xl-40 pr-xl-40">
            <Link
               to="/auth/registration"
               className="d-flex align-items-center"
               style={{
                  color: "#5492FF",
                  // margin: "50px 0 0 16px",
                  fontSize: "16px",
                  fontWeight: 500,
                  cursor: "pointer"
               }}
            >
               <img src={BackIcon} alt="Go back" style={{ marginRight: "10px" }} />
               Go back to profile selection
            </Link>
            <Formik {...formik} onSubmit={onSubmit} validationSchema={validationSchema}>
               {({ values, handleChange, setFieldValue, errors, touched }) => (
                  <Form>
                     <div
                        style={{
                           display: "flex",
                           flexDirection: "column",
                           marginTop: "40px",
                           gap: "32px"
                        }}
                     >
                        <p
                           style={{
                              fontSize: "26px",
                              color: "#4158CF",
                              fontWeight: 600,
                              margin: "0px"
                           }}
                        >
                           Create an account <span style={{ color: "#5492FF" }}>as an Org</span>
                        </p>
                        <div>
                           <p style={{ fontSize: "18px", fontWeight: 600, color: "#4158CF" }}>
                              Login details
                           </p>
                           <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    <span style={{ color: "red" }}>* </span>
                                    Email
                                 </label>
                                 <Field
                                    placeholder="yourmail@gmail.com"
                                    className={`form-control`}
                                    type="email"
                                    name="email"
                                    autocomplete="off"
                                 />
                                 {errors.email && touched.email && (
                                    <div className="text-danger">{errors.email}</div>
                                 )}
                              </div>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    <span style={{ color: "red" }}>* </span>
                                    Password
                                 </label>
                                 <div className="password-section">
                                    <Field
                                       placeholder="Password"
                                       type={type}
                                       name="password"
                                       className="form-control"
                                       maxLength={16}
                                    />
                                    <img
                                       className="eyeButton"
                                       onClick={handleTogglePassword}
                                       src={EyeInvisible}
                                       alt="showPasswordIcon"
                                    />
                                 </div>

                                 {errors.password && touched.password && (
                                    <div className="text-danger">{errors.password}</div>
                                 )}
                              </div>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    <span style={{ color: "red" }}>* </span>
                                    Confirm password
                                 </label>
                                 <div className="password-section">
                                    <Field
                                       placeholder="Password"
                                       type={confirmType}
                                       name="confirmPassword"
                                       className="form-control"
                                       maxLength={16}
                                    />
                                    <img
                                       className="eyeButton"
                                       onClick={handleToggleConfirmPassword}
                                       src={EyeInvisible}
                                       alt="showPasswordIcon"
                                    />
                                 </div>

                                 {errors.confirmPassword && touched.confirmPassword && (
                                    <div className="text-danger">{errors.confirmPassword}</div>
                                 )}
                              </div>
                           </div>
                        </div>
                        <div>
                           <p style={{ fontSize: "18px", fontWeight: 600, color: "#4158CF" }}>
                              Organization details
                           </p>
                           <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    <span style={{ color: "red" }}>* </span>
                                    Organization name
                                 </label>
                                 <Field
                                    placeholder="Enter organization's name"
                                    type="text"
                                    name="internationalName"
                                    className="form-control"
                                    autocomplete="off"
                                 />
                                 {errors.internationalName && touched.internationalName && (
                                    <div className="text-danger">{errors.internationalName}</div>
                                 )}
                              </div>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    Organization description{" "}
                                    <span
                                       style={{
                                          color: "#979797",
                                          fontSize: "12px",
                                          fontWeight: 400
                                       }}
                                    >
                                       (optional)
                                    </span>
                                 </label>
                                 <div className="password-section">
                                    <Field
                                       placeholder="Autosize height based on content lines"
                                       as="textarea"
                                       name="description"
                                       className="form-control"
                                       maxLength={100}
                                       autocomplete="off"
                                    />
                                 </div>
                                 <span
                                    style={{ float: "right", fontSize: "12px", color: "#979797" }}
                                 >
                                    {values?.description?.length}/100
                                 </span>
                                 {errors.description && touched.description && (
                                    <div className="text-danger">{errors.description}</div>
                                 )}
                              </div>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    <span style={{ color: "red" }}>* </span>
                                    Foundation year
                                 </label>
                                 <div className="password-section">
                                    <DatePicker
                                       name="dateOfEstablishment"
                                       placeholderText="Select date"
                                       onChange={(date) =>
                                          setFieldValue("dateOfEstablishment", date)
                                       }
                                       disabledDate={(current) => {
                                          return current && current > dayjs().endOf("day");
                                       }}
                                       className="form-control"
                                       value={
                                          values?.dateOfEstablishment &&
                                          dayjs(values?.dateOfEstablishment)
                                       }
                                    />
                                 </div>
                                 {errors.dateOfEstablishment && touched.dateOfEstablishment && (
                                    <div className="text-danger">{errors.dateOfEstablishment}</div>
                                 )}
                              </div>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    <span style={{ color: "red" }}>* </span>
                                    Organization size
                                 </label>

                                 <Field
                                    as={Select}
                                    name="organizationSizeId"
                                    placeholder="Select the size of your organization"
                                    options={orgSizeList}
                                    onChange={(selectedOrgSize) => {
                                       setFieldValue("organizationSizeId", selectedOrgSize);
                                    }}
                                 />
                                 {errors.organizationSizeId && touched.organizationSizeId && (
                                    <div className="text-danger">{errors.organizationSizeId}</div>
                                 )}
                              </div>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    <span style={{ color: "red" }}>* </span>
                                    Working sectors
                                 </label>
                                 <Field
                                    placeholder="Select all the sectors your organization works in"
                                    name="sectors"
                                    as={Select}
                                    isMulti
                                    options={sectorList}
                                    value={values.sectors}
                                    onChange={(selectedOptions) =>
                                       setFieldValue("sectors", selectedOptions)
                                    }
                                 />
                                 {errors.sectors && touched.sectors && (
                                    <div className="text-danger">{errors.sectors}</div>
                                 )}
                              </div>
                           </div>
                        </div>
                        <div>
                           <p style={{ fontSize: "18px", fontWeight: 600, color: "#4158CF" }}>
                              Contact information
                           </p>
                           <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    <span style={{ color: "red" }}>* </span>
                                    Organization's email
                                 </label>
                                 <Field
                                    placeholder="Enter organization's name"
                                    type="email"
                                    name="organizationEmail"
                                    className="form-control"
                                    autocomplete="off"
                                 />
                                 {errors.organizationEmail && touched.organizationEmail && (
                                    <div className="text-danger">{errors.organizationEmail}</div>
                                 )}
                              </div>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    <span style={{ color: "red" }}>* </span>
                                    Base country
                                 </label>

                                 <Field
                                    placeholder="Select your base country"
                                    as={Select}
                                    name="baseCountryId"
                                    value={values.baseCountryId}
                                    options={countryList}
                                    onChange={(selectedOption) => {
                                       setSelectedCountry(selectedOption);
                                       setSelectedCity(null);
                                       setFieldValue("baseCountryId", selectedOption);
                                       setFieldValue("cityId", null);
                                       handleCountryChange(selectedOption);
                                    }}
                                    autocomplete="off"
                                 />
                                 {errors.baseCountryId && touched.baseCountryId && (
                                    <div className="text-danger">{errors.baseCountryId}</div>
                                 )}
                              </div>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    City
                                 </label>
                                 <Select
                                    value={selectedCity ?? null}
                                    isDisabled={cityList.length === 0}
                                    className="basic-single"
                                    options={cityList}
                                    placeholder="Select your base city"
                                    isSearchable
                                    onChange={(selectedOption) => {
                                       setSelectedCity(selectedOption);
                                       setFieldValue("cityId", selectedOption.value);
                                    }}
                                 />
                                 {errors.cityId && touched.cityId && (
                                    <div className="text-danger">{errors.cityId}</div>
                                 )}
                              </div>
                              <div className="form-group fv-plugins-icon-container">
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    <span style={{ color: "red" }}>* </span>
                                    Address
                                 </label>
                                 <Field
                                    placeholder="Enter organization's address"
                                    as="textarea"
                                    name="address"
                                    className="form-control"
                                    autocomplete="off"
                                 />
                                 {errors.address && touched.address && (
                                    <div className="text-danger">{errors.address}</div>
                                 )}
                              </div>
                              <div className="form-group fv-plugins-icon-container">
                                 {/* select here */}
                                 <label
                                    style={{
                                       fontSize: "16px",
                                       color: "#3A3737",
                                       fontWeight: 500,
                                       marginBottom: "10px"
                                    }}
                                 >
                                    ZIP code
                                 </label>
                                 <Field
                                    placeholder="Enter organization's zip code"
                                    type="text"
                                    name="zipCode"
                                    className="form-control"
                                    autocomplete="off"
                                 />
                              </div>
                              <div>
                                 <div style={{ display: "flex", alignItems: "flex-start" }}>
                                    <Field
                                       type="checkbox"
                                       name="acceptTerms"
                                       required
                                       className="m-1"
                                    />
                                    <p style={{ margin: 0 }}>
                                       I have read and agreed with the{" "}
                                       <Link
                                          onClick={openTermsModal}
                                          to="#"
                                          rel="noopener noreferrer"
                                          style={{
                                             color: "#5492FF",
                                             textDecoration: "underline",
                                             margin: "0 4px"
                                          }}
                                       >
                                          Terms of Service, Privacy Policy
                                       </Link>
                                       and{" "}
                                       <Link
                                          onClick={openTermsModal}
                                          to="#"
                                          rel="noopener noreferrer"
                                          style={{
                                             color: "#5492FF",
                                             textDecoration: "underline",
                                             margin: "0 4px"
                                          }}
                                       >
                                          Cookie Policy.
                                       </Link>
                                    </p>
                                 </div>

                                 {errors.acceptTerms && touched.acceptTerms && (
                                    <div className="text-danger">{errors.acceptTerms}</div>
                                 )}
                              </div>
                           </div>
                        </div>
                        <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
                           <button
                              id="kt_login_signin_submit"
                              type="submit"
                              className={`btn sign-btn font-weight-bold my-3`}
                              disabled={loading}
                           >
                              {loading ? <Spin /> : "Sign up"}
                           </button>
                        </div>
                     </div>
                  </Form>
               )}
            </Formik>
         </div>
         <TermsConditionsModal visible={modalVisible} onCancel={openTermsModal} />
      </>
   );
}
export default RegistrationOfNationalOrganization;
