import Header from "./components/Header";
import FirstSection from "./components/FirstSection";
import React from "react";
import Advantages from "./components/Advantages";
import Footer from "./components/Footer";
import ExpectedOutcomes from "./components/ExpectedOutcomes";
import OrganizationalDevelopment from "./components/OrganizationalDevelopment";
import FundingNetworks from "./components/FundingNetworks";
import SocialChangeSudan from "./components/SocialChangeSudan";
import PartnerWithKap from "./components/PartnerWithKAP";
function LoginSudan() {
  return (
    <div className="App">
        <div className="sudan-kaporg">
            <Header />
            <OrganizationalDevelopment />
            <FirstSection />
            <PartnerWithKap />
            <FundingNetworks />
            <SocialChangeSudan />
            <Advantages />
            <ExpectedOutcomes />
            <Footer />
        </div>
    </div>
  );
}

export default LoginSudan;
