import { makeStyles, TextField } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import BlockUI from "../../base/components/BlockUI";
import { httpService } from "../../base/services/httpService.service";
import { LocalStorageService } from "../../base/services/local-storage.service";
import { getDateFromDatePicker } from "../../base/utils/timezoneConverter";
import "./custom.css";
import { getUser } from "./selectors";

const initialValues = {
   name: "",
   acronym: "",
   description: "",
   year: "",
   address: "",
   email: "",
   number: "",
   country: "",
   sector: [],
   size: "",
   operation: []
};

const useStyles = makeStyles((theme) => ({
   container: {
      display: "flex",
      flexWrap: "wrap"
   },
   textField: {
      width: "100%",
      margin: 0
   }
}));

function RegistrationStep2() {
   const [isLoading, setIsLoading] = useState(false);
   const [startDate, setStartDate] = useState(new Date());
   const RegistrationSchema = Yup.object().shape({
      name: Yup.string()
         .min(3, "Minimum 3 symbols")
         .required("Required"),
      acronym: Yup.string()
         .max(50, "Maximum 50 symbols")
         .required("Required"),
      description: Yup.string()
         .min(3, "Minimum 3 symbols")
         .required("Required"),
      year: Yup.string().required("Required"),
      size: Yup.string().required("Required"),
      address: Yup.string()
         .min(3, "Minimum 3 symbols")
         .required("Required"),
      email: Yup.string()
         .email("Invalid email")
         .required("Required"),
      number: Yup.string()
         .min(3, "Minimum 3 symbols")
         .required("Required"),
      operation: Yup.array().required("Required"),
      sector: Yup.array().required("Required"),
      country: Yup.string().required("Required")
   });

   const { state } = useLocation();
   const navigate = useNavigate();

   const classes = useStyles();

   const { user } = useSelector((state) => ({
      user: getUser(state)
   }));

   const [countriesList, setCountriesList] = useState([]);
   const [selectedCountry, setSelectedCountry] = useState(null);
   const [selectedOperatingCountries, setSelectedOperatingCountries] = useState([]);
   const [sectorsList, setSectorsList] = useState([]);
   const [selectedSectors, setSelectedSectors] = useState([]);
   const [sizesList, setSizesList] = useState([]);
   const [selectedSize, setSelectedSize] = useState(null);
   const [organizationTypes, setOrganizationTypes] = useState([]);
   const [selectedType, setSelectedType] = useState(null);

   const handleTypeChange = (selectedType, values) => {
      values.type = selectedType.value;
      setSelectedType(selectedType);
   };

   const handleCountryChange = (selectedCountry, values) => {
      values.country = selectedCountry.value;
      setSelectedCountry(selectedCountry);
   };

   const handleSectorChange = (selectedSectors, values) => {
      values.sector = selectedSectors?.map((sector) => {
         return sector.value;
      });
      setSelectedSectors(selectedSectors);
   };

   const handleOperatingCountries = (selectedOperatingCountries, values) => {
      values.operation = selectedOperatingCountries?.map((operation) => {
         return operation.value;
      });
      setSelectedOperatingCountries(selectedOperatingCountries);
   };

   const handleSizeChange = (selectedSize, values) => {
      values.size = selectedSize.value;
      setSelectedSize(selectedSize);
   };

   const saveOrganization = (values) => {
      setIsLoading(true);
      const userId = state?.data || user?.id || LocalStorageService.getItem("userId");
      httpService
         .post(
            "/organization",
            {
               userId: userId,
               internationalName: values.name,
               acronym: values.acronym,
               description: values.description,
               dateOfEstablishment: values.year,
               baseCountryId: values.country,
               address: values.address,
               organizationSectors: values.sector,
               orgSizeId: values.size,
               orgTypeId: values.type,
               email: values.email,
               phoneNumber: values.number,
               countries: values.operation
            },
            (data) => {
               setIsLoading(false);
               LocalStorageService.setItem("orgId", data.data);
               if (+values.type === 3 || +values.type === 2) {
                  //Donor or International Organization
                  navigate("/auth/success", { state: { fromApp: true } });
               } else {
                  navigate("/auth/request-approval", {
                     state: { fromApp: true, orgID: data.data }
                  });
               }
            }
         )
         .finally(() => {
            setIsLoading(false);
         });
   };

   useEffect(() => {
      if (!state?.fromApp) {
         return navigate("/auth/registration");
      }
   }, [state, navigate]);

   useEffect(() => {
      httpService.get("/countries", (data) => {
         const formattedData = data.data.map((country) => {
            return {
               value: country.id,
               label: country.name
            };
         });
         setCountriesList(formattedData);
      });

      httpService.get("/organization/sectors/null", (data) => {
         const formattedData = data.data.map((sector) => {
            return {
               value: sector.id,
               label: sector.name
            };
         });
         setSectorsList(formattedData);
      });

      httpService.get("/organization/sizes", (data) => {
         const formattedData = data.data.map((size) => {
            return {
               value: size.id,
               label: size.range
            };
         });

         setSizesList(formattedData);
      });

      httpService.get("/organization/types", (data) => {
         const formattedData = data.data.map((type) => {
            return {
               value: type.id,
               label: type.name
            };
         });

         setOrganizationTypes(formattedData);
      });
   }, []);

   return (
      <>
         <BlockUI isLoading={isLoading} />
         <div className="login-form login-signin" style={{ display: "block" }}>
            <div className="text-center mb-5 mb-lg-10">
               <h2>Organization information</h2>
            </div>
            <Formik
               initialValues={initialValues}
               validationSchema={RegistrationSchema}
               onSubmit={(values) => {
                  saveOrganization(values);
               }}
            >
               {({
                  handleSubmit,
                  handleChange,
                  touched,
                  values,
                  errors,
                  setFieldTouched,
                  setFieldValue
               }) => (
                  <form
                     onSubmit={handleSubmit}
                     className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                  >
                     <div className="form-group fv-plugins-icon-container">
                        <label>Organization international name*</label>
                        <input
                           className={`form-control h-auto py-3 px-6`}
                           type="text"
                           name="name"
                           onChange={handleChange}
                           values={values.name}
                        />
                        {errors.name && touched.name ? (
                           <div className="error-message">{errors.name}</div>
                        ) : null}{" "}
                     </div>
                     <div className="form-group fv-plugins-icon-container">
                        <label>Acronym*</label>
                        <input
                           className={`form-control h-auto py-3 px-6`}
                           type="text"
                           name="acronym"
                           onChange={handleChange}
                           values={values.acronym}
                        />
                        {errors.acronym && touched.acronym ? (
                           <div className="error-message">{errors.acronym}</div>
                        ) : null}{" "}
                     </div>
                     <div className="form-group fv-plugins-icon-container">
                        <label>Organization description*</label>
                        <TextField
                           multiline
                           className={`form-control h-auto py-6 px-6`}
                           type="text"
                           name="description"
                           onChange={handleChange}
                           values={values.description}
                        />
                        {errors.description && touched.description ? (
                           <div className="error-message">{errors.description}</div>
                        ) : null}{" "}
                     </div>
                     <div className="form-group fv-plugins-icon-container">
                        <div className="date-picker">
                           <label>Foundation year*</label>
                           <DatePicker
                              id="year"
                              name="year"
                              className={`form-control h-auto py-3 px-6`}
                              selected={startDate}
                              onChange={(val) => {
                                 setStartDate(val);
                                 setFieldValue("year", val);
                              }}
                           />
                           {errors.year && touched.year ? (
                              <div className="error-message">{errors.year}</div>
                           ) : null}{" "}
                        </div>
                     </div>
                     <div className="form-group fv-plugins-icon-container">
                        <label>Country*</label>
                        <Select
                           value={selectedCountry}
                           name="country"
                           onChange={(selectedOption) => {
                              handleCountryChange(selectedOption, values);
                           }}
                           options={countriesList}
                           values={values.country}
                        />
                        {errors.country && touched.country ? (
                           <div className="error-message">{errors.country}</div>
                        ) : null}{" "}
                     </div>
                     <div className="form-group fv-plugins-icon-container">
                        <label>Address*</label>
                        <input
                           className={`form-control h-auto py-3 px-6`}
                           type="text"
                           name="address"
                           onChange={handleChange}
                           values={values.address}
                        />
                        {errors.address && touched.address ? (
                           <div className="error-message">{errors.address}</div>
                        ) : null}{" "}
                     </div>
                     <div className="form-group fv-plugins-icon-container">
                        <label>Sectors*</label>
                        <Select
                           value={selectedSectors}
                           name="sector"
                           isMulti
                           onChange={(selectedSectors) => {
                              handleSectorChange(selectedSectors, values);
                           }}
                           options={sectorsList}
                           values={values.sector}
                        />
                        {errors.sector && touched.sector ? (
                           <div className="error-message">{errors.sector}</div>
                        ) : null}{" "}
                     </div>
                     <div className="form-group fv-plugins-icon-container">
                        <label>Organization size*</label>
                        <Select
                           value={selectedSize}
                           name="size"
                           onChange={(selectedSize) => {
                              handleSizeChange(selectedSize, values);
                           }}
                           options={sizesList}
                           values={values.size}
                        />
                        {errors.size && touched.size ? (
                           <div className="error-message">{errors.size}</div>
                        ) : null}{" "}
                     </div>
                     <div className="form-group fv-plugins-icon-container">
                        <label>Organization type*</label>
                        <Select
                           value={selectedType}
                           name="size"
                           onChange={(selectedType) => {
                              handleTypeChange(selectedType, values);
                           }}
                           options={organizationTypes}
                           values={values.type}
                        />
                        {errors.size && touched.size ? (
                           <div className="error-message">{errors.size}</div>
                        ) : null}{" "}
                     </div>
                     <div className="form-group fv-plugins-icon-container">
                        <label>Organization email</label>
                        <input
                           className={`form-control h-auto py-3 px-6`}
                           type="email"
                           name="email"
                           onChange={handleChange}
                           values={values.email}
                        />
                        {errors.email && touched.email ? (
                           <div className="error-message">{errors.email}</div>
                        ) : null}{" "}
                     </div>
                     <div className="form-group fv-plugins-icon-container">
                        <label>Organization phone number*</label>
                        <input
                           className={`form-control h-auto py-3 px-6`}
                           type="text"
                           name="number"
                           onChange={handleChange}
                           values={values.number}
                        />
                        {errors.number && touched.number ? (
                           <div className="error-message">{errors.number}</div>
                        ) : null}{" "}
                     </div>
                     <div className="form-group fv-plugins-icon-container">
                        <label>Where your organization is operating?*</label>
                        <Select
                           value={selectedOperatingCountries}
                           name="operation"
                           isMulti
                           onChange={(selectedOperatingCountries) => {
                              handleOperatingCountries(selectedOperatingCountries, values);
                           }}
                           options={countriesList}
                           values={values.operation}
                        />
                        {errors.operation && touched.operation ? (
                           <div className="error-message">{errors.operation}</div>
                        ) : null}{" "}
                        {/* <Select
                        isMulti
                        placeholder="Country Name"
                        options={countriesList?.map((item) => ({
                           label: item.name,
                           value: item.id,
                           code: item.code
                        }))}
                        onChange={handleChange}
                        id="operation"
                        name="operation"
                        component="operation"
                     />
                     {errors.operation && touched.operation ? (
                        <div className="error-message">{errors.operation}</div>
                     ) : null}{" "} */}
                     </div>
                     <div className="d-flex justify-content-between"></div>
                     <div className="">
                        <button
                           type="submit"
                           className="btn btn-next font-weight-bold px-12 py-4 my-3 mx-4"
                        >
                           Next
                        </button>
                     </div>
                  </form>
               )}
            </Formik>
         </div>
      </>
   );
}

export default RegistrationStep2;
