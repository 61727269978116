import React from "react";
import { Spin } from "antd";
import "./index.scss";

const BlockUI = ({ isLoading, title }) => {
   if (!isLoading) {
      return "";
   } else {
      return (
         <div className="block-ui-container">
            <div className="block-ui-overlay" />
            <div className="block-ui-message-container">
               <div className="block-ui-message">
                  <div>
                     <Spin size="large" />
                  </div>
               </div>
            </div>
         </div>
      );
   }
};

BlockUI.defaultProps = {
   blocking: true,
   title: "Loading"
};

export default BlockUI;
