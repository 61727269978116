/* eslint-disable */

import React from "react";
import style from "./AboutLocalPartners.module.css";
import LocalPartnersImg from "../assets/local-partners.svg";
import WhiteTick from "../assets/white-tick.svg";
import OrganizationalAssessmentImg from "../assets/organizationalAssessments.svg";
import CapacityImg from "../assets/Capacity.svg";
import Check1Img from "../assets/check-1.svg";
import Check2Img from "../assets/check-2.svg";
import Check3Img from "../assets/check-3.svg";
import Check4Img from "../assets/check-4.svg";
import { BlueTick } from "../../../../assets/icons";

const LocalPartners = () => {
   return (
      <div className="container">
         <div className="row">
            <div className={style.aboutKapSurveySectionStyle}>
               <div className={style.content}></div>
               <div className={style.content}>
                  <h1 className={style.h1}>Do more with KAPorg</h1>
               </div>
               <div className={style.firstSection}>
                  <div className={`${style.secondCard} + "card"`}>
                     <div className={style.secondInnerCard}>
                        <div className={`${style.sectionPaddingInnerCard} + col-lg-4`}>
                           <div className="d-flex flex-column" style={{ gap: "18px" }}>
                              <h3 className={style.heading3Style} style={{ margin: 0 }}>
                                 Identify local partners
                              </h3>
                              <div className={style.secondInnerCardContent}>
                                 <img src={WhiteTick} alt="tick" />

                                 <span
                                    style={{
                                       marginLeft: "1rem",
                                       fontSize: "16px",
                                       fontFamily: "Lato"
                                    }}
                                 >
                                    Meet localization targets by identifying local entities to
                                    partner with.
                                 </span>
                              </div>
                              <div className={style.secondInnerCardContent}>
                                 <img src={WhiteTick} alt="tick" />

                                 <span
                                    style={{
                                       marginLeft: "1rem",
                                       fontSize: "16px",
                                       fontFamily: "Lato"
                                    }}
                                 >
                                    View interactive dashboards to gain insight into the capacities
                                    and strengths of local partners.
                                 </span>
                              </div>
                              <div className={style.secondInnerCardContent}>
                                 <img src={WhiteTick} alt="tick" />

                                 <span
                                    style={{
                                       marginLeft: "1rem",
                                       fontSize: "16px",
                                       fontFamily: "Lato"
                                    }}
                                 >
                                    Compare and communicate with local partners/NGOs to find the
                                    best fit for your projects.
                                 </span>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-8">
                           <img
                              src={LocalPartnersImg}
                              alt="task management"
                              className={style.taskManagementImg}
                           />
                        </div>
                     </div>
                  </div>
               </div>

               <div className={style.secondSection}>
                  <div
                     className={`${style.secondSectionCard} + "card"`}
                     style={{ position: "relative" }}
                  >
                     <div
                        className={`${style.secondSectionCardCheckImg} justify-content-center justify-content-md-end`}
                     >
                        <div>
                           <img src={Check1Img} alt="" />
                        </div>
                        <div style={{ position: "relative", top: "2rem" }}>
                           <img src={Check2Img} alt="" />
                        </div>
                        <div style={{ position: "relative", top: "0rem" }}>
                           <img src={Check3Img} alt="" />
                        </div>
                        <div style={{ position: "relative", top: "2.5rem" }}>
                           <img src={Check4Img} alt="" />
                        </div>
                     </div>

                     <div className="">
                        <div className={style.sectionPadding}>
                           <div className="d-flex flex-column flex-lg-row align-items-lg-center ">
                              <div className="d-flex flex-column" style={{ gap: "18px" }}>
                                 <h3
                                    className={`${style.heading3Style} `}
                                    // style={{ position: "absolute", top: "6rem" }}
                                 >
                                    Manage and implement customized organizational assessments
                                 </h3>

                                 <div className={style.secondSectionInnerCardContent}>
                                    <img src={BlueTick} alt="tick" />

                                    <span
                                       style={{
                                          marginLeft: "1rem",
                                          fontSize: "16px",
                                          fontFamily: "Lato"
                                       }}
                                    >
                                       Administration
                                    </span>
                                 </div>
                                 <div className={style.secondSectionInnerCardContent}>
                                    <img src={BlueTick} alt="tick" />

                                    <span
                                       style={{
                                          marginLeft: "1rem",
                                          fontSize: "16px",
                                          fontFamily: "Lato"
                                       }}
                                    >
                                       Human resources
                                    </span>
                                 </div>
                                 <div className={style.secondSectionInnerCardContent}>
                                    <img src={BlueTick} alt="tick" />

                                    <span
                                       style={{
                                          marginLeft: "1rem",
                                          fontSize: "16px",
                                          fontFamily: "Lato"
                                       }}
                                    >
                                       Financial Management
                                    </span>
                                 </div>
                                 <div className={style.secondSectionInnerCardContent}>
                                    <img src={BlueTick} alt="tick" />

                                    <span
                                       style={{
                                          marginLeft: "1rem",
                                          fontSize: "16px",
                                          fontFamily: "Lato"
                                       }}
                                    >
                                       Governance
                                    </span>
                                 </div>
                              </div>

                              <img
                                 src={OrganizationalAssessmentImg}
                                 alt="beneficieries mockup"
                                 className={style.beneficieriesImg}
                              />
                           </div>
                        </div>
                     </div>
                  </div>

                  <div
                     className={`${style.secondSectionSecondCard} card `}
                     style={{ position: "relative" }}
                  >
                     <div
                        className={`${style.secondSectionSecondInnerCard} d-flex flex-column flex-lg-row `}
                        // style={{ minWidth: "405px", flexShrink: 1 }}
                     >
                        <div
                           className={`${style.sectionPadding2} col-lg-8 `} //48px 0  20px 57px
                           // style={{ paddingTop: "48px" }}
                        >
                           <h3 className={style.heading3Style}>
                              Assess and build the capacity of local partners
                           </h3>
                           <div className="d-flex flex-column" style={{ gap: "18px" }}>
                              <div className={style.secondSectionInnerCardContent}>
                                 <img src={BlueTick} alt="tick" />
                                 <span
                                    style={{
                                       marginLeft: "1rem",
                                       fontFamily: "Lato",
                                       fontSize: "16px"
                                    }}
                                 >
                                    Identify areas of improvement.
                                 </span>
                              </div>
                              <div className={style.secondSectionInnerCardContent}>
                                 <img src={BlueTick} alt="tick" />
                                 <span
                                    style={{
                                       marginLeft: "1rem",
                                       fontFamily: "Lato",
                                       fontSize: "16px"
                                    }}
                                 >
                                    Use verified data about implementing partners to guide your
                                    recommendations.
                                 </span>
                              </div>
                              <div className={style.secondSectionInnerCardContent}>
                                 <img src={BlueTick} alt="tick" />
                                 <span
                                    style={{
                                       marginLeft: "1rem",
                                       fontFamily: "Lato",
                                       fontSize: "16px"
                                    }}
                                 >
                                    Develop customized capacity-building plans as part of your
                                    activities.
                                 </span>
                              </div>
                              <div className={style.secondSectionInnerCardContent}>
                                 <img src={BlueTick} alt="tick" />
                                 <span
                                    style={{
                                       marginLeft: "1rem",
                                       fontFamily: "Lato",
                                       fontSize: "16px"
                                    }}
                                 >
                                    Become donor-ready and compliant.
                                 </span>
                              </div>
                           </div>
                        </div>
                        <div
                           className="col-lg-4"
                           style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                              paddingRight: 0
                           }}
                        >
                           <img
                              src={CapacityImg}
                              alt="data analysis"
                              className={style.dataAnalysisImg}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export default LocalPartners;
