import React from "react";
import { Link, useLocation } from "react-router-dom";
import { KapOrgLogo } from "../../../../app/assets/icons";
import AuthService from "../../../../app/base/services/authentication.service";
import { UserRolesEnum } from "../../../../app/pages/auth/userRoles.enum";

export function Brand() {
   const isSuperAdmin = AuthService.isAuthorized([UserRolesEnum.SuperAdmin]);
   const location = useLocation();
   const isWorkspacePath = location.pathname === "/admin/workspace";
   return (
      // <div style={{ display: isSuperAdmin ? "unset" : "none" }}>
      <div>
         {/* begin::Brand */}
         <div
            className={`aside-brand d-flex flex-column align-items-center flex-column-auto py-5 py-lg-12`}
         >
            {/* begin::Logo */}
            {isWorkspacePath && !isSuperAdmin ? null : (
               <Link to="/admin/workspace" className="brand-logo">
                  <img alt="logo" src={KapOrgLogo} className="max-h-44px max-w-106px" />
               </Link>
            )}
            {/* end::Logo */}
         </div>
         {/* end::Brand */}
      </div>
   );
}
