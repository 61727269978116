import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import objectPath from "object-path";
import { Brand } from "../brand/Brand";
import { AdminAsideMenu } from "./aside-menu/AdminAsideMenu";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import AuthService from "../../../../app/base/services/authentication.service";
import { UserRolesEnum } from "../../../../app/pages/auth/userRoles.enum";

export function AdminAside() {
   const location = useLocation();
   const uiService = useHtmlClassService();
   const isSuperAdmin = AuthService.isAuthorized([UserRolesEnum.SuperAdmin]);
   const isOrganizationManagement = location.pathname.includes("organization-management");

   const layoutProps = useMemo(() => {
      return {
         disableScroll: objectPath.get(uiService.config, "aside.menu.dropdown") === "true" || false,
         isAdminLayout: true,
         asideClassesFromConfig: uiService.getClasses("aside", true),
         disableAsideSelfDisplay: objectPath.get(uiService.config, "aside.self.display") === false,
         headerLogo: uiService.getLogo()
      };
   }, [uiService]);

   return (
      <div className={`${isSuperAdmin ? "aside-secondary-enabled" : "aside-third-disabled"}`}>
         {/* begin::Aside */}
         <div
            id="kt_aside"
            className={`aside aside-left aside-admin ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto admin-aside`}
         >
            <Brand />

            {/* begin::Aside Menu */}
            <div
               id="kt_aside_menu_wrapper"
               className="aside-menu-left aside-menu-wrapper flex-column-fluid"
            >
               {layoutProps.disableAsideSelfDisplay && (
                  <>
                     {/* begin::Header Logo */}
                     <div className="header-logo">
                        <Link to="">
                           <img alt="logo" src={layoutProps.headerLogo} />
                        </Link>
                     </div>
                     {/* end::Header Logo */}
                  </>
               )}
               <AdminAsideMenu disableScroll={layoutProps.disableScroll} />
            </div>
            {/* end::Aside Menu */}
         </div>
         {/* end::Aside */}
      </div>
   );
}
