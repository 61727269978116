import React from "react";
import { Link } from "react-router-dom";
import { TickSuccess } from "../../../assets/icons";

function RecoveredPassword() {
   return (
      // <div
      //    className="pt-md-20 pr-md-10 pb-md-20 pl-md-10 custom-div"
      //    style={{
      //       margin: "0 !important",
      //       height: "auto",
      //       padding: "30px 20px",
      //       backgroundColor: "#fff"
      //    }}
      // ></div>
      <div
         className="pt-md-20 pr-md-10 pb-md-20 pl-md-10 custom-div"
         style={{
            margin: "0 !important",
            padding: "30px 20px",
            height: "auto",
            backgroundColor: "#fff",
            maxHeight: "420px"
         }}
      >
         <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <div className="d-flex justify-content-center">
               <img src={TickSuccess} alt="email confirmation icon" />
            </div>
            <div className="d-flex flex-column align-items-center">
               <p
                  style={{
                     color: "#4158CF",
                     fontWeight: 600,
                     fontSize: "26px",
                     textAlign: "center",
                     margin: "0 0 8px "
                  }}
               >
                  Well done!
               </p>
               <div
                  className="d-flex flex-column align-items-center"
                  style={{ alignSelf: "stretch", gap: "30px", textAlign: "center" }}
               >
                  <p style={{ color: "#555", fontSize: "16px", margin: "0px" }}>
                     You have successfully changed your password. <br />
                     Please use it when logging in.
                  </p>
               </div>
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
               <Link to="/">
                  <button
                     type="button"
                     className={`btn sign-btn`}
                     style={{ fontWeight: 500, width: "unset" }}
                  >
                     Log in to continue
                  </button>
               </Link>
            </div>
         </div>
      </div>
   );
}

export default RecoveredPassword;
