import React from "react";
import "./styles.scss";
import TurkeyPartners from "../assets/turkey-partners.svg";
import BlueTick from "../assets/Blue-tick.svg";
const OrganizationalDevelopment = () => {
   return (
      <div className="mainContainer-turkey">
         <div className="container">
            <div className="mainContainer-wrapper">
               <div className="main-turkey-image">
                  <img
                     src={TurkeyPartners}
                     alt="People"
                     className="img-fluid"
                     style={{
                        height: "100%",
                        maxWidth: "100%",
                        objectFit: "cover",
                        borderRadius: "16px"
                     }}
                  />
               </div>
               <div className="text-diff" style={{ gap: "32px" }}>
                  <div className="d-flex flex-column" style={{ gap: "24px" }}>
                     <span
                        style={{
                           fontSize: "32px",
                           fontWeight: 600,
                           color: "#003DAA",
                           margin: 0
                        }}
                     >
                        TürkiyeKAPorg is Your Partner in Driving Social Change
                     </span>
                     <span
                        style={{
                           fontSize: "16px",
                           color: "#3A3737",
                           letterSpacing: "0.32px"
                        }}
                     >
                        KAPorg is a groundbreaking platform accelerating localization efforts in
                        humanitarian aid and development by uniting key stakeholders and empowering
                        organizations to accomplish their missions.
                     </span>
                     <div className="d-flex flex-column" style={{ gap: "24px" }}>
                        {/* <ul
                  className="d-flex flex-column"
                  style={{ padding: "0 0 0 25px" }}
                > */}
                        <div className="d-flex align-items-center" style={{ gap: "12px" }}>
                           <img src={BlueTick} alt="tick" />
                           <span
                              style={{
                                 color: "#3A3737",
                                 fontWeight: 600,
                                 fontSize: "18px",
                                 letterSpacing: "0.36px"
                              }}
                           >
                              Streamline localization efforts
                           </span>
                        </div>
                        <div className="d-flex align-items-center" style={{ gap: "12px" }}>
                           <img src={BlueTick} alt="tick" />
                           <span
                              style={{
                                 color: "#3A3737",
                                 fontWeight: 600,
                                 fontSize: "18px",
                                 letterSpacing: "0.36px"
                              }}
                           >
                              Expand your partner network
                           </span>
                        </div>
                        <div className="d-flex align-items-center" style={{ gap: "12px" }}>
                           <img src={BlueTick} alt="tick" />
                           <span
                              style={{
                                 color: "#3A3737",
                                 fontWeight: 600,
                                 fontSize: "18px",
                                 letterSpacing: "0.36px"
                              }}
                           >
                              Strengthen organizational capacity
                           </span>
                        </div>
                        {/* </ul> */}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default OrganizationalDevelopment;
