/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import objectPath from "object-path";
import React, { useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader";
import { MenuItem, Popover } from "@material-ui/core";
import AuthService from "../../../../app/base/services/authentication.service";
import { UserRolesEnum } from "../../../../app/pages/auth/userRoles.enum";
import { BellRingSVG, ChevronDown, GoBack } from "../../../../app/assets/icons";
import { Avatar, Badge } from "antd";
import { NotificationContext } from "./notificationContext/NotificationContext";
import NotificationPopover from "./components/NotificationPopover";
import {
   accountSummaryBlue,
   loginInfoBlue,
   logoutBlue,
   paymentInfoBlue,
   profileInfoBlue
} from "../../../../app/assets/icons";
import "./style.css";

export function SubHeader() {
   const uiService = useHtmlClassService();
   const navigate = useNavigate();
   const location = useLocation();
   const subheader = useSubheader();
   const [anchorEl, setAnchorEl] = useState(null);
   const [toggleBell, setToggleBell] = useState(null);
   const user = AuthService?.getUser();
   const isDonor = AuthService.isAuthorized([UserRolesEnum.Donor]);
   const isSuperAdmin = AuthService.isAuthorized([UserRolesEnum.SuperAdmin]);
   const isNgo = AuthService.isAuthorized([UserRolesEnum.NationalOrganization]);
   const savedRecord = localStorage.getItem("selectedRecord");
   const isDetailPage = location.pathname.includes("/admin/organization/detail");
   const isViewActionPlan =
      location.pathname.includes("/view") && location.pathname.includes("action-plan");
   const isWorkspaceDetail =
      location.pathname.includes("/workspace/") || location.pathname.includes("/help-desk");
   const isEditSurveyDetail =
      location.pathname.includes("/admin/survey/edit-survey-details") ||
      location.pathname.includes("/admin/survey/add") ||
      location.pathname.includes("/admin/survey/import") ||
      location.pathname.includes("/admin/survey/edit/") ||
      location.pathname.includes("/admin/survey/test/");
   const isEditAssessment =
      location.pathname.includes("/admin/assessment/edit") ||
      location.pathname.includes("/admin/assessment/add");
   const isSelfAssessment =
      location.pathname.includes("/workspace/assessment/self-assessment/") &&
      location.pathname.includes("/survey/");
   const isStaffActionPlan =
      location.pathname.includes("/workspace/results/action-plan-member/") &&
      location.pathname.includes("/survey/");
   const isManagerActionPlan =
      location.pathname.includes("/workspace/results/action-plan-manager/") &&
      location.pathname.includes("/survey/");
   const isORGManagementInDonor =
      location.pathname.includes("/workspace/organization-management/details/") && isDonor;
   const isSuperAdminManagement =
      location.pathname.includes("/workspace/organization-management/details/") && isSuperAdmin;
   const isGuidedAssessmentInExpert =
      location.pathname.includes("/workspace/assessment/guided-assessment/") &&
      location.pathname.includes("/survey/");
   const isGuidedAssessmentExpert = location.pathname.includes(
      `/workspace/assessment/guided-assessment/surveys/`
   );
   const isConsultantORGView = AuthService.isAuthorized([UserRolesEnum.Consultant]) &&
      location?.pathname?.includes('/workspace/results/action-plan/organization/');

   const isAdminTickets =
      location?.pathname?.includes("/admin/help-desk/list") ||
      location?.pathname?.includes("/admin/help-desk/details/");
      
      const isSettings =
      location?.pathname?.includes("/admin/account-summary") ||
      location?.pathname?.includes("/admin/profile-information") ||
      location?.pathname?.includes("/admin/login-information") ||
      location?.pathname?.includes("/admin/payment-information");

      const selectedAssessmentId = localStorage.getItem("selectedAssessmentId");
      const selectedSectionId = localStorage.getItem('selectedSectionId');
      const selectedSurveyId = localStorage.getItem('selectedSurveyId');

   const logout = () => {
      AuthService.logout();
      navigate("/auth/login");
   };

   function handleClick(event) {
      setAnchorEl(event.currentTarget);
   }

   function handleClose() {
      setAnchorEl(null);
   }

   function handleToggleBell(event) {
      setToggleBell(event.currentTarget);
   }

   function handleCloseBell() {
      setToggleBell(null);
   }

   const layoutProps = useMemo(() => {
      return {
         config: uiService.config,
         subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
         subheaderMobileToggle: objectPath.get(uiService.config, "subheader.mobile-toggle"),
         subheaderCssClasses: uiService.getClasses("subheader", true),
         subheaderContainerCssClasses: uiService.getClasses("subheader_container", true)
      };
   }, [uiService]);

   useLayoutEffect(() => {
      const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
      const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
      const breadcrumbs =
         aside && aside.breadcrumbs.length > 0 ? aside.breadcrumbs : header.breadcrumbs;
      subheader.setBreadcrumbs(breadcrumbs);
      subheader.setTitle(
         aside && aside.title && aside.title.length > 0 ? aside.title : header.title
      );

      // eslint-disable-next-line
   }, [location.pathname]);

   useEffect(() => {
      // Call setUser whenever the user changes
      setUser(AuthService?.getUser());
   }, []);

   const {
      unreadCount,
      notifications,
      markNotificationAsRead,
      markAllNotificationsAsRead,
      setUser,
      pageNumber,
      loadingMarkAllRead,
      loadingMarkOneRead
   } = useContext(NotificationContext);

   // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
   useEffect(() => {}, [subheader]);

   return (
      <div
         id="kt_subheader"
         className={`subheader py-2 py-lg-4 ${layoutProps.subheaderCssClasses}`}
         style={{ backgroundColor: "#fff" }}
      >
         <div
            className={`full-width d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
         >
            <div className="d-flex align-items-center flex-wrap mr-1">
               {layoutProps.subheaderMobileToggle && (
                  <button
                     className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                     id="kt_subheader_mobile_toggle"
                  >
                     <span />
                  </button>
               )}
            </div>

            <div
               className="pl-4 pl-md-10 pr-4 pr-md-10 d-flex w-100 align-items-center justify-content-between flex-wrap cursor-pointer"
               style={{
                  gap: "10px"
                  // paddingLeft: "40px",
               }}
            >
               {isViewActionPlan ||
               isWorkspaceDetail ||
               isEditSurveyDetail ||
               isEditAssessment ||
               isSelfAssessment ||
               isGuidedAssessmentInExpert ||
               isORGManagementInDonor ||
               isSuperAdminManagement ||
               isGuidedAssessmentExpert ||
               isStaffActionPlan ||
               isManagerActionPlan ||
               isSettings ? (
                  // isAdminTickets
                  <Link
                     to={
                        isViewActionPlan
                           ? `/workspace/results/action-plan/${selectedAssessmentId}/`
                           : isEditSurveyDetail
                           ? `/admin/survey?page=${pageNumber}`
                           : isConsultantORGView
                           ? `/workspace/results/action-plan/${selectedAssessmentId}/view/${selectedSurveyId}/${selectedSectionId}`
                           : isEditAssessment
                           ? `/admin/assessment?page=${pageNumber}`
                           : isSelfAssessment
                           ? "/workspace/assessment/self-assessment/"
                           : isStaffActionPlan
                           ? "/workspace/results/action-plan-member/"
                           : isManagerActionPlan
                           ? "/workspace/results/action-plan-manager/"
                           : isORGManagementInDonor
                           ? `/workspace/organization-management?page=${pageNumber}`
                           : isSuperAdminManagement
                           ? `/workspace/organization-management?page=${pageNumber}`
                           : isGuidedAssessmentInExpert
                           ? `/workspace/assessment/guided-assessment/surveys/${savedRecord}`
                           : isGuidedAssessmentExpert
                           ? `/workspace/assessment/guided-assessment/list/`
                           : isAdminTickets ||
                             isWorkspaceDetail ||
                             isEditSurveyDetail ||
                             isEditAssessment ||
                             isSuperAdminManagement
                           ? ""
                           : `/admin/workspace?page=${pageNumber}`
                     }
                     className="d-flex align-items-center"
                     style={{ color: "#777777", fontSize: "14px", fontWeight: 500 }}
                  >
                     {!isAdminTickets && !isORGManagementInDonor && !isSuperAdminManagement && (
                        <img src={GoBack} alt="GoBack" style={{ marginRight: "10px" }} />
                     )}
                     {isDetailPage || isORGManagementInDonor || isSuperAdminManagement
                        ? "Go back to organizations"
                        : isViewActionPlan
                        ? "Go back to action plan"
                        : isEditSurveyDetail
                        ? "Go back to surveys"
                        : isConsultantORGView
                        ? 'Go back to action plan section'
                        : isEditAssessment
                        ? "Go back to assessment"
                        : isSelfAssessment
                        ? "Go back to self assessment"
                        : isStaffActionPlan
                        ? "Go back to Assigned surveys list"
                        : isManagerActionPlan
                        ? "Go back to Pending survey list"
                        : isGuidedAssessmentInExpert
                        ? "Go back to guided assessment"
                        : isGuidedAssessmentExpert
                        ? "Go back to guided assessment list"
                        : isAdminTickets
                        ? ""
                        : "Go back to workspaces"}
                  </Link>
               ) : (
                  <div></div>
               )}
               <div className="subheader-rigth d-flex align-items-center" style={{ gap: "20px" }}>
                  {AuthService?.isAuthorized([
                     UserRolesEnum.SuperAdmin,
                     UserRolesEnum.Manager,
                     UserRolesEnum.Consultant,
                     UserRolesEnum.Staff,
                     UserRolesEnum.NationalOrganization
                  ]) && (
                     <>
                        <Badge count={unreadCount}>
                           <div onClick={handleToggleBell} className="bell">
                              <img
                                 src={BellRingSVG}
                                 alt="link"
                                 aria-controls="popover"
                                 aria-haspopup="true"
                                 data-toggle="modal"
                                 data-target="#kt_chat_modal"
                              />
                           </div>
                        </Badge>

                        <NotificationPopover
                           toggleBell={toggleBell}
                           handleCloseBell={handleCloseBell}
                           notifications={notifications}
                           markNotificationAsRead={markNotificationAsRead}
                           markAllNotificationsAsRead={markAllNotificationsAsRead}
                           loadingMarkAllRead={loadingMarkAllRead}
                           loadingMarkOneRead={loadingMarkOneRead}
                        />
                     </>
                  )}
                  <div onClick={handleClick} style={{ cursor: "pointer" }}>
                     <div className="username">
                        <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
                           <Avatar className="avatar">
                              {user?.fullName.charAt(0).toUpperCase()}
                           </Avatar>
                           <div
                              style={{
                                 display: "flex",
                                 flexDirection: "column",
                                 maxWidth: "125px"
                              }}
                           >
                              <div
                                 style={{
                                    // maxWidth: "65px",
                                    display: "inline-block"
                                 }}
                              >
                                 <p className="fullnameParagraph">{user?.fullName}</p>
                              </div>
                              <div
                                 style={{
                                    // maxWidth: "65px",
                                    display: "inline-block"
                                 }}
                              >
                                 <p className="roleParagraph">
                                    {user?.roles[0]?.name === "National Organization"
                                       ? "Org"
                                       : user?.roles[0]?.name}
                                 </p>
                              </div>
                           </div>
                        </div>
                        <img
                           src={ChevronDown}
                           alt="ChevronDown"
                           aria-controls="simple-popover"
                           aria-haspopup="true"
                           data-toggle="modal"
                           data-target="#kt_chat_modal"
                        />
                     </div>
                  </div>

                  <Popover
                     id="simple-popover"
                     className="popOver_account"
                     open={Boolean(anchorEl)}
                     anchorEl={anchorEl}
                     onClose={handleClose}
                     style={{
                        position: "fixed",
                        top: "50px"
                        // left: "-40px"
                     }}
                     PaperProps={{
                        style: {
                           borderRadius: "8px",
                           width: "220px"
                        }
                     }}
                  >
                     {isSuperAdmin || isNgo ? (
                        <Link to="/admin/account-summary">
                           <MenuItem>
                              <img src={accountSummaryBlue} alt="account summary" />
                              <span>Account summary</span>
                           </MenuItem>
                        </Link>
                     ) : null}
                     <Link to="/admin/profile-information">
                        <MenuItem>
                           <img src={profileInfoBlue} alt="profile info" />
                           <span>Profile information</span>
                        </MenuItem>
                     </Link>
                     <Link to="/admin/login-information">
                        <MenuItem>
                           <img src={loginInfoBlue} alt="login info" />
                           <span>Login information</span>
                        </MenuItem>
                     </Link>
                     {isSuperAdmin && (
                        <Link to="/admin/payment-information">
                           <MenuItem>
                              <img src={paymentInfoBlue} alt="payment info" />
                              <span>Payment information</span>
                           </MenuItem>
                        </Link>
                     )}
                     <div className="devider"></div>
                     <Link to="/auth">
                        <MenuItem onClick={logout}>
                           <img src={logoutBlue} alt="log out" />
                           <span>Log out</span>
                        </MenuItem>
                     </Link>
                  </Popover>
               </div>
            </div>
         </div>
      </div>
   );
}
