/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import AuthService from "../../../../../app/base/services/authentication.service";
import { LogoutIcon } from "../../../../../app/assets/icons";

export function AdminAsideMenuList({ data, layoutProps }) {
   const [activeMenuItem, setActiveMenuItem] = useState(null);
   const location = useLocation();
   const navigate = useNavigate();

   const handleMenuClick = (itemId) => {
      setActiveMenuItem(itemId);
   };

   useEffect(() => {
      const currentRoute = location.pathname;
      const isDetailsTicketActive = currentRoute.includes("/admin/help-desk/details/");
      const matchingMenuItem = data.find((menuItem) => currentRoute.startsWith(menuItem.url));

      if (isDetailsTicketActive) {
         setActiveMenuItem("admin_page_ticket");
      } else if (matchingMenuItem) {
         setActiveMenuItem(matchingMenuItem.id);
      }
   }, [location.pathname]);

   const getMenuItemActive = (url, hasSubmenu = false, id) => {
      return checkIsActive(location, url) ||
         (id === "admin_page_ticket" && location?.pathname?.includes("/admin/help-desk/"))
         ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted`
         : "";
   };

   const logout = () => {
      AuthService.logout();
      navigate("/auth/login");
   };

   return (
      <>
         {/* begin::Menu Nav */}
         <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            {data.length !== 0
               ? data.map((menuItem) => {
                    if (menuItem.type === "divider") {
                       return (
                          AuthService.isAuthorized(menuItem.role) && (
                             <li key={menuItem.id} className="menu-section ">
                                <h4 className="menu-text">{menuItem.title}</h4>
                                <i className="menu-icon flaticon-more-v2"></i>
                             </li>
                          )
                       );
                    } else {
                       return (
                          AuthService.isAuthorized(menuItem.role) && (
                             <li
                                key={menuItem.id}
                                className={`menu-item ${menuItem.subMenus?.length > 0 &&
                                   "menu-item-submenu "} ${getMenuItemActive(
                                   menuItem.url,
                                   menuItem.children?.length > 0 ? true : false,
                                   menuItem.id
                                )}`}
                                aria-haspopup="true"
                             >
                                <NavLink
                                   className={`menu-link ${menuItem.isDisabled &&
                                      " disabled"} ${menuItem.subMenus?.length > 0 &&
                                      "menu-toggle"}`}
                                   to={menuItem.url}
                                   onClick={() => handleMenuClick(menuItem.id)}
                                >
                                   <span>
                                      <img
                                         className="active"
                                         src={menuItem.icons.active}
                                         alt="icon"
                                      />
                                      <img
                                         className="inactive"
                                         src={menuItem.icons.inactive}
                                         alt="icon"
                                      />
                                   </span>
                                   <span className="menu-text">{menuItem.title}</span>
                                   {menuItem.subMenus?.length > 0 && <i className="menu-arrow" />}
                                </NavLink>

                                <div className="menu-submenu ">
                                   <i className="menu-arrow" />
                                   <ul className="menu-subnav">
                                      {/*begin::2 Level*/}
                                      {menuItem.subMenus.length > 0 &&
                                         menuItem.subMenus.map((subMenu) => {
                                            return (
                                               <li
                                                  key={subMenu.id}
                                                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                                                     subMenu.url,
                                                     subMenu.children?.length > 0 ? true : false,
                                                     menuItem.id
                                                  )}`}
                                                  aria-haspopup="true"
                                                  data-menu-toggle="hover"
                                               >
                                                  <NavLink
                                                     className={`menu-link menu-toggle ${menuItem.isDisabled &&
                                                        " disabled"}`}
                                                     to={subMenu.url}
                                                  >
                                                     <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                     </i>
                                                     <span className="menu-text">
                                                        {subMenu.title}
                                                     </span>
                                                     {subMenu.children?.length > 0 && (
                                                        <i className="menu-arrow" />
                                                     )}
                                                  </NavLink>
                                                  {subMenu.children?.length > 0 && (
                                                     <div className="menu-submenu ">
                                                        <i className="menu-arrow" />
                                                        <ul className="menu-subnav">
                                                           {/*begin::3 Level*/}
                                                           {subMenu.children?.map((childItem) => {
                                                              return (
                                                                 <li
                                                                    key={childItem.id}
                                                                    className={`menu-item  ${getMenuItemActive(
                                                                       childItem.url
                                                                    )}`}
                                                                    aria-haspopup="true"
                                                                 >
                                                                    <NavLink
                                                                       className={`menu-link ${childItem.isDisabled &&
                                                                          " disabled"}`}
                                                                       to={childItem.url}
                                                                    >
                                                                       <i className="menu-bullet menu-bullet-dot">
                                                                          <span />
                                                                       </i>
                                                                       <span className="menu-text">
                                                                          {childItem.title}
                                                                       </span>
                                                                    </NavLink>
                                                                 </li>
                                                              );
                                                           })}
                                                        </ul>
                                                     </div>
                                                  )}
                                               </li>
                                            );
                                         })}
                                      {/*end::2 Level*/}
                                   </ul>
                                </div>
                             </li>
                          )
                       );
                    }
                 })
               : null}

            {/*end::1 Level*/}

            {/* Components */}
            {/* begin::section */}

            {/* end:: section */}
         </ul>

         <div className="aside-footer">
            {/* <OverlayTrigger
               placement="right"
               overlay={<Tooltip id="toggle-quick-panel">Dark/Light Mode</Tooltip>}
            >
               <a
                  onClick={() => handleThemeColorChange()}
                  className="btn disabled btn-icon btn-clean btn-lg mb-1 position-relative"
                  id="kt_quick_panel_toggle"
                  data-placement="right"
                  data-container="body"
                  data-boundary="window"
               >
                  {isDarkModeOn ? (
                     <span className="svg-icon svg-icon-lg">
                        <SVG src={toAbsoluteUrl("/media/svg/custom-icons/sun.svg")} />
                     </span>
                  ) : (
                     <span className="svg-icon svg-icon-lg">
                        <SVG src={toAbsoluteUrl("/media/svg/custom-icons/moon.svg")} />
                     </span>
                  )}
               </a>
            </OverlayTrigger> */}
         </div>
         {/* end::Menu Nav */}
      </>
   );
}
