import { create } from "zustand";
export const useResultStore = create((set) => ({
   isLoading: false,
   allOrganizations: [],
   selectedAssessments: [],
   selectedSubmissionRounds: [],

   allSurveys: [],
   activeSurvey: [],
   activeSectionSurvey: [],

   setAllOrganizations: (payload) => {
      set({ isLoading: true });
      set({ allOrganizations: payload });
   },

   setActiveAssessment: (payload) => {
      set({ selectedAssessments: payload });
   },

   setAllSurveys: (payload) => {
      set({ allSurveys: payload });
   },
   setActiveSurvey: (payload) => {
      set({ activeSurvey: payload });
   },

   setActiveSurveySection: (payload) => {
      set({ activeSectionSurvey: payload });
   },

   setSelectedSubmissionRounds: (payload) => {
      set({ selectedSubmissionRounds: payload });
   }
}));
