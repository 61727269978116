import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { KapOrgIcon2, FailedPayment, BackIconWhiteColor } from "../../assets/icons";
import { Button } from "antd";
import "./style.scss";

const FailedPaymentInfo = () => {
   const navigate = useNavigate();

   return (
      <div
         className="full-page-background"
         style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/bg/loginbackground.svg")})`
         }}
      >
         <Link to="/" className="logo-link">
            <img alt="Logo" className="logo" src={KapOrgIcon2} />
         </Link>
         <div className="centered-box">
            <img src={FailedPayment} alt="FailedPayment" />
            <h2>Purchase failed</h2>
            <p>
               Unfortunately, your recent purchase attempt was not successful. There may have been
               an issue with payment processing or a problem with the information provided. Please
               check your payment details and try again.
            </p>
            <span>We apologize for the inconvenience and appreciate your patience.</span>
            <Button
               className="back-to-payment-info-button"
               htmlType="submit"
               style={{ width: "fit-content", color: "#fff" }}
               onClick={() => navigate("/admin/payment-information")}
            >
               <div className="button-info">
                  <img src={BackIconWhiteColor} alt="BackIconWhiteColor" />
                  <div className="back-to-payment-info-text">Go back to payment information</div>
               </div>
            </Button>
         </div>
      </div>
   );
};

export default FailedPaymentInfo;
