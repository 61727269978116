import axios from "axios";
import AuthService from "../services/authentication.service";
import Toast from "../components/Toast";

const { REACT_APP_BASE_URL, REACT_APP_FILE_MANAGEMENT } = process.env;

const https = axios.create({
   baseURL: REACT_APP_BASE_URL,
   timeout: 60000,
   headers: { "Content-Type": "application/json" }
});

export const setupInterceptor = (store) => {

   https.interceptors.request.use((config) => {
      const token = AuthService.getAccessToken();

      if (token !== null || token !== "") {
         config.headers.Authorization = `Bearer ${token}`;
      }

      config.headers.Authorization = `Bearer ${token}`;
      if (config.url && config.url.startsWith("/FileData/getFileUrlByFileKeyAndEntityId")) {
         config.baseURL = REACT_APP_FILE_MANAGEMENT;
      } else {
         config.baseURL = REACT_APP_BASE_URL;
      }

      return config;
   });

   https.interceptors.response.use(
      (response) => {
         if (!response.data.success) {
            const errMessages = response?.data?.messages?.join("");
            Toast.warning(errMessages);
         }
         return response;
      },
      (error) => {
         if (navigator.onLine) {
            handleException(error);
         } else {
            Toast.warning("Check your internet connection");
         }
         return Promise.reject(error);
      }
   );

   const handleException = async (error) => {
      const originalRequest = error.config;
  
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const refreshToken = AuthService.getRefreshToken();
          const { data } = await AuthService.refreshToken(refreshToken);
  
         //  AuthService.setAccessToken(data.accessToken);
          originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
  
          return https(originalRequest);
        } catch (err) {
          AuthService.logout();
          window.location = "/auth/login";
          return Promise.reject(err);
        }
      }
  
      // switch (error.response?.status) {
      //   case 404:
      //     window.location = "/404";
      //     break;
      //   default:
      //     window.location = "/500";
      //     break;
      // }
  
      return Promise.reject(error);
    };
};

export default https;
