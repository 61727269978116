import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import AuthService from "../../../base/services/authentication.service";

function RequestPage() {
   const { state } = useLocation();
   const dispatch = useDispatch();
   const navigate = useNavigate();

    const logout = () => {
        AuthService.logout();
        navigate("/auth/login");
    }

    useEffect(() => {
      if (!state?.fromApp) {
         return navigate("/auth/login");
      }
   }, [state, navigate]);

   return (
      <>
         <div className="pending-approval-page mb-10 mb-lg-2 text-center">
            <h2 className="font-size-h2 mt-30 text-center request-title">
               Your application is being reviewed. <br />
               You will receive a confirmation via email shortly.
            </h2>
            <div className="mt-20">
               <img src={toAbsoluteUrl("/media/bg/Request.png")} alt="frame" />
            </div>
         </div>
         <div className="d-flex justify-content-center">
               <button
                onClick={() => logout()}
                  type="button"
                  className="btn btn-last font-weight-bold px-8 py-4 my-3 mx-4 mb-30"
               >
                  Log out
               </button>
         </div>
      </>
   );
}

export default RequestPage;
