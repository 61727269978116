import React from "react";
import { Button } from "antd";
import AssessmentIcon from "../assets/assessment-icon.svg";
import OnlinePresence from "../assets/online-presence.svg";
import StrategicPartnerships from "../assets/strategic-partnerships.svg";
import DevActions from "../assets/dev-actions.svg";
import PerformanceAnalysis from "../assets/performance-analysis.svg";
import IndependentValidation from "../assets/independent-validation.svg";

const Advantages = () => {
   const scrollToTop = () => {
      window.scrollTo({
         top: 0,
         behavior: "smooth"
      });
   };

   const spanStyle = {
      fontSize: "16px",
      color: "#3A3737",
      letterSpacing: "0.32px"
   };

   return (
      <div className="d-md-flex optimizingContainer-turkey" style={{ gap: "74px" }}>
         <div className="container">
            <div className="row">
               <div className="col-md-4 p-0">
                  <div className="d-flex flex-column" style={{ gap: "24px", width: "100%" }}>
                     <span
                        style={{
                           fontSize: "32px",
                           color: "#003DAA",
                           fontWeight: 600
                        }}
                     >
                        Optimizing Local Partner Performance Through In-Depth Assessments and
                        Capacity Development
                     </span>
                     <span style={spanStyle}>
                        Achieve Donor Readiness, Compliance, and Expanded Partnerships.
                     </span>
                     <Button
                        onClick={scrollToTop}
                        className="btn-turkey"
                        type="primary"
                        style={{
                           width: "fit-content",
                           backgroundColor: "#4158CF",
                           fontWeight: 700,
                           borderRadius: "4px",
                           padding: "12px 24px",
                           height: "100%",
                           fontSize: "16px"
                        }}
                     >
                        Get started now
                     </Button>
                  </div>
               </div>
               <div className="col-md-8">
                  <div className="gridContainer">
                     <div className="d-flex align-items-start" style={{ gap: "10px" }}>
                        <img src={AssessmentIcon} alt="organizational assessments" />
                        <div className="d-flex flex-column" style={{ gap: "5px" }}>
                           <span
                              style={{
                                 color: "#4158CF",
                                 fontSize: "18px",
                                 fontWeight: 600
                              }}
                           >
                              Develop Comprehensive Organizational Assessments
                           </span>
                           <span style={{ color: "#3A3737" }}>
                              Assess Organizational Readiness for Donor Compliance and Long-Term
                              Sustainability
                           </span>
                        </div>
                     </div>
                     <div className="d-flex align-items-start" style={{ gap: "10px" }}>
                        <img src={OnlinePresence} alt="" />
                        <div className="d-flex flex-column" style={{ gap: "5px" }}>
                           <span
                              style={{
                                 color: "#4158CF",
                                 fontSize: "18px",
                                 fontWeight: 600
                              }}
                           >
                              Build a Strong Online Presence with a Few Clicks
                           </span>
                           <span style={{ color: "#3A3737" }}>
                              Enhance your organization's global visibility and expand partnership
                              opportunities.
                           </span>
                        </div>
                     </div>
                     <div className="d-flex align-items-start" style={{ gap: "10px" }}>
                        <img src={PerformanceAnalysis} alt="" />
                        <div className="d-flex flex-column" style={{ gap: "5px" }}>
                           <span
                              style={{
                                 color: "#4158CF",
                                 fontSize: "18px",
                                 fontWeight: 600
                              }}
                           >
                              Conduct a comprehensive performance analysis to identify key areas for
                              improvement.
                           </span>
                           <span style={{ color: "#3A3737" }}>
                              Utilize validated assessment tools to conduct a thorough
                              organizational evaluation and identify core competencies and areas for
                              development.
                           </span>
                        </div>
                     </div>
                     <div className="d-flex align-items-start" style={{ gap: "10px" }}>
                        <img src={StrategicPartnerships} alt="" />
                        <div className="d-flex flex-column" style={{ gap: "5px" }}>
                           <span
                              style={{
                                 color: "#4158CF",
                                 fontSize: "18px",
                                 fontWeight: 600
                              }}
                           >
                              Forge Strategic Partnerships on a Local and Global Scale
                           </span>
                           <span style={{ color: "#3A3737" }}>
                              Identify and Cultivate Strategic Partnerships Aligned with
                              Organizational Strengths
                           </span>
                        </div>
                     </div>
                     <div className="d-flex align-items-start" style={{ gap: "10px" }}>
                        <img src={DevActions} alt="" />
                        <div className="d-flex flex-column" style={{ gap: "5px" }}>
                           <span
                              style={{
                                 color: "#4158CF",
                                 fontSize: "18px",
                                 fontWeight: 600
                              }}
                           >
                              Monitor organizational performance and growth through real-time data
                              analytics.
                           </span>
                           <span style={{ color: "#3A3737" }}>
                              Accelerate Organizational Growth with Personalized Support,
                              Data-Driven Insights and Personalized Improvement Plans. Monitor
                              organizational performance and growth through real-time data
                              visualization.
                           </span>
                        </div>
                     </div>
                     <div className="d-flex align-items-start" style={{ gap: "10px" }}>
                        <img src={IndependentValidation} alt="" />
                        <div className="d-flex flex-column" style={{ gap: "5px" }}>
                           <span
                              style={{
                                 color: "#4158CF",
                                 fontSize: "18px",
                                 fontWeight: 600
                              }}
                           >
                              Enhance organizational credibility through independent validation of
                              assessment scores by KAPorg.
                           </span>
                           <span style={{ color: "#3A3737" }}>
                              Benefit from KAPorg's independent organizational assessments for
                              unbiased insights and improvement recommendations.
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export default Advantages;
