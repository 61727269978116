import React, { useState, useEffect } from "react";
import { httpService } from "../../../base/services/httpService.service";
import { Modal } from "react-bootstrap";
import ConsultantPhoto from "../../../assets/icons/consultantPhoto.png";
import CompletedWithSuccess from "../../../assets/icons/successLogo.svg";
import { SessionService } from "../../../base/services/session.service";
import "./index.scss";
import { useLocation, useNavigate } from "react-router-dom";

function ConsultantsList() {
   const [consultantList, setConsultantList] = useState([]);
   const [selectedConsultant, setSelectedConsultant] = useState([]);
   const [showModal, setShowModal] = useState(false);
   const [showSecondModal, setSecondShowModal] = useState(false);
   const workspaceId = SessionService.getItem("workspaceID");
   const location = useLocation();
   const { state } = location; // Destructure the state object from the location
   const { isValid } = state || {}; // In case state is undefined or null, provide a default empty object
   const { assessmentId } = state || null; // In case state is undefined or null, provide a default empty object
   const { organizationId } = state || null; // In case state is undefined or null, provide a default empty object
   const navigate = useNavigate();

   useEffect(() => {
      if (isValid === undefined) {
         navigate("/teams/members");
      }
      httpService.get(`/team/getworkspaceconsultants/${workspaceId}`, (data) => {
         setConsultantList(data.data);
      });
   }, []);

   const handleSelectConsultant = (item) => {
      const elements = document.querySelectorAll(".consultant.active-consultant");
      elements.forEach((element) => {
         element.classList.remove("active-consultant");
      });

      let SelectedConsultant = document.getElementById(item.id);
      SelectedConsultant.classList.add("active-consultant");

      setSelectedConsultant(item);
      setShowModal(true);
   };

   const handleSelectThisConstultantButton = () => {
      if (selectedConsultant.id != null) {
         httpService.post(
            "guidedassesment/complete/",
            {
               assessmentId: assessmentId,
               organizationId: organizationId,
               workspaceId: workspaceId,
               consultantId: selectedConsultant.id
            },
            (res) => {
               if (res.success) {
                  setShowModal(false);

                  setSecondShowModal(true);
               }
            }
         );
      }
   };
   const handleClose = () => {
      setShowModal(false);
      setSecondShowModal(false);
      // document.querySelector('[data-target="#self-assessment"]').click();
   };

   const handleGoToAssessment = () => {
      // Navigate to the route
      navigate("/guided-assessment/list");

      // Reload the page
      // window.location.reload();
      setShowModal(false);
      setSecondShowModal(false);
   };

   return (
      <div className="consultantList p-10" style={{}}>
         <div className="">
            <h2 className="listTitle">Choose a consultant to guide your assessment </h2>
         </div>
         <div className="d-flex flex-column-fluid p-10">
            <div className="row">
               {consultantList.length > 0 &&
                  consultantList?.map((item) => {
                     return (
                        <div className="col-md-4 mb-5" key={item.id}>
                           <div
                              onClick={() => handleSelectConsultant(item)}
                              id={item.id}
                              className="card consultant"
                              style={{
                                 backgroundColor: "#F4F4F4",
                                 padding: "10px",
                                 cursor: "pointer"
                              }}
                           >
                              <img src={ConsultantPhoto} className="card-img-top" alt={item.name} />
                              <div className="card-body">
                                 <h5 className="card-title">{item.fullName}</h5>
                                 {/* <p className="card-text">{item.description}</p> */}
                              </div>
                           </div>
                        </div>
                     );
                  })}
            </div>
            <Modal
               centered
               show={showModal}
               className="consultant-detail"
               // Add necessary props for the second modal
            >
               <div onClick={handleClose} className="closeModal" style={{ background: "#036051" }}>
                  <span
                     className="d-flex justify-content-end"
                     style={{ color: "white", padding: "10px", cursor: "pointer" }}
                  >
                     X
                  </span>
               </div>
               <div className="p-10" style={{ backgroundColor: "#F1F1F1" }}>
                  <h1>Consultant Profile</h1>
                  <div className="card mt-10 mb-10 p-5">
                     <div className="d-flex justify-content-around">
                        <img alt="png" src={ConsultantPhoto} />
                        <div className="ml-10">
                           <h3>{selectedConsultant.fullName}</h3>
                           <p>Consultant</p>
                           <p>
                              Lorem ipsum dolor sit amet consectetur. Non sed pharetra erat bibendum
                              libero. Sed varius ut amet duis parturient. Tempor ac ultricies in ut
                              aliquam aliquam pellentesque. Ridiculus volutpat gravida quam mattis
                              sed proin vestibulum quis. Commodo magna duis at risus integer eget
                              lorem. Commodo nulla id arcu dignissim. Vehicula ac vitae nec in quam
                              euismod et orci consectetur. Eros convallis pellentesque semper velit.
                              Amet quam quis nibh platea quis eu. Ac amet sapien egestas est
                              tristique in. Senectus nulla suspendisse dolor ac. Ut quis pharetra
                              proin a blandit sit. Amet nam diam in.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="card mt-10 p-10">
                     <div>
                        <h1>Experience</h1>
                        <p>
                           Lorem ipsum dolor sit amet consectetur. Non sed pharetra erat bibendum
                           libero. Sed varius ut amet duis parturient. Tempor ac ultricies in ut
                           aliquam aliquam pellentesque. Ridiculus volutpat gravida quam mattis sed
                           proin vestibulum quis. Commodo magna duis at risus integer eget lorem.
                           Commodo nulla id arcu
                        </p>
                     </div>
                     <hr />
                     <div>
                        <h1>Education</h1>
                        <div className="row">
                           <div className="col-md-6">School</div>
                           <div className="col-md-6">Lorem ipsum dolor sit amet consectetur. </div>
                        </div>
                     </div>
                  </div>

                  <div className="card mt-10 p-10">
                     <div>
                        <h1>Skills</h1>
                        <div className="row">
                           <div className="col-md-3">Word</div>
                           <div className="col-md-3">Excel </div>
                           <div className="col-md-3">Figma </div>
                           <div className="col-md-3">Power Point </div>
                        </div>
                     </div>
                  </div>
                  <div className="d-flex justify-content-center mt-10">
                     <button
                        onClick={handleSelectThisConstultantButton}
                        style={{
                           background: "#036051",
                           padding: "10px 35px",
                           border: "#036051",
                           color: "white",
                           borderRadius: "8px"
                        }}
                     >
                        Select this Consultant
                     </button>
                  </div>
               </div>
            </Modal>

            <Modal
               centered
               show={showSecondModal}
               className="consultant-complete"
               // Add necessary props for the second modal
            >
               <div onClick={handleClose} className="closeModal" style={{ background: "#036051" }}>
                  <span
                     className="d-flex justify-content-end"
                     style={{ color: "white", padding: "10px", cursor: "pointer" }}
                  >
                     X
                  </span>
               </div>
               <div className="p-10" style={{ textAlign: "center" }}>
                  <img src={CompletedWithSuccess} alt="/" />
                  <h1>You have been successfully enrolled in the guided assessment!</h1>
                  <p>
                     The consultant {selectedConsultant.fullName} will get in touch with you soon to
                     start your assessments.
                  </p>
                  <button
                     onClick={handleGoToAssessment}
                     style={{
                        background: "#036051",
                        padding: "10px 35px",
                        border: "#036051",
                        color: "white",
                        borderRadius: "8px"
                     }}
                  >
                     Go to assessements
                  </button>
               </div>
            </Modal>
         </div>
      </div>
   );
}
export default ConsultantsList;
