import React from "react";
import "./styles.scss";
import TurkeyMap from "../assets/turkey-map.svg";

export const PartnerWithKAP = () => {
   return (
      <div className="section">
         <div className="container">
            <div className="row" style={{ alignItems: "center" }}>
               <div className="col-md-6">
                  <div className="text-content">
                     <span className="partner-with-kaporg">Driving Change with TürkiyeKAPorg</span>
                     <div className="d-flex flex-column" style={{ gap: "16px" }}>
                        <span
                           style={{ fontSize: "16px", color: "#3A3737", letterSpacing: "0.32px" }}
                        >
                           With over 120,000 civil society organizations in Türkiye, there's a vast
                           opportunity to make a difference. Partner with TürkiyeKAPorg to connect
                           with these organizations and drive positive change.
                        </span>
                        <span
                           style={{ fontSize: "16px", color: "#3A3737", letterSpacing: "0.32px" }}
                        >
                           TürkiyeKAPorg is a leading provider of integrated, organizational and NGO
                           capacity assessments, analysis and action planning designed to empower
                           NGOs in Türkiye's humanitarian, social development, migration, and
                           transformational community development sectors. Over the past few years,
                           we have helped large number of NGOs secure funding and create lasting
                           organizational level impact. Our deep understanding of the local context
                           and expertise in navigating evolving donor standards and compliance
                           requirements enable us to provide strategic support that drives long-term
                           impact.
                        </span>
                        <span
                           style={{ fontSize: "16px", color: "#3A3737", letterSpacing: "0.32px" }}
                        >
                           Our strategic, innovative, and mutually supportive approach ensures that
                           Turkish NGOs can maximize their impact and contribute to meaningful
                           change in the humanitarian and development sectors in Türkiye.
                        </span>
                     </div>
                  </div>
               </div>
               <div className="col-md-6">
                  <img
                     alt="Turkey map"
                     src={TurkeyMap}
                     style={{ maxHeight: "100%", width: "100%" }}
                  />
               </div>
            </div>
         </div>
      </div>
   );
};

export default PartnerWithKAP;
