import React from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../../_helpers";

function MenuAssesmentList({ menuItem }) {
   const location = useLocation();

   const getMenuItemActive = (url, hasSubmenu = false) => {
      return checkIsActive(location, url)
         ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
         : "";
   };

   return menuItem.type === "divider" ? (
      <>
         <li key={menuItem.id} className="menu-section ">
            <h4 className="menu-text">{menuItem.title}</h4>
            <i className="menu-icon flaticon-more-v2"></i>
         </li>
         <li>
            <p style={{ color: "#036051", fontSize: "12px", width: "70%;", marginLeft: "26px" }}>
               This assessment is checked by:
               {menuItem?.consultant != null ? " " + menuItem?.consultant : ""}
            </p>
         </li>
      </>
   ) : (
      <li
         key={menuItem.id}
         className={`menu-item menu-item-submenu ${getMenuItemActive(menuItem.url)}`}
         aria-haspopup="true"
         data-menu-toggle="hover"
      >
         <NavLink className="menu-link menu-toggle" to={menuItem.url}>
            <span className="svg-icon menu-icon">
               <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
            </span>
            <span className="menu-text">
               {menuItem.title} - {menuItem.progress}%
            </span>
         </NavLink>
      </li>
   );
}

export default MenuAssesmentList;
