import React from "react";
import VitalIcon from "../assets/vital.svg";
import "./ResponsivenessForAll.scss";
const FirstSection = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <div className="container">
        <div
          className="d-flex flex-column align-items-center boxStyle"
          style={{ gap: "24px" }}
        >
          <img src={VitalIcon} alt="icon" width={"64px"} />
          <div
            className="d-flex flex-column"
            style={{ gap: "12px", textAlign: "center" }}
          >
            <span
              style={{
                color: "#0098B8",
                fontSize: "18px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Empower with Data, Shape the Future.
            </span>
            <span
              style={{
                textAlign: "center",
                fontWeight: 600,
                fontSize: "32px",
              }}
            >
              <span style={{ color: "#4158CF" }}>TürkiyeKAPorg </span>connects
              Turkish NGOs and Organizations with
              <span style={{ color: "#4158CF" }}> potential donors,</span> and
              provides{" "}
              <span style={{ color: "#4158CF" }}>capacity-building</span>{" "}
              support to strengthen their project or program impact.
            </span>
            <span
              style={{
                color: "#3A3737",
                fontSize: "18px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              We facilitate partnerships with qualified national and local
              organizations and help expand your network within the humanitarian
              and development sectors.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
